import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  Grid,
  Typography,
  DialogActions,
} from "@mui/material"
import Page from "../../components/page"
import { useAppDispatch, useAppSelector } from "../../app/hooks"

import { useEffect, useState } from "react"
import SearchForProperty from "./search-for-property"
import SelectAgent from "./select-agent"
import { useNavigate } from "react-router-dom"
import {
  PhysicalAustralianAddress,
  Property,
  TitleSearchResult,
  User,
  WorkspaceSummaryNew,
} from "../../landconnex-api-client"

import {
  initialise,
  selectIsWorkspaceCreator,
  selectSessionStatus,
  selectTeamName,
  SessionStatus,
} from "../../app/slices/session-slice"
import {
  postWorkspace,
  selectWorkspaceStatus,
  WorkspacesStatus,
} from "../../app/slices/workspaces-slice"
import {
  getTeamMembers,
  selectTeamMembers,
} from "../../app/slices/team-members-slice"
import { getWorkspace } from "../workspace/thunks"

const CreateWorkspacePage = () => {
  const dispatch = useAppDispatch()
  const teamName = useAppSelector(selectTeamName)
  const [agent, setAgent] = useState<User | null>(null)
  const [address, setAddress] = useState<PhysicalAustralianAddress | null>(null)
  const [title, setTitle] = useState<TitleSearchResult | null>(null)
  const workspaceStatus = useAppSelector(selectWorkspaceStatus)
  const teamMembers = useAppSelector(selectTeamMembers)
  const isWorkspaceCreator = useAppSelector(selectIsWorkspaceCreator)
  const sessionStatus = useAppSelector(selectSessionStatus)
  const [missingTitleError, setMissingTitleError] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (sessionStatus == SessionStatus.initial) {
      dispatch(initialise())
    }
  }, [dispatch, sessionStatus])

  useEffect(() => {
    if (!teamMembers || teamMembers.length === 0) {
      dispatch(getTeamMembers())
    }
  }, [teamMembers, dispatch])

  const handleAgentSelected = (agent: User | null) => {
    setAgent(agent)
  }

  const handleAddressSelected = (address: PhysicalAustralianAddress | null) => {
    setAddress(address)
  }

  const handleTitleSelected = (title: TitleSearchResult | null) => {
    setTitle(title)
  }

  const handleCreateWorkspace = () => {
    if (!title) {
      setMissingTitleError(true)
      return
    }

    const st = address?.structured!
    const [lot, plan] = title?.lotOnPlan.split(" ")!
    let streetAddress1 = ""

    if (address?.mla.length == 2) {
      streetAddress1 = address?.mla[0]
    } else {
      streetAddress1 = `${address?.mla[0]} ${address?.mla[1]}`
    }

    const property: Property = {
      locality: st.locality?.name,
      postCode: st.postcode,
      stateOrTerritory: st.state?.abbreviation,
      streetAddress1: streetAddress1,
      titleReference: title?.reference,
      lot: lot,
      plan: plan,
    }

    dispatch(
      postWorkspace({
        agentId: agent?.id!,
        property: property,
      }),
    ).then(action => {
      setAgent(null)
      setAddress(null)
      setTitle(null)

      const w = action.payload as WorkspaceSummaryNew
      dispatch(getWorkspace(w.id))

      navigate(`/workspaces/${w.id}`)
    })
  }

  if (!isWorkspaceCreator) {
    return (
      <Page>
        {/* <Paper>
          <Typography>
            You are not authorized to create a workspace. If you do not you have
            a business account, you can create one by onboading.
            <Button
              variant="contained"
              onClick={() => navigate("/on-boarding")}
            >
              Create a business account
            </Button>
            If you do have a business account, please contact your administrator
            to be added to the workspace creator role.
          </Typography>
        </Paper> */}
        <Card
          sx={{
            margin: "15px 0",
            boxShadow: "0 1px 15px 0 rgba(0, 0, 0, 0.1)",
          }}
        >
          <CardHeader title="Unathorized" />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography variant="h5">
              You are not authorized to create a workspace.
            </Typography>
            <Typography variant="body1">
              If you do not you have a business account, you can create one by
              onboading.
            </Typography>
          </CardContent>
          <CardActions
            sx={{ flexDirection: "column", my: 5, textAlign: "center" }}
          >
            <Box sx={{ width: 260, margin: "0 auto 10px auto" }}>
              <Button
                onClick={() => navigate("/on-boarding")}
                color="success"
                size="large"
              >
                Create a business account
              </Button>
            </Box>
            <Typography variant="caption">
              If you do have a business account,
            </Typography>
            <Typography variant="caption">
              please contact your administrator to be added to the workspace
              creator role.
            </Typography>
          </CardActions>
        </Card>
      </Page>
    )
  }

  return (
    <Page>
      <Grid
        container
        sx={{
          maxWidth: 800,
          margin: "0 auto",
          padding: "50px 0",
        }}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Create a new workspace" />

            <CardContent>
              <SearchForProperty
                selectedAddress={address}
                onAddressSelected={handleAddressSelected}
                selectedTitle={title}
                onTitleSelected={handleTitleSelected}
                missingTitleError={missingTitleError}
              />
              <SelectAgent
                selectedAgent={agent}
                onAgentSelected={handleAgentSelected}
              />
            </CardContent>
            {missingTitleError && (
              <Box sx={{ textAlign: "center" }}>
                <Typography color="error" mt={2}>
                  Please select a title before creating the workspace.
                </Typography>
              </Box>
            )}
            <CardActions sx={{ justifyContent: "center" }}>
              {workspaceStatus == WorkspacesStatus.loading && (
                <Box sx={{ justifyItems: "center" }} mb={3} maxWidth={200}>
                  <CircularProgress />
                  <Typography ml={2}>Creating workspace...</Typography>
                </Box>
              )}
              {workspaceStatus !== WorkspacesStatus.loading && (
                <Box mb={3} maxWidth={200} width={"100%"}>
                  <Button
                    size="large"
                    onClick={() => handleCreateWorkspace()}
                    disabled={agent && address ? false : true}
                  >
                    Create Workspace
                  </Button>
                </Box>
              )}
            </CardActions>
          </Card>

          {/* <LCCard
            title={"Create a new workspace"}
            titleColor="#000"
            txtColor="#000"
            bgColor="#fff"
            sxCard={{
              padding: { sm: "15px 30px", xs: "15px 0" },
              outline: "2px solid rgba(208, 226, 255, .5)",
            }}
          >
            <SearchForProperty />
            <SelectAgent />
            <CardActions sx={{ justifyContent: "center" }}>
              {state.status == CreateWorkspaceStatus.creatingWorkspace && (
                <Box sx={{ justifyItems: "center" }}>
                  <CircularProgress />
                  <Typography ml={2}>Creating workspace...</Typography>
                </Box>
              )}
              {state.status !== CreateWorkspaceStatus.creatingWorkspace && (
                <Box mt={2} maxWidth={200} width={"100%"}>
                  <Button
                    onClick={() => handleCreateWorkspace()}
                    disabled={
                      state.addressDetails &&
                      state.selectedTitle &&
                      state.selectedAgent
                        ? false
                        : true
                    }
                  >
                    Create Workspace
                  </Button>
                </Box>
              )}
            </CardActions>
          </LCCard> */}
        </Grid>
      </Grid>
      <Dialog
        open={teamName === undefined}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle>Account Required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please create an account to create a workspace.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => navigate("/workspaces")}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => navigate("/on-boarding")}>
            Create Account
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  )
}

export default CreateWorkspacePage
