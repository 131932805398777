import {
  Button,
  CardHeader,
  Card,
  CardContent,
  CardActions,
  Box,
} from "@mui/material"
import React from "react"
import {
  ParticipantParticipantTypeEnum,
  ParticipantRoleEnum,
} from "../../../landconnex-api-client"
import ClientDetail from "./client-detail"
import { Notes } from "./form-wrapper"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import {
  postParticipant,
  selectParticipants,
} from "../../../app/slices/participants-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
export type OnParticipantAdded = () => void

const ClientDetails: React.FC = () => {
  const participants = useAppSelector(selectParticipants)
  const workspace = useAppSelector(selectWorkspace)
  const dispatch = useAppDispatch()

  const clients = participants.filter(
    p => p.role === ParticipantRoleEnum.Seller,
  )

  const notes = [
    {
      title: "Note:",
      items: [
        "The client is the person or entity appointing the agent to provide the services. This may be the owner (or authorised representative of the owner) of the land, property or business that is to be sold or may be a prospective buyer seeking to purchase land or a property.",
        "Fields marked with * are required.",
        "Annexures detailing additional clients may be attached if required.",
      ],
    },
  ]

  const handleAddClient = () => {
    dispatch(
      postParticipant({
        workspaceId: workspace!.workspace!.id,
        participant: {
          role: ParticipantRoleEnum.Seller,
          participantType: ParticipantParticipantTypeEnum.Individual,
        },
      }),
    )
  }

  return (
    <Card
      sx={{ border: "none" }}
    >
      <CardHeader title="Client Details" />
      <CardContent>
        <Notes notes={notes} />
        {clients.map((client, index) => (
          <ClientDetail key={client.id} client={client} index={index} />
        ))}
      </CardContent>
      <CardActions>
        <Box sx={{ width: 120, margin: "0 auto 10px auto" }}>
          <Button
            size="small"
            color="success"
            onClick={() => handleAddClient()}
            startIcon={<AddOutlinedIcon />}
          >
            client
          </Button>
        </Box>
      </CardActions>
    </Card>
  )
}

export default ClientDetails
