import { Container, useMediaQuery } from "@mui/material"

import { useTheme } from "@mui/material"
import Page from "../../components/page"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useParams } from "react-router-dom"
import {
  getWorkspace,
  selectWorkspace,
} from "../../app/slices/workspaces-slice"
import {
  getOffer,
  getOfferParticipants,
  selectOffer,
  selectOfferLoading,
} from "../../app/slices/offers-slice"
import { useEffect } from "react"
import ContractStepperPage from "../contract-stepper/contract-stepper-page"
import { getParticipants } from "../../app/slices/participants-slice"
import { getDocuments } from "../../app/slices/documents-slice"

const OfferPage = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"))

  const dispatch = useAppDispatch()
  const params = useParams()
  const offerId = Number(params.offerId)
  const workspaceId = Number(params.workspaceId)

  const workspace = useAppSelector(selectWorkspace)
  const offer = useAppSelector(selectOffer)
  const offerLoading = useAppSelector(selectOfferLoading)
  useEffect(() => {
    if (!workspace || workspace.workspace?.id !== workspaceId) {
      dispatch(getWorkspace(workspaceId))
      dispatch(getParticipants(workspaceId))
      dispatch(getDocuments(workspaceId))
    }
    if (!offer || offer.id !== offerId) {
      dispatch(getOffer({ offerId: offerId, workspaceId: workspaceId }))
      dispatch(
        getOfferParticipants({ offerId: offerId, workspaceId: workspaceId }),
      )
    }
  }, [dispatch, offerId, workspaceId])

  if (isSmall || isMedium) {
    return (
      <Page title="Offer">
        <OfferPageSmall />
      </Page>
    )
  }

  return (
    <Page title="Offer">
      <Container>
        {/* <pre>{JSON.stringify(offerLoading, null, 2)}</pre> */}
        {offerLoading.offer == false && <ContractStepperPage />}
      </Container>
    </Page>
  )
}

const OfferPageSmall = () => {
  return <h1>sdf</h1>
}

export default OfferPage
