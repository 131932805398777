import { useEffect, useState } from "react"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import Page from "../../components/page"
import WorkspaceContents from "../workspaces/components/workspace-contents"

import { withAuthenticationRequired } from "react-oidc-context"
import { Box, Button, Typography } from "@mui/material"
import styles from "./landing-page.module.css"
import { useNavigate } from "react-router-dom"
import RedirectToLogin from "../../components/redirect-to-login"
import {
  getWorkflow,
  getWorkspaces,
  selectWorkspaces,
  selectWorkspacesStatus,
  WorkspacesStatus,
} from "../../app/slices/workspaces-slice"
import { getRoles } from "../../app/authorisation"

const LandingPage: React.FC = () => {
  const workspacesStatus = useAppSelector(selectWorkspacesStatus)
  const workspaces = useAppSelector(selectWorkspaces)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const roles = useAppSelector(getRoles)
  const [isRedirecting, setIsRedirecting] = useState(true)

  const targetRoles = [
    "agent",
    "buyerSolicitor",
    "sellerSolicitor",
    "solicitor",
  ]

  const isAgentOrSolicitor = roles.some(item =>
    targetRoles.includes(item || ""),
  )

  useEffect(() => {
    if (isAgentOrSolicitor) {
      navigate("/dashboard")
    } else {
      navigate("/workspaces")
    }
    setIsRedirecting(false)
  }, [isAgentOrSolicitor, navigate])

  if (isRedirecting) {
    return null
  }

  useEffect(() => {
    if (workspacesStatus === WorkspacesStatus.initial) {
      dispatch(getWorkspaces())
    }
  }, [workspacesStatus, dispatch])

  useEffect(() => {
    workspaces.forEach(w => {
      dispatch(getWorkflow(w.workspace.id))
    })
  }, [workspaces, dispatch])

  return (
    <Page>
      <Box className={`${styles["banner-wrapper"]}`}>
        <Box className={`${styles["banner"]}`}>
          <Box className={`${styles["text"]}`}>
            <Box sx={{ marginBottom: "30px" }}>
              <Typography className="roboto-black" variant="h3">
                Unlock the Door to Your Future: Begin Your Journey with Us
              </Typography>
              <p className="roboto-light">
                At LandConnex, we understand that finding the perfect home is
                more than just a transaction; it’s a journey filled with dreams,
                hopes, and aspirations.
              </p>
              <p className="roboto-light">
                Our mission is to simplify this journey and make your
                home-buying experience as smooth and enjoyable as possible.
              </p>
            </Box>
            <Box sx={{ width: "180px", margin: "0 auto" }}>
              <Button color="inherit" size="large">
                Learn More
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          background: "#fff",
          padding: {
            sm: "40px 20px 40px 20px",
            xs: "5px",
          },
          borderRadius: "0 0 20px 20px",
        }}
      >
        {workspaces.length >= 1 && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <Typography
                className="roboto-black"
                variant="h4"
                sx={{ color: "#8F1219" }}
              >
                Our Workspace
              </Typography>
              <Box
                sx={{
                  width: "150px",
                }}
              >
                <Button onClick={() => navigate("/workspaces")}>
                  See More
                </Button>
              </Box>
            </Box>
            <WorkspaceContents
              workspaces={workspaces}
              isCardView={true}
              workflowStates={[]}
              maxItems={8}
            />
          </>
        )}

        {/* <Box
          className={`${styles["whys"]}`}
          sx={{
            display: "flex",
            position: "relative",
            overflow: "hidden",
          }}
          ref={parentRef}
        >
          <MailListing formRef={formRef} parentRef={parentRef} />
          <Whys />
        </Box> */}
      </Box>
    </Page>
  )
}

export default withAuthenticationRequired(LandingPage, {
  OnRedirecting: () => <RedirectToLogin />,
})
// export default LandingPage;
