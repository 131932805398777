import { FC } from "react"
import { WorkflowStateEnum, WorkflowSubStateEnum } from "../landconnex-api-client"
import { Chip } from "@mui/material"

export interface StatusIndicatorProps {
  state: WorkflowStateEnum
  subState: WorkflowSubStateEnum
}

const StatusIndicator: FC<StatusIndicatorProps> = ({ state, subState }) => {
  let stateLabel: string = ""
  let subStateLabel: string = ""

  switch (state) {
    case WorkflowStateEnum.AgentAppointment:
      stateLabel = "Agent Appointment"
      break
    case WorkflowStateEnum.ContractPreparation:
      stateLabel = "Contract Preparation"
      break
    case WorkflowStateEnum.AcceptingOffers:
      stateLabel = "Accepting Offers"
      break
    case WorkflowStateEnum.ContractSigned:
      stateLabel = "Contract Signed"
      break
  }

  switch (subState) {
    case WorkflowSubStateEnum.AgentAppointmentPreparing:
      subStateLabel = "Preparing"
      break
    case WorkflowSubStateEnum.AgentAppointmentAwaitingSigning:
      subStateLabel = "Awaiting Signing"
      break
    case WorkflowSubStateEnum.AgentAppointmentSigned:
      subStateLabel = "Signed"
      break
  }

  return <Chip size="small" label={`${stateLabel} - ${subStateLabel}`} />
}

export default StatusIndicator
