import {
  Button,
  useTheme,
  useMediaQuery,
  Stack,
  Container,
  Fab,
  Box,
  CardActions,
} from "@mui/material"
import { Add as AddIcon } from "@mui/icons-material"
import OfferTable from "../../components/offer/offer-table"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { postOffer, selectOffers } from "../../app/slices/offers-slice"
import { useEffect } from "react"

import WorkspaceCard, {
  WorkspaceCardContent,
  WorkspaceCardHeader,
} from "../../components/workspace-card"
import OfferCard from "../../components/offer/offer-card"
import { useNavigate } from "react-router-dom"
import { selectWorkspace } from "../../app/slices/workspaces-slice"

const Offers = () => {
  const offers = useAppSelector(selectOffers)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const isLarge = useMediaQuery(theme.breakpoints.up("md"))
  const workspace = useAppSelector(selectWorkspace)

  useEffect(() => {}, [dispatch])

  // const getOfferStatus = (
  //   offerId: number,
  // ): WorkflowOffersInnerStateEnum | undefined => {
  //   const offerState = workflow?.offers.find(offer => offer.offerId === offerId)
  //   return offerState?.state
  // }

  if (isSmall || isMedium) {
    return (
      <Box>
        <Stack direction="column" spacing={1}>
          {offers.map(offer => (
            <OfferCard key={offer.offer.id} offerId={offer.offer.id} />
          ))}
        </Stack>
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: 72,
            right: 16,
            zIndex: 1000,
          }}
        >
          <AddIcon />
        </Fab>
      </Box>
    )
  }
  if (isLarge) {
    return (
      <Container>
        <WorkspaceCard>
          <WorkspaceCardHeader
            title="Offers"
            subheader={offers.length + " offers"}
          />
          <WorkspaceCardContent>
            <OfferTable />
          </WorkspaceCardContent>
          <CardActions
            sx={{
              padding: 1,
              display: "flex",
              justifyContent: "center",
              mb: 3,
            }}
          >
            <Button
              color="success"
              sx={{ width: 160 }}
              onClick={() => {
                dispatch(
                  postOffer({
                    workspaceId: workspace?.workspace?.id!,
                    offer: {
                      id: 0,
                      hasEncumbrances: false,
                      hasPool: false,
                      hasPoolCertificate: false,
                      hasSafetySwitches: false,
                      hasSmokeAlarms: false,
                      hasNeighbourhoodDisputes: false,
                      hasTenant: false,
                      status: "",
                      hasSellerSolicitor: false,
                    },
                  }),
                )
                  .unwrap()
                  .then(contract => {
                    navigate(`offers/${contract.id}`)
                  })
              }}
            >
              Create New Offer
            </Button>
          </CardActions>
        </WorkspaceCard>
      </Container>
    )
  }
}

export default Offers
