import React, { useCallback, useEffect, useState } from "react"
import {
  Grid,
  Box,
  Button,
  Typography,
  useTheme,
  debounce,
} from "@mui/material"
import { Contract, Participant } from "../../../landconnex-api-client"
import {
  OnContractChanged,
  OnParticipantChanged,
} from "../../../components/events"
import { SubmitHandler, useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import useDeepCompareEffect from "use-deep-compare-effect"
import ParticipantNameForm from "../../../components/participant-name-form"
import ContactDetails from "../../../components/contact-details"
import AddressForm from "../../../components/address-form"
import { participantSchema } from "../../agent-appointment/components/schemas"
import FormWrapper from "../../agent-appointment/components/form-wrapper"
import FormInputText from "../../../components/form-input-text"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import RemoveIcon from "@mui/icons-material/Remove"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import {
  putParticipant,
  selectParticipants,
} from "../../../app/slices/participants-slice"
import {
  getMyDetails,
  selectMyDetails,
} from "../../../app/slices/my-details-slice"
import { produce } from "immer"

export interface SellerSolicitorProps {
  participant: Participant
  onParticipantChanged: OnParticipantChanged
  onContractChanged: OnContractChanged
  workspaceRole?: string
  contract: Contract
  // onCopySolicitorDetails: OnWorkspaceEvent;
}

const SellerSolicitor: React.FC = () => {
  const schema = participantSchema
  const theme = useTheme()

  const dispatch = useAppDispatch()

  const workspace = useAppSelector(selectWorkspace)
  const participants = useAppSelector(selectParticipants)
  const sellerSolicitor = participants.find(p => p.role == "sellerSolicitor")

  const myDetails = useAppSelector(selectMyDetails)

  useEffect(() => {
    if (myDetails === undefined) {
      dispatch(getMyDetails())
    }
  }, [dispatch, myDetails])

  const disableField = ["buyer", "buyerSolicitor"].includes(
    workspace!.workspace?.role!,
  )

  const handleOnParticipantChanged = (data: Participant) => {
    dispatch(
      putParticipant({
        workspaceId: workspace!.workspace!.id!,
        participant: data,
      }),
    )
  }

  const { handleSubmit, control, formState, reset, trigger, setValue } =
    useForm<Participant>({
      defaultValues: sellerSolicitor,
      resolver: yupResolver(schema),
    })

  // const contractSchema = yup
  //   .object<Contract>({
  //     depositHolderName: yup.string().required(),
  //     depositHolderAccountName: yup.string().required(),
  //     depositHolderBsb: yup.string().required(),
  //     depositHolderAccountNumber: yup.string().required(),
  //     depositHolderBank: yup.string().required(),
  //   })
  //   .required();

  // const {
  //   handleSubmit: contractHandleSubmit,
  //   control: contractControl,
  //   formState: contractFormState,
  //   reset: contractReset,
  //   trigger: contractTrigger,
  // } = useForm<any>({
  //   defaultValues: state.contract || {},
  //   resolver: yupResolver(contractSchema),
  // });

  // useEffect(() => {
  //   contractReset(state.contract || {});
  //   contractTrigger();
  // }, [state.contract]);

  const [showDepositHolderForm, setShowDepositHolderForm] = useState(false)

  useEffect(() => {
    reset(sellerSolicitor)
    trigger()
  }, [sellerSolicitor])

  const onChangedSubmit: SubmitHandler<Participant> = data => {
    handleOnParticipantChanged(data)
  }

  const watchedData = useWatch({
    control: control,
    defaultValue: sellerSolicitor,
  })

  const debouncedSave = useCallback(
    debounce(() => {
      handleSubmit(onChangedSubmit)()
    }, 1000),
    [],
  )

  useDeepCompareEffect(() => {
    if (formState.isDirty) {
      debouncedSave()
    }
  }, [watchedData])

  // const onContractChangedSubmit: SubmitHandler<Contract> = (data) => {
  //   dispatch(saveContract(data));
  // };

  // const debouncedContractSave = useCallback(
  //   debounce(() => {
  //     contractHandleSubmit(onContractChangedSubmit)();
  //   }, 1000),
  //   []
  // );

  // const watchedContractData = useWatch({
  //   control: contractControl,
  //   defaultValue: state.contract || {},
  // });

  // useDeepCompareEffect(() => {
  //   debouncedContractSave();
  // }, [watchedContractData]);

  // const handleInputChange = useCallback(
  //   (field: keyof Contract) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const updatedContract = {
  //       ...watchedContractData,
  //       [field]: event.target.value,
  //     };
  //     onChangedSubmit(updatedContract);
  //   },
  //   [watchedContractData, onChangedSubmit]
  // );

  const handleCopySolicitorDetails = () => {

    const updatedSellerSolicitor = produce(sellerSolicitor!, draft => {
      draft.firstName = myDetails?.firstName
      draft.lastName = myDetails?.lastName
      draft.email = myDetails?.email
      draft.phone = myDetails?.phone
      draft.streetAddress1 = myDetails?.streetAddress1
      draft.streetAddress2 = myDetails?.streetAddress2
      draft.locality = myDetails?.locality
      draft.stateOrTerritory = myDetails?.stateOrTerritory
      draft.postCode = myDetails?.postCode
      draft.country = myDetails?.country
      draft.abn = myDetails?.abn
      draft.acn = myDetails?.acn
    })

    dispatch(
      putParticipant({
        workspaceId: workspace!.workspace!.id!,
        participant: updatedSellerSolicitor,
      }),
    )
  }

  return (
    <FormWrapper title="Seller's Solicitor">
      <Box sx={{}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ maxWidth: "120px" }}>
              <Button
                color="success"
                onClick={handleCopySolicitorDetails}
                startIcon={<AddOutlinedIcon />}
              >
                My details
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Name
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormInputText
              name="firstName"
              required
              label="First name"
              control={control}
              key="firstName"
              disabled={disableField}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormInputText
              name="lastName"
              required
              label="Last name"
              control={control}
              key="lastName"
              disabled={disableField}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <ParticipantNameForm
              control={control}
              participantType={sellerSolicitor!.participantType!}
              disabled={disableField}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Contact
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ContactDetails control={control} disabled={disableField} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Address
            </Typography>
          </Grid>

          <AddressForm
            address={sellerSolicitor!}
            setValue={setValue}
            control={control}
            disabled={disableField}
          />
        </Grid>

        {workspace!.workspace?.role === "sellerSolicitor" && (
          <>
            <Box sx={{ marginTop: 2, zIndex: 1, maxWidth: "150px" }}>
              <Button
                size="small"
                onClick={() => setShowDepositHolderForm(!showDepositHolderForm)}
                startIcon={
                  showDepositHolderForm ? <RemoveIcon /> : <AddOutlinedIcon />
                }
              >
                Deposit Holder
              </Button>
            </Box>

            {/* {showDepositHolderForm && (
              <Box sx={{ marginTop: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color={theme.palette.text.secondary}
                    >
                      Deposit Holder Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={handleInputChange("depositHolderName")}
                      defaultValue={state.contract.depositHolderName || ""}
                      name="depositHolderName"
                      label="Deposit Holder Name"
                      variant="filled"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={handleInputChange("depositHolderAccountName")}
                      defaultValue={
                        state.contract.depositHolderAccountName || ""
                      }
                      name="depositHolderAccountName"
                      label="Deposit Holder Account Name"
                      variant="filled"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={handleInputChange("depositHolderBsb")}
                      defaultValue={state.contract.depositHolderBsb || ""}
                      name="depositHolderBsb"
                      label="Deposit Holder BSB"
                      variant="filled"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={handleInputChange("depositHolderAccountNumber")}
                      defaultValue={
                        state.contract.depositHolderAccountNumber || ""
                      }
                      name="depositHolderAccountNumber"
                      label="Deposit Holder Account Number"
                      variant="filled"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={handleInputChange("depositHolderBank")}
                      defaultValue={state.contract.depositHolderBank || ""}
                      name="depositHolderBank"
                      label="Deposit Holder Bank Name"
                      variant="filled"
                      required
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
            )} */}
          </>
        )}
      </Box>
    </FormWrapper>
  )
}

export default SellerSolicitor
