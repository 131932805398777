import { Home, People, FileCopy } from "@mui/icons-material"

import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  List,
  Stack,
  Typography,
} from "@mui/material"

import Grid from "@mui/material/Grid2"

import OfferIcon from "../../../components/offer-icon"
import { Fragment, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import {
  getParticipants,
  selectParticipants,
} from "../../../app/slices/participants-slice"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import WorkflowAction from "./workflow-action"
import ParticipantListItem from "../../../components/participant-list-item"
import { formatCurrency, singleLineFullAddress } from "../../../helpers/helpers"
import SelectSolicitor, { ActingForEnum } from "./actions/select-solicitor"
import {
  selectWorkflow,
  selectWorkspace,
} from "../../../app/slices/workspaces-slice"
import { selectProperty } from "../../../app/slices/property-slice"
import { selectAgentAppointment } from "../../../app/slices/agent-appointment-slice"
import WorkflowStatus from "../../workspaces/components/workflow-status"
import { BrandingCompanyTypeEnum } from "../../../landconnex-api-client"

const WorkspacePageSmall = () => {
  const [viewNumber, setViewNumber] = useState(0)
  const dispatch = useAppDispatch()
  const params = useParams()
  const participants = useAppSelector(selectParticipants)
  const workspace = useAppSelector(selectWorkspace)
  const workflow = useAppSelector(selectWorkflow)
  const property = useAppSelector(selectProperty)
  const agentAppointment = useAppSelector(selectAgentAppointment)

  useEffect(() => {
    dispatch(getParticipants(parseInt(params.workspaceId!)))
  }, [dispatch, params])

  const filteredParticipants = participants.filter(
    participant => participant.firstName !== undefined,
  )
  let agentBranding = workspace?.branding.find(
    b => b.companyType === BrandingCompanyTypeEnum.RealEstateAgency,
  )
  if (!agentBranding) {
    agentBranding = {
      logo: "34",
      primaryColor: "#FCD600",
      secondaryColor: "#FCD600",
      companyType: BrandingCompanyTypeEnum.RealEstateAgency,
    }
  }

  const DataPoint = ({ label, value }: { label: string; value: any }) => {
    return (
      <Stack direction="column">
        <Typography
          variant="body1"
          sx={{
            textTransform: "uppercase",
            fontWeight: 600,
            color: "text.secondary",
          }}
        >
          {label}
        </Typography>
        <Typography variant="body2">{value}</Typography>
      </Stack>
    )
  }
  return (
    <Box sx={{ position: "relative", overflow: "hidden", width: "100%", backgroundColor: "background.paper" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
        }}
      >
        <img
          style={{ border: "1 solid black" }}
          src={`https://landconnex-prod-public.ap-south-1.linodeobjects.com/logos/${agentBranding?.logo}.png`}
          alt=""
          height="32px"
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "4px",
          backgroundColor: `${agentBranding?.primaryColor}`,
        }}
      ></Box>
      {viewNumber === 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Card>
            <CardMedia
              component="img"
              height="250"
              image={`https://landconnex-prod-public.ap-south-1.linodeobjects.com/${workspace?.workspace?.id}.jpeg`}
              alt="Workspace Image"
            />

            <CardContent sx={{ padding: "15px" }}>
              <Typography variant="h6" color="text.secondary">
                {singleLineFullAddress(property!)}
              </Typography>
              <Grid container spacing={2} sx={{ pt: 2 }}>
                <Grid size={12}>
                  <WorkflowStatus workflow={workflow!} />
                </Grid>
                <Grid size={6}>
                  <DataPoint
                    label="List Price"
                    value={formatCurrency(agentAppointment?.priceList)}
                  />
                </Grid>

                <Grid size={6}>
                  <DataPoint
                    label="Lot & Plan"
                    value={workspace?.property?.lot}
                  />
                </Grid>
                <Grid size={6}>
                  <DataPoint
                    label="Title Reference"
                    value={workspace?.property?.titleReference}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {workflow?.subState ===
            "contractPreparationAwaitingSellerSolicitorSelection" && (
            <SelectSolicitor actingFor={ActingForEnum.Seller} />
          )}
        </Box>
      )}
      {viewNumber === 1 && (
        <List sx={{ backgroundColor: "background.paper" }}>
          {filteredParticipants.map(participant => (
            <Fragment key={participant.id}>
              <ParticipantListItem participant={participant} />
              <Divider />
            </Fragment>
          ))}
        </List>
      )}
      {viewNumber === 2 && <WorkflowAction />}
      {viewNumber === 3 && <h1>Hello</h1>}
      {/* <Box
        sx={{
          position: "relative",
          height: "100%",
          overflow: "auto",
          zIndex: 1,
          // pt: 8, // Add padding top to account for fixed header
          pb: 8, // Add padding bottom to account for bottom navigation
        }}
      >
        {viewNumber === 0 && <h1>Hello</h1>}
      </Box> */}
      <Box
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1000 }}
      >
        <BottomNavigation
          showLabels
          value={viewNumber}
          onChange={(_, newValue) => {
            setViewNumber(newValue)
          }}
        >
          <BottomNavigationAction label="Property" icon={<Home />} />
          <BottomNavigationAction label="Contacts" icon={<People />} />
          <BottomNavigationAction label="Offers" icon={<OfferIcon />} />
          <BottomNavigationAction label="Documents" icon={<FileCopy />} />
        </BottomNavigation>
      </Box>
    </Box>
  )
}

export default WorkspacePageSmall
