import { PayloadAction } from "@reduxjs/toolkit"
import {
  AgentAppointment,
  BenefitToAgent,
  Fee,
  Participant,
  Property,
} from "../../landconnex-api-client"
// import {
//   addBenefitToAgent,
//   addFee,
//   addSeller,
//   deleteBenefitToAgent,
//   deleteFee,
//   deleteParticipant,
//   getAgentAppointment,
//   getAgentAppointmentPDF,
//   sendAgentAppointmentForSigning,
//   updateAgentAppointment,
//   updateBenefitToAgent,
//   updateFee,
//   updateParticipant,
// } from "./thunks"
import dayjs from "dayjs"
import { createAppSlice } from "../../app/createAppSlice"
import { agentsApi } from "../../api"
import { appConfig } from "../../constants"
import { User } from "oidc-client-ts"
import axios from "axios"

export enum AgentAppointmentStatus {
  initial,
  loading,
  ready,
  dirty,
  updated,
  pdfReadyToDownload,
  sentForSigning,
  error,
  agentAppointmentSigned,
}
export enum GeneratedPDFStatus {
  initial,
  loading,
  ready,
  stale,
}

interface AgentAppointmentState {
  status: AgentAppointmentStatus
  pdfStatus: GeneratedPDFStatus
  property: Property
  agentAppointment: AgentAppointment | undefined
  fees: Array<Fee>
  benefits: Array<BenefitToAgent>
  clients: Array<Participant>
  licencee: Participant
  licenceeAgent: Participant
  pdf?: string
  pdfName?: string
  step: string
  workspaceId: number
}

export interface UpdateAgentAppointmentArgs {
  workspaceId: number
  agentAppointment: AgentAppointment
}

const initialState: AgentAppointmentState = {
  status: AgentAppointmentStatus.initial,
  pdfStatus: GeneratedPDFStatus.initial,
  agentAppointment: undefined,
  fees: [],
  benefits: [],
  property: {},
  clients: [],
  licencee: {},
  licenceeAgent: {},
  step: "client",
  workspaceId: 0,
}

export const agentAppointmentSlice = createAppSlice({
  name: "agentAppointment",
  initialState,
  reducers: create => ({
    initialise: create.reducer((state, action: PayloadAction<number>) => {
      state.workspaceId = action.payload
      state.status = AgentAppointmentStatus.initial
    }),
    moveToStep: create.reducer((state, action: PayloadAction<string>) => {
      state.step = action.payload
    }),
    participantChanged: create.reducer(
      (state, action: PayloadAction<Participant>) => {
        const index = state.clients.findIndex(v => v.id == action.payload.id)
        console.log(state.clients)
        state.clients[index] = action.payload
        console.log(state.clients)
        state.status = AgentAppointmentStatus.dirty
      },
    ),
    licenceeChanged: create.reducer(
      (state, action: PayloadAction<Participant>) => {
        state.licencee = action.payload
        state.status = AgentAppointmentStatus.dirty
      },
    ),
    propertyUpdated: create.reducer(
      (state, action: PayloadAction<Property>) => {
        state.property = action.payload
        state.status = AgentAppointmentStatus.dirty
      },
    ),
    licenceeAgentChanged: create.reducer(
      (state, action: PayloadAction<Participant>) => {
        state.licenceeAgent = action.payload
        state.status = AgentAppointmentStatus.dirty
      },
    ),
    uploadSignedAgentAppointment: create.asyncThunk<
      void,
      { workspaceId: number; signedAgentAppointment: File }
    >(
      async ({ workspaceId, signedAgentAppointment }) => {
        await agentsApi.putSignedAgentAppointment(
          workspaceId,
          signedAgentAppointment,
        )
      },
      {
        pending: state => {
          state.status = AgentAppointmentStatus.loading
        },
        fulfilled: state => {
          state.status = AgentAppointmentStatus.agentAppointmentSigned
        },
      },
    ),
    getAgentAppointmentPdf: create.asyncThunk<string, number>(
      async (workspaceId: number) => {
        const oidcStorage = sessionStorage.getItem(
          `oidc.user:${appConfig.authority}:${appConfig.clientId}`,
        )
        const user = User.fromStorageString(oidcStorage!)

        const response = await axios.get(
          `${appConfig.workspacesUri}/workspaces/${workspaceId}/agent-appointment/pdf`,
          {
            headers: { Authorization: `Bearer ${user.access_token}` },
            responseType: "blob",
          },
        )

        const url = window.URL.createObjectURL(response.data)

        return url
      },
      {
        fulfilled: (state, action) => {
          state.pdf = action.payload
          state.pdfStatus = GeneratedPDFStatus.ready
        },
      },
    ),
    getAgentAppointment: create.asyncThunk<AgentAppointment, number>(
      async (workspaceId: number) => {
        const response = await agentsApi.getAgentAppointment(workspaceId)
        return response.data.agentAppointment
      },
      {
        pending: state => {
          state.status = AgentAppointmentStatus.loading
        },
        fulfilled: (state, action) => {
          state.agentAppointment = action.payload
          state.agentAppointment.commissionAmount =
            state.agentAppointment.commissionAmount! / 100
          if (state.agentAppointment.appointmentTermStart) {
            const start = dayjs(Date.now())
            const end = start.add(90, "day")
            state.agentAppointment.appointmentTermStart = start.toISOString()
            state.agentAppointment.appointmentTermEnd = end.toISOString()
          }
          state.status = AgentAppointmentStatus.ready
        },
        rejected: state => {
          state.status = AgentAppointmentStatus.error
        },
      },
    ),
    updateAgentAppointment: create.asyncThunk<
      AgentAppointment,
      UpdateAgentAppointmentArgs
    >(
      async ({ workspaceId, agentAppointment }) => {
        agentAppointment.commissionAmount = Number(
          agentAppointment.commissionAmount!.toString().replace(/[^\d]/g, ""),
        )

        await agentsApi.putAgentAppointment(workspaceId, agentAppointment)
        return agentAppointment
      },
      {
        fulfilled: (state, action) => {
          state.agentAppointment = action.payload
          state.status = AgentAppointmentStatus.updated
        },
      },
    ),
    getFees: create.asyncThunk<Array<Fee>, number>(
      async (workspaceId: number) => {
        const response = await agentsApi.getFees(workspaceId)
        return response.data.items!
      },
      {
        fulfilled: (state, action) => {
          state.fees = action.payload
        },
      },
    ),
    addFee: create.asyncThunk<Fee, { workspaceId: number; fee: Fee }>(
      async ({ workspaceId, fee }) => {
        const response = await agentsApi.postFee(workspaceId, fee)
        fee.id = response.data.id!
        return fee
      },
      {
        fulfilled: (state, action) => {
          state.fees.push(action.payload)
        },
      },
    ),
    updateFee: create.asyncThunk<void, { workspaceId: number; fee: Fee }>(
      async ({ workspaceId, fee }) => {
        await agentsApi.updateFee(workspaceId, fee.id, fee)
      },
    ),
    deleteFee: create.asyncThunk<void, { workspaceId: number; feeId: number }>(
      async ({ workspaceId, feeId }) => {
        await agentsApi.deleteFee(workspaceId, feeId)
      },
    ),

    getBenefitToAgents: create.asyncThunk<Array<BenefitToAgent>, number>(
      async (workspaceId: number) => {
        const response = await agentsApi.getBenefitToAgents(workspaceId)
        return response.data.items!
      },
      {
        fulfilled: (state, action) => {
          state.benefits = action.payload
        },
      },
    ),
    addBenefitToAgent: create.asyncThunk<
      BenefitToAgent,
      { workspaceId: number; benefitToAgent: BenefitToAgent }
    >(
      async ({ workspaceId, benefitToAgent }) => {
        const response = await agentsApi.postBenefitToAgent(
          workspaceId,
          benefitToAgent,
        )
        benefitToAgent.id = response.data.id!
        return benefitToAgent
      },
      {
        fulfilled: (state, action) => {
          state.benefits.push(action.payload)
        },
      },
    ),
    updateBenefitToAgent: create.asyncThunk<
      void,
      { workspaceId: number; benefitToAgent: BenefitToAgent }
    >(async ({ workspaceId, benefitToAgent }) => {
      await agentsApi.updateBenefitToAgent(
        workspaceId,
        benefitToAgent.id,
        benefitToAgent,
      )
    }),
    deleteBenefitToAgent: create.asyncThunk<
      number,
      { workspaceId: number; benefitToAgentId: number }
    >(
      async ({ workspaceId, benefitToAgentId }) => {
        await agentsApi.deleteBenefitToAgent(workspaceId, benefitToAgentId)
        return benefitToAgentId
      },
      {
        fulfilled: (state, action) => {
          const index = state.benefits.findIndex(x => x.id == action.payload)
          state.benefits.splice(index, 1)
        },
      },
    ),
    sendAgentAppointmentForSigning: create.asyncThunk<void, number>(
      async (workspaceId: number) => {
        await agentsApi.sendAgentAppointmentForSigning(workspaceId)
      },
    ),
  }),
  selectors: {
    selectAgentAppointment: agentAppointment =>
      agentAppointment.agentAppointment,
    selectAgentAppointmentPdf: agentAppointment => agentAppointment.pdf,
    selectAgentAppointmentPdfStatus: agentAppointment =>
      agentAppointment.pdfStatus,
    selectAgentAppointmentStatus: agentAppointment => agentAppointment.status,
    selectFees: agentAppointment => agentAppointment.fees,
    selectBenefits: agentAppointment => agentAppointment.benefits,
    selectClients: agentAppointment => agentAppointment.clients,
    selectLicencee: agentAppointment => agentAppointment.licencee,
    selectLicenceeAgent: agentAppointment => agentAppointment.licenceeAgent,
    selectProperty: agentAppointment => agentAppointment.property,
    selectStep: agentAppointment => agentAppointment.step,
  },
})

// export const agentAppointmentSliceOld = createAppSlice({
//   name: "agentAppointment",
//   initialState,
//   reducers: {
//     initialise: (state, action: PayloadAction<number>) => {
//       state.workspaceId = action.payload
//       state.status = AgentAppointmentStatus.initial
//     },
//     moveToStep: (state, action: PayloadAction<string>) => {
//       state.step = action.payload
//     },
//     participantChanged: (state, action: PayloadAction<Participant>) => {
//       const index = state.clients.findIndex(v => v.id == action.payload.id)
//       console.log(state.clients)
//       state.clients[index] = action.payload
//       console.log(state.clients)
//       state.status = AgentAppointmentStatus.dirty
//     },
//     licenceeChanged: (state, action: PayloadAction<Participant>) => {
//       state.licencee = action.payload
//       state.status = AgentAppointmentStatus.dirty
//     },
//     licenceeAgentChanged: (state, action: PayloadAction<Participant>) => {
//       state.licenceeAgent = action.payload
//       state.status = AgentAppointmentStatus.dirty
//     },

//     propertyUpdated: (state, action: PayloadAction<Property>) => {
//       state.property = action.payload
//       state.status = AgentAppointmentStatus.dirty
//     },
//     sendAgentAppointmentForSigning: createAsyncThunk<void, number>(
//       "agentAppointment/sendForSigning",
//       async workspaceId => {
//         await agentsApi.sendAgentAppointmentForSigning(workspaceId)
//       },
//     ),
//   },
//   extraReducers: builder => {
//     GetAgentAppointmentReducers(builder)
//     GetAgentAppointmentPdfReducers(builder)
//     AddSellerReducers(builder)
//     UpdateAgentAppointmentReducers(builder)
//     UpdateParticipantReducer(builder)
//     DeleteParticipantReducer(builder)
//     FeeReducer(builder)
//     BenefitToAgentReducer(builder)
//     SendAgentAppointmentReducer(builder)
//   },
//   selectors: {
//     agentAppointmentState: agentAppointment => agentAppointment,
//   },
// })

// function SendAgentAppointmentReducer(
//   builder: ActionReducerMapBuilder<AgentAppointmentState>,
// ) {
//   builder.addCase(sendAgentAppointmentForSigning.fulfilled, state => {
//     state.status = AgentAppointmentStatus.sentForSigning
//   })
// }

// function FeeReducer(builder: ActionReducerMapBuilder<AgentAppointmentState>) {
//   builder.addCase(addFee.fulfilled, (state, action) => {
//     state.status = AgentAppointmentStatus.ready
//     const fee: Fee = { id: action.payload }
//     state.fees.push(fee)
//   })
//   builder.addCase(updateFee.fulfilled, (state, action) => {
//     const fees = state.fees
//     const index = fees.findIndex(x => x.id == action.payload.id)
//     state.fees[index] = action.payload
//   })
//   builder.addCase(deleteFee.fulfilled, (state, action) => {
//     const fees = state.fees
//     const index = fees.findIndex(x => x.id == action.payload.id)
//     state.fees.splice(index, 1)
//   })
// }

// function BenefitToAgentReducer(
//   builder: ActionReducerMapBuilder<AgentAppointmentState>,
// ) {
//   builder.addCase(addBenefitToAgent.fulfilled, (state, action) => {
//     state.status = AgentAppointmentStatus.ready
//     const benefitToAgent: BenefitToAgent = { id: action.payload }
//     state.benefits.push(benefitToAgent)
//   })
//   builder.addCase(updateBenefitToAgent.fulfilled, (state, action) => {
//     const benefitToAgents = state.benefits
//     const index = benefitToAgents.findIndex(x => x.id == action.payload.id)
//     state.benefits[index] = action.payload
//   })
//   builder.addCase(deleteBenefitToAgent.fulfilled, (state, action) => {
//     const benefitToAgents = state.benefits
//     const index = benefitToAgents.findIndex(x => x.id == action.payload.id)
//     state.benefits.splice(index, 1)
//   })
// }

// function UpdateAgentAppointmentReducers(
//   builder: ActionReducerMapBuilder<AgentAppointmentState>,
// ) {
//   builder.addCase(updateAgentAppointment.pending, state => {
//     state.status = AgentAppointmentStatus.loading
//   })
//   builder.addCase(updateAgentAppointment.fulfilled, (state, action) => {
//     state.agentAppointment = action.payload
//     state.status = AgentAppointmentStatus.updated
//   })
// }

// function UpdateParticipantReducer(
//   builder: ActionReducerMapBuilder<AgentAppointmentState>,
// ) {
//   builder.addCase(updateParticipant.pending, state => {
//     state.status = AgentAppointmentStatus.loading
//   })
//   builder.addCase(updateParticipant.fulfilled, (state, action) => {
//     const index = state.clients.findIndex(
//       client => client.id === action.payload.id,
//     )

//     // Check if the client is found
//     if (index !== -1) {
//       // Replace the client at the found index with the updated client
//       state.clients[index] = action.payload
//     }
//     state.status = AgentAppointmentStatus.ready
//     //state.pdfStatus = GeneratedPDFStatus.stale;
//   })
// }

// function DeleteParticipantReducer(
//   builder: ActionReducerMapBuilder<AgentAppointmentState>,
// ) {
//   builder.addCase(deleteParticipant.fulfilled, (state, action) => {
//     state.clients.splice(
//       state.clients.findIndex(v => v.id == action.payload),
//       1,
//     )

//     state.status = AgentAppointmentStatus.ready
//     //state.pdfStatus = GeneratedPDFStatus.stale;
//   })
// }

// function GetAgentAppointmentReducers(
//   builder: ActionReducerMapBuilder<AgentAppointmentState>,
// ) {
//   builder.addCase(getAgentAppointment.pending, state => {
//     state.status = AgentAppointmentStatus.loading
//   })
//   builder.addCase(getAgentAppointment.fulfilled, (state, action) => {
//     state.agentAppointment = action.payload.agentAppointment
//     if (state.agentAppointment.appointmentTermStart) {
//       const start = dayjs(Date.now())
//       const end = start.add(90, "day")
//       state.agentAppointment.appointmentTermStart = start.toISOString()
//       state.agentAppointment.appointmentTermEnd = end.toISOString()
//     }
//     state.licencee = action.payload.licencee
//     state.licenceeAgent = action.payload.agent
//     state.fees = action.payload.fees

//     state.clients = action.payload.clients
//     state.status = AgentAppointmentStatus.ready
//     //state.pdfStatus = GeneratedPDFStatus.stale;

//     state.property = action.payload.property
//   })
// }

// function GetAgentAppointmentPdfReducers(
//   builder: ActionReducerMapBuilder<AgentAppointmentState>,
// ) {
//   builder.addCase(getAgentAppointmentPDF.pending, state => {
//     state.pdfStatus = GeneratedPDFStatus.loading
//   })
//   builder.addCase(getAgentAppointmentPDF.fulfilled, (state, action) => {
//     state.pdfName = toKebabCase(
//       `agent appointment ${state.property.streetAddress1} ${state.property.locality}`,
//     )
//     state.pdf = action.payload
//     state.pdfStatus = GeneratedPDFStatus.ready
//   })
// }

// function toKebabCase(input: string): string {
//   return input
//     .trim() // Remove leading and trailing whitespaces
//     .replace(/[^\w\s]/g, "") // Remove any punctuation and special characters
//     .replace(/\s+/g, "-") // Replace spaces with hyphens
//     .toLowerCase() // Convert everything to lowercase
// }

// function AddSellerReducers(
//   builder: ActionReducerMapBuilder<AgentAppointmentState>,
// ) {
//   builder.addCase(addSeller.pending, _state => {
//     //state = initialState;
//   })
//   builder.addCase(addSeller.fulfilled, (state, action) => {
//     state.clients.push({ id: action.payload, role: "seller" })
//     state.status = AgentAppointmentStatus.ready
//     //state.pdfStatus = GeneratedPDFStatus.stale;
//   })
// }

export const {
  moveToStep,
  initialise,
  participantChanged,
  licenceeChanged,
  licenceeAgentChanged,
  propertyUpdated,
  sendAgentAppointmentForSigning,
  getAgentAppointmentPdf,
  getAgentAppointment,
  updateAgentAppointment,
  addFee,
  updateFee,
  deleteFee,
  addBenefitToAgent,
  updateBenefitToAgent,
  deleteBenefitToAgent,
  uploadSignedAgentAppointment,
} = agentAppointmentSlice.actions

// export default agentAppointmentSlice.reducer;
export const {
  selectAgentAppointment,
  selectAgentAppointmentPdf,
  selectAgentAppointmentPdfStatus,
  selectAgentAppointmentStatus,
  selectFees,
  selectBenefits,
  selectClients,
  selectLicencee,
  selectLicenceeAgent,
  selectProperty,
  selectStep,
} = agentAppointmentSlice.selectors
