import { TableCell, TableRow } from "@mui/material"
import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../app/hooks"
import { getDocuments } from "../../app/slices/documents-slice"
import { getParticipants } from "../../app/slices/participants-slice"
import { getProperty } from "../../app/slices/property-slice"
import { getWorkspace, getWorkflow, WorkspaceDetails } from "../../app/slices/workspaces-slice"
import { singleLineFullAddress, fullName, formatCurrency } from "../../helpers/helpers"

import StatusIndicator from "../status-indicator"

const WorkspaceRow: FC<WorkspaceDetails> = ({ workspace, workflow }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    return (
      <TableRow
        hover
        onClick={() => {
          dispatch(getWorkspace(workspace.id))
          dispatch(getDocuments(workspace.id))
          dispatch(getParticipants(workspace.id))
          dispatch(getWorkflow(workspace.id))
          dispatch(getProperty(workspace.id))
          navigate(`/workspaces/${workspace.id}`)
        }}
        sx={{ cursor: "pointer" }}
      >
        <TableCell>{singleLineFullAddress(workspace.property)}</TableCell>
        <TableCell>
          {workspace.sellers.map(seller => fullName(seller)).join(", ")}
        </TableCell>
        <TableCell>{fullName(workspace.agent)}</TableCell>
        <TableCell>{formatCurrency(workspace.price)}</TableCell>
        <TableCell>
          <StatusIndicator
            state={workflow?.state!}
            subState={workflow?.subState!}
          />
        </TableCell>
        <TableCell>{workspace.offers?.length ?? 0}</TableCell>
      </TableRow>
    )
  }

export default WorkspaceRow
