import { FC, useEffect } from "react"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Typography,
} from "@mui/material"
import AddressLabel from "../../../components/address-label"
import FormInputText from "../../../components/form-input-text"

import type { Property } from "../../../landconnex-api-client"
import { Notes } from "./form-wrapper"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"

import AutoSave from "../../../components/auto-save"
import { putProperty, selectProperty } from "../../../app/slices/property-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"

export type OnPropertyChanged = (property: Property) => void

// const presentUseOptions = [
//   "Vacant land",
//   "Dwelling",
//   "Multi Unit",
//   "Flats",
//   "Guest House Private Hotel",
//   "Farming",
//   "Industrial",
//   "Commercial",
//   "Other",
// ];

const Property: FC = () => {
  const dispatch = useAppDispatch()
  const workspace = useAppSelector(selectWorkspace)
  const property = useAppSelector(selectProperty)


  const propertyFormMethods = useForm<Property>({
    defaultValues: property!,
  })
  const {
    handleSubmit: propertyHandleSubmit,
    control: propertyControl,
    reset: propertyReset,
    trigger: propertyTrigger,
  } = propertyFormMethods

  useEffect(() => {
    propertyReset(property!)
    propertyTrigger()
  }, [property])

  const handlePropertySubmit: SubmitHandler<Property> = data => {
    dispatch(
      putProperty({
        workspaceId: workspace!.workspace!.id,
        property: data,
      }),
    )
  }

  const notes = [
    {
      title: "Note:",
      items: [
        "Please provide details of the property, land, or business as appropriate.",
        "Annexures detailing multiple properties may be attached if required.",
      ],
    },
  ]

  return (
    <Card sx={{ border: "none" }}>
      <CardHeader title="Details of property or business that is to be sold, let, purchased, or managed" />
      <CardContent>
        <Notes notes={notes} />
        <FormProvider {...propertyFormMethods}>
          <form onSubmit={propertyHandleSubmit(handlePropertySubmit)}>
            <Paper>
              <AddressLabel property={property!} />
            </Paper>

            <Typography variant="h6">Description</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>
              <Grid item xs={6} sm={4}>
                <FormInputText
                  control={propertyControl}
                  name="lot"
                  label="Lot"
                  required
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormInputText
                  control={propertyControl}
                  name="plan"
                  label="Plan"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormInputText
                  control={propertyControl}
                  name="titleReference"
                  label="Title Reference"
                  required
                />
              </Grid>
            </Grid>

            <AutoSave
              onSubmit={handlePropertySubmit}
              defaultValues={property}
            ></AutoSave>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  )
}

export default Property
