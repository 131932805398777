import { Participant, Participant1 } from "../../landconnex-api-client"
import { createAppSlice } from "../createAppSlice"
import { participantsApi } from "../../api"

export enum ParticipantsStatus {
  initial = "initial",
  loading = "loading",
  ready = "ready",
  error = "error",
}

interface ParticipantsState {
  status: ParticipantsStatus
  participants: Participant[]
  error?: string
}

const initialState: ParticipantsState = {
  status: ParticipantsStatus.initial,
  participants: [],
}

export interface ParticipantParams {
  participant: Participant
  workspaceId: number
}

export const participantsSlice = createAppSlice({
  name: "participants",
  initialState,
  reducers: create => ({
    getParticipants: create.asyncThunk<Participant1[], number>(
      async (workspaceId: number) => {
        const response = await participantsApi.getParticipants(workspaceId)
        return response.data.items!
      },
      {
        pending: state => {
          state.status = ParticipantsStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = ParticipantsStatus.ready
          state.participants = action.payload
        },
        rejected: state => {
          state.status = ParticipantsStatus.error
        },
      },
    ),
    putParticipant: create.asyncThunk<Participant, ParticipantParams>(
      async ({ workspaceId, participant }) => {
        await participantsApi.putParticipant(
          workspaceId,
          participant!.id!,
          participant,
        )
        return participant
      },
    ),
    postParticipant: create.asyncThunk<Participant, ParticipantParams>(
      async ({ workspaceId, participant }) => {
        const response = await participantsApi.postParticipant(
          workspaceId,
          participant,
        )
        participant.id = response.data.id
        return participant
      },
      {
        pending: state => {
          state.status = ParticipantsStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = ParticipantsStatus.ready
          state.participants = [...state.participants, action.payload]
        },
      },
    ),
    deleteParticipant: create.asyncThunk<number, ParticipantParams>(
      async ({ workspaceId, participant }) => {
        await participantsApi.deleteParticipant(workspaceId, participant!.id!)
        return participant!.id!
      },
      {
        pending: state => {
          state.status = ParticipantsStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = ParticipantsStatus.ready
          state.participants = state.participants.filter(
            p => p.id !== action.payload,
          )
        },
        rejected: state => {
          state.status = ParticipantsStatus.error
        },
      },
    ),
  }),
  selectors: {
    selectParticipants: participants => participants.participants,
    selectParticipantsStatus: state => state.status,
    selectParticipantsError: state => state.error,
    selectIsParticipantsLoading: state =>
      state.status === ParticipantsStatus.loading,
  },
})

export const {
  getParticipants,
  putParticipant,
  deleteParticipant,
  postParticipant,
} = participantsSlice.actions

export const {
  selectParticipants,
  selectParticipantsStatus,
  selectParticipantsError,
  selectIsParticipantsLoading,
} = participantsSlice.selectors
