import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { FC, useEffect } from "react"
import ParticipantNameForm from "../../../components/participant-name-form"
import ContactDetails from "../../../components/contact-details"
import AddressForm from "../../../components/address-form"
import { Participant } from "../../../landconnex-api-client"
import { FormProvider, useForm } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"

import {
  getMyDetails,
  selectMyDetails,
  updateMyDetails,
} from "../../../app/slices/my-details-slice"

const MyDetails: FC = () => {
  const theme = useTheme()
  const myDetails = useAppSelector(selectMyDetails)
  const dispatch = useAppDispatch()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"))
  // const isLarge = useMediaQuery(theme.breakpoints.up("md"))

  useEffect(() => {
    if (myDetails === undefined) {
      dispatch(getMyDetails())
    }
  }, [myDetails, dispatch])

  const formMethods = useForm<Participant>({
    defaultValues: myDetails,
  })
  const { handleSubmit, control } = formMethods

  const handleMyDetailsFormSubmit = (data: Participant) => {
    dispatch(updateMyDetails(data))
  }

  const renderContent = () => {
    return (
      <Paper sx={{ padding: 3 }}>
        {myDetails && (
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(handleMyDetailsFormSubmit)}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Name
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <ParticipantNameForm
                    control={control}
                    captureMiddleName={true}
                    captureGst={true}
                    participantType={"individual"}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography variant="h6" color={theme.palette.text.secondary}>
                    Contact
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ContactDetails control={control} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6" color={theme.palette.text.secondary}>
                    Address
                  </Typography>
                </Grid>
                <AddressForm
                  control={control}
                  address={myDetails!}
                  setValue={s => {
                    console.log(s)
                  }}
                />
              </Grid>
              <Box width={120} mx="auto" mt="30px">
                <Button type="submit" size="large">
                  Save
                </Button>
              </Box>

              {/* <AutoSave
                defaultValues={myDetails}
                onSubmit={handleMyDetailsFormSubmit}
              /> */}
            </form>
          </FormProvider>
        )}
      </Paper>
    )
  }

  if (isSmall || isMedium) {
    return (
      <Box sx={{ padding: 0, backgroundColor: "#fff" }}>{renderContent()}</Box>
    )
  }

  return renderContent()
}

export default MyDetails
