import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React, { ChangeEvent } from "react"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import FormWrapper from "../../agent-appointment/components/form-wrapper"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { putContract, selectContract } from "../../../app/slices/contract-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { Contract } from "../../../landconnex-api-client"
import { produce } from "immer"

const NeighbourhoodDisputes: React.FC = ({}) => {
  const dispatch = useAppDispatch()

  const contract = useAppSelector(selectContract)
  const workspace = useAppSelector(selectWorkspace)
  const disableField = ["buyer", "buyerSolicitor"].includes(
    workspace!.workspace!.role!,
  )

  function onChanged(event: ChangeEvent<HTMLInputElement>): void {
    let newContract: Contract | undefined = undefined
    switch (event.currentTarget.name) {
      case "hasNeighbourhoodDisputes":
        newContract = produce(contract!, draft => {
          draft.hasNeighbourhoodDisputes = event.currentTarget.value == "true"
        })
        break

      default:
        break
    }
    if (newContract) {
      dispatch(
        putContract({
          workspaceId: workspace!.workspace!.id,
          contract: newContract,
        }),
      )
    }
  }

  return (
    <FormWrapper title="Neighbourhood Disputes">
      <Grid container spacing={2}>
        <Grid item sm={12} lg={12}>
          <Typography variant="body1">
            Does the Seller give notice to the Buyer in accordance with Section
            83 of the Neighbourhood Disputes (Dividing Fences and Trees) Act
            2011 that the land is affected by an application to, or an order
            made by, QCAT in relation to a tree on the Land, a copy of which has
            been given to the Buyer prior to the Buyer signing the contract.
          </Typography>
        </Grid>
        <Grid item sm={12} lg={12}>
          <RadioGroup
            value={contract!.hasNeighbourhoodDisputes}
            name="hasNeighbourhoodDisputes"
            onChange={onChanged}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Yes"
              disabled={disableField}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="No"
              disabled={disableField}
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </FormWrapper>
  )
}

export default NeighbourhoodDisputes
