import { FC,  useEffect, useState } from "react"
import { DocumentSummary } from "../landconnex-api-client"
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material"
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined"

export type OnDocumentClicked = (documentName: string) => void
export interface DocumentsTableProps {
  documents: Array<DocumentSummary>
  onDocumentViewClicked?: OnDocumentClicked
  onDocumentDownloadClicked?: OnDocumentClicked
}
interface KeyedDocumentSummary extends DocumentSummary {
  id: number
}

const DocumentsTable: FC<DocumentsTableProps> = ({
  documents,
  onDocumentViewClicked,
  // onDocumentDownloadClicked,
}) => {
  const [items, setItems] = useState<KeyedDocumentSummary[]>([])

  useEffect(() => {
    const keyedDocuments = documents
      // .filter((item) => item.documentType !== "Annexure")
      .map((item, index) => ({
        ...item,
        id: index,
      }))
    setItems(keyedDocuments)
  }, [documents])

  return (
    <List>
      {items.map((item, index) => (
        <ListItem
          key={index}
          onClick={() => onDocumentViewClicked?.(item.name!)}
          sx={{
            "&:hover": { backgroundColor: "primary.light", color: "white" },
          }}
        >
          <ListItemAvatar>
            <DownloadForOfflineOutlinedIcon fontSize="medium" />
          </ListItemAvatar>
          <Box>
            <ListItemText>{item.name}</ListItemText>
          </Box>
        </ListItem>
      ))}
    </List>
  )
}

export default DocumentsTable
