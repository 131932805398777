import { teamMembersApi } from "../../api"
import { createAppSlice } from "../../app/createAppSlice"
import { TeamInvitation, TeamParticipant } from "../../landconnex-api-client"

export enum TeamMembersStatus {
  initial,
  loading,
  ready,
  error,
}

interface TeamMembersState {
  status: TeamMembersStatus
  members: Array<TeamParticipant>
  teamInvitations: Array<TeamInvitation>
}

const initialState: TeamMembersState = {
  status: TeamMembersStatus.initial,
  members: [],
  teamInvitations: [],
}

export const teamMembersSlice = createAppSlice({
  name: "teamMembers",
  initialState,
  reducers: create => ({
    getTeamMembers: create.asyncThunk(
      async () => {
        const response = await teamMembersApi.getUsers()

        return response.data
      },
      {
        pending: state => {
          state.status = TeamMembersStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamMembersStatus.ready
          state.members = action.payload.users!
        },
        rejected: state => {
          state.status = TeamMembersStatus.error
        },
      },
    ),
    getTeamInvitations: create.asyncThunk(
      async () => {
        const response = await teamMembersApi.getTeamInvitations()

        return response.data.items
      },
      {
        pending: state => {
          state.status = TeamMembersStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamMembersStatus.ready
          state.teamInvitations = action.payload
        },
        rejected: state => {
          state.status = TeamMembersStatus.error
        },
      },
    ),
    cancelInvitation: create.asyncThunk<void, number>(
      async invitationId => {
        await teamMembersApi.cancelInvitation(invitationId)
      },
      {
        pending: state => {
          state.status = TeamMembersStatus.loading
        },
        fulfilled: state => {
          state.status = TeamMembersStatus.ready
        },
        rejected: state => {
          state.status = TeamMembersStatus.error
        },
      },
    ),
    inviteUserToTeam: create.asyncThunk<void, string>(
      async emailAddress => {
        await teamMembersApi.inviteUserToTeam(emailAddress)
      },
      {
        pending: state => {
          state.status = TeamMembersStatus.loading
        },
        fulfilled: state => {
          state.status = TeamMembersStatus.ready
        },
        rejected: state => {
          state.status = TeamMembersStatus.error
        },
      },
    ),
    removeUserFromTeam: create.asyncThunk<void, string>(
      async sub => {
        await teamMembersApi.removeUserFromTeam(sub)
      },
      {
        pending: state => {
          state.status = TeamMembersStatus.loading
        },
        fulfilled: state => {
          state.status = TeamMembersStatus.ready
        },
        rejected: state => {
          state.status = TeamMembersStatus.error
        },
      },
    ),
  }),

  selectors: {
    selectStatus: settings => settings.status,
    selectTeamMembers: settings => settings.members,
    selectTeamInvitations: settings => settings.teamInvitations,
  },
})

export const {
  getTeamMembers,
  getTeamInvitations,
  cancelInvitation,
  inviteUserToTeam,
  removeUserFromTeam,
} = teamMembersSlice.actions

export const { selectStatus, selectTeamInvitations, selectTeamMembers } =
  teamMembersSlice.selectors
