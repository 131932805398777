import { FC, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import Page from "../../components/page"
import {
  Box,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { withAuthenticationRequired } from "react-oidc-context"
import SearchIcon from "@mui/icons-material/Search"
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined"
import SortByAlphaOutlinedIcon from "@mui/icons-material/SortByAlphaOutlined"
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined"
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined"
import WorkspaceContents from "./components/workspace-contents"
import LCCard from "../../components/cards/lc-card"
import RedirectToLogin from "../../components/redirect-to-login"
import AddIcon from "@mui/icons-material/Add"
import {
  getWorkspaces,
  selectWorkspaces,
  selectWorkspacesStatus,
  WorkspacesStatus,
} from "../../app/slices/workspaces-slice"
import { useNavigate } from "react-router-dom"
import {
  selectIsWorkspaceCreator,
  selectTeamName,
  toggleModal,
} from "../../app/slices/session-slice"
import WorkspacesLarge from "./components/workspaces-large"
import WorkspacesSmallMedium from "./components/workspaces-small-medium"
import { getRoles } from "../../app/authorisation"

const WorkspacesPage: FC = () => {
  const [searchValue, setSearchValue] = useState("")
  const [isCardView, setIsCardView] = useState(true)

  const workspacesStatus = useAppSelector(selectWorkspacesStatus)
  const workspaces = useAppSelector(selectWorkspaces)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const teamName = useAppSelector(selectTeamName)
  const isWorkspaceCreator = useAppSelector(selectIsWorkspaceCreator)
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"))

  const roles = useAppSelector(getRoles)

  useEffect(() => {
    if (workspacesStatus === WorkspacesStatus.initial) {
      dispatch(getWorkspaces())
    }
  }, [workspacesStatus, dispatch])

  const handleViewToggle = () => {
    setIsCardView(prev => !prev)
  }

  const handleCreateWorkspace = () => {
    if (teamName == undefined) {
      setShowModal(true)
    } else {
      navigate("/create-workspace")
    }
  }
  const targetRoles = [
    "agent",
    "buyerSolicitor",
    "sellerSolicitor",
    "solicitor",
  ]

  const isAgentOrSolicitor = roles.some(item =>
    targetRoles.includes(item || ""),
  )

  return isAgentOrSolicitor ? (
    <Page>
      {isSmall || isMedium ? <WorkspacesSmallMedium /> : <WorkspacesLarge />}
    </Page>
  ) : (
    <>
      <Box
        id="property-image"
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: "url(/retail-background-desktop.png)",
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          opacity: 0.5,
          zIndex: 0,
        }}
      />

      <Box
        sx={{
          position: "relative",
          padding: 2,
        }}
      >
        <WorkspacesSmallMedium />
      </Box>
    </>
  )

  return (
    <Page>
      <LCCard title={"Workspaces"} titleColor="#000" bgColor="#fff">
        <Box
          sx={{
            width: "100%",
            margin: "0 0 30px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: {
              xs: 2,
              md: 1,
            },
          }}
        >
          <OutlinedInput
            id="input-search-header"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            placeholder="Search Address"
            sx={{ width: { xs: "100%", md: "500px" }, borderRadius: "10px" }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <Box>
            <IconButton
              // onClick={handleSortByCreateDate}
              sx={{
                display: "inline-flex",
                background: "rgba(208, 226, 255, .5)",
                borderRadius: "10px",
                padding: { sm: "10px", xs: "7px" },
                marginRight: {
                  sm: 1,
                  xs: "5px",
                },
                cursor: "pointer",
              }}
            >
              <DateRangeOutlinedIcon />
            </IconButton>
            <IconButton
              // onClick={handleSortByAddress}
              sx={{
                display: "inline-flex",
                background: "rgba(208, 226, 255, .5)",
                borderRadius: "10px",
                padding: { sm: "10px", xs: "7px" },
                marginRight: {
                  sm: 1,
                  xs: "5px",
                },
                cursor: "pointer",
              }}
            >
              <SortByAlphaOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={handleViewToggle}
              sx={{
                display: "inline-flex",
                background: "rgba(208, 226, 255, .5)",
                borderRadius: "10px",
                padding: { sm: "10px", xs: "7px" },
                marginRight: {
                  sm: 1,
                  xs: "5px",
                },
                cursor: "pointer",
              }}
            >
              {isCardView ? (
                <FormatListBulletedOutlinedIcon />
              ) : (
                <DashboardCustomizeOutlinedIcon />
              )}
            </IconButton>
          </Box>
        </Box>

        <WorkspaceContents
          workspaces={workspaces}
          isCardView={isCardView}
          workflowStates={[]}
        />

        <Box sx={{ width: 250, margin: "0 auto" }}>
          <Button
            color="success"
            size="large"
            startIcon={<AddIcon />}
            onClick={handleCreateWorkspace}
          >
            Create New Workspace
          </Button>
        </Box>

        {isWorkspaceCreator ? "true" : "false"}
        {teamName}
      </LCCard>
      <Dialog
        open={showModal}
        onClose={() => dispatch(toggleModal())}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle>Account Required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please create an account to create a workspace.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowModal(false)}>Cancel</Button>
          <Button onClick={() => navigate("/on-boarding")}>
            Create Account
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  )
}

export default withAuthenticationRequired(WorkspacesPage, {
  OnRedirecting: () => <RedirectToLogin />,
  signinRedirectArgs: { redirect_uri: window.location.href },
})
