import React from "react"
import { Grid, Typography } from "@mui/material"
import WorkspaceSummary from "./workspace-sumary"
import { WorkspaceDetails } from "../../../app/slices/workspaces-slice"

interface WorkspaceContentsProps {
  workspaces: WorkspaceDetails[]
  isCardView: boolean
  maxItems?: number
  workflowStates: any[]
}

const WorkspaceContents: React.FC<WorkspaceContentsProps> = ({
  workspaces,
  isCardView,
  maxItems,
}) => {
  const displayWorkspaces = maxItems
    ? workspaces.slice(0, maxItems)
    : workspaces

  if (displayWorkspaces.length === 0) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={2}
        sx={{ padding: 4 }}
      >
        <Grid item>
          <Typography variant="h6" color="text.secondary">
            No workspaces found
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container sx={{ padding: 0 }}>
      {displayWorkspaces.map((w, i) => (
        <Grid
          key={`wsg-${i}`}
          item
          xl={isCardView ? 3 : 6}
          lg={isCardView ? 4 : 6}
          md={isCardView ? 6 : 6}
          xs={12}
          sx={{ padding: 1 }}
        >
          <WorkspaceSummary
            // onClick={() => handleWorkspaceClicked(w.workspace.id)}
            workspaceSummary={w.workspace!}
            key={`ws-${w.workspace.id}`}
            workflow={w.workflow}
            isCardView={isCardView}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default WorkspaceContents
