import { Typography, Box, Stack, SxProps, Theme, lighten } from "@mui/material"
import { FC } from "react"
import {
  Branding,
  BrandingCompanyTypeEnum,
  Workflow,
  WorkflowAgentAppointmentEnum,
  WorkflowContractPreparationEnum,
  WorkflowOfferNegotiationEnum,
  WorkspaceSummary,
} from "../../landconnex-api-client"

enum TimelineStepState {
  NotStarted = "notStarted",
  InProgress = "inProgress",
  Completed = "completed",
}
interface TimelineStepProps {
  state: TimelineStepState
  inProgressDescription: string
  completedDescription: string
  sx?: SxProps<Theme>
  agentBranding: Branding
}

const TimelineStep: FC<TimelineStepProps> = ({
  sx,
  state,
  inProgressDescription,
  completedDescription,
  agentBranding,
}) => {
  let bgColor: string
  let description: string
  let descriptionColor: string
  switch (state) {
    case TimelineStepState.NotStarted:
      bgColor = "#EAEAEA"
      description = inProgressDescription
      descriptionColor = "#ccc"
      break
    case TimelineStepState.InProgress:
      bgColor = lighten(agentBranding.secondaryColor!, 0.5)
      description = inProgressDescription
      descriptionColor = "text.primary"
      break
    case TimelineStepState.Completed:
      bgColor = agentBranding.secondaryColor
      description = completedDescription
      descriptionColor = "text.primary"
      break
  }

  return (
    <Box sx={{ ...sx }}>
      <Box
        id="progress"
        sx={{
          bgcolor: bgColor,
          width: "100%",
          height: "8px",
        }}
      ></Box>
      <Box id="progress" sx={{ pl: 1, textAlign: "left", width: "100%" }}>
        <Typography
          variant="caption"
          color={descriptionColor}
          sx={{ pr: 1, fontSize: ".7em", fontWeight: "bold" }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  )
}
export interface TimelineProps {
  workflow: Workflow
  workspace: WorkspaceSummary
}
interface TimelineStageProps {
  stageName: string
  stageColor?: string
  sx?: SxProps<Theme>
}
const TimelineStage: FC<TimelineStageProps> = ({
  stageName,
  stageColor,
  sx,
}) => {
  return (
    <Box
      id="agent-appointment"
      sx={{
        bgcolor: stageColor,
        ...sx,
      }}
    >
      <Typography
        variant="caption"
        sx={{
          pl: 1,
          fontSize: ".7em",
          fontWeight: "bold",
          color: "text.primary",
        }}
      >
        {stageName}
      </Typography>
    </Box>
  )
}

interface TimelineProgressProps {
  workflow: Workflow
  agentBranding: Branding
}
const TimelineProgress: FC<TimelineProgressProps> = ({
  workflow,
  agentBranding,
}) => {
  let agentAppointmentPreparingState: TimelineStepState
  let agentAppointmentAwaitingSigningState: TimelineStepState
  let agentAppointmentAwaitingSellerAdmissionState: TimelineStepState
  let sellerSolicitorSelectionState: TimelineStepState
  let sellerSolicitorAdmissionState: TimelineStepState
  let contractPreparationState: TimelineStepState
  let acceptingOffersState: TimelineStepState
  let contractSignedState: TimelineStepState

  if (!workflow) {
    return <></>
  }
  switch (workflow.agentAppointment) {
    case WorkflowAgentAppointmentEnum.AgentAppointmentPreparing:
      agentAppointmentPreparingState = TimelineStepState.InProgress
      agentAppointmentAwaitingSigningState = TimelineStepState.NotStarted
      agentAppointmentAwaitingSellerAdmissionState =
        TimelineStepState.NotStarted
      break
    case WorkflowAgentAppointmentEnum.AgentAppointmentAwaitingSigning:
      agentAppointmentPreparingState = TimelineStepState.Completed
      agentAppointmentAwaitingSigningState = TimelineStepState.InProgress
      agentAppointmentAwaitingSellerAdmissionState =
        TimelineStepState.NotStarted
      break
    case WorkflowAgentAppointmentEnum.AgentAppointmentComplete:
      agentAppointmentPreparingState = TimelineStepState.Completed
      agentAppointmentAwaitingSigningState = TimelineStepState.Completed
      agentAppointmentAwaitingSellerAdmissionState = TimelineStepState.Completed
      break
    case WorkflowAgentAppointmentEnum.AgentAppointmentAwaitingSellerAdmission:
      agentAppointmentPreparingState = TimelineStepState.Completed
      agentAppointmentAwaitingSigningState = TimelineStepState.Completed
      agentAppointmentAwaitingSellerAdmissionState =
        TimelineStepState.InProgress
      break

    default:
      agentAppointmentPreparingState = TimelineStepState.NotStarted
      agentAppointmentAwaitingSigningState = TimelineStepState.NotStarted
      agentAppointmentAwaitingSellerAdmissionState =
        TimelineStepState.NotStarted
      break
  }

  switch (workflow.contractPreparation) {
    case WorkflowContractPreparationEnum.ContractPreparationAwaitingSellerSolicitorSelection:
      sellerSolicitorSelectionState = TimelineStepState.InProgress
      sellerSolicitorAdmissionState = TimelineStepState.NotStarted
      contractPreparationState = TimelineStepState.NotStarted
      break
    case WorkflowContractPreparationEnum.ContractPreparationAwaitingSellerSolicitorAdmission:
      sellerSolicitorSelectionState = TimelineStepState.Completed
      sellerSolicitorAdmissionState = TimelineStepState.InProgress
      contractPreparationState = TimelineStepState.NotStarted
      break
    case WorkflowContractPreparationEnum.ContractPreparationPreparing:
      sellerSolicitorSelectionState = TimelineStepState.Completed
      sellerSolicitorAdmissionState = TimelineStepState.Completed
      contractPreparationState = TimelineStepState.InProgress
      break
    case WorkflowContractPreparationEnum.ContractPreparationComplete:
      sellerSolicitorSelectionState = TimelineStepState.Completed
      sellerSolicitorAdmissionState = TimelineStepState.Completed
      contractPreparationState = TimelineStepState.Completed
      break
    default:
      sellerSolicitorSelectionState = TimelineStepState.NotStarted
      sellerSolicitorAdmissionState = TimelineStepState.NotStarted
      contractPreparationState = TimelineStepState.NotStarted
      break
  }

  switch (workflow.offerNegotiation) {
    case WorkflowOfferNegotiationEnum.OfferNegotiationNotStarted:
      acceptingOffersState = TimelineStepState.NotStarted
      contractSignedState = TimelineStepState.NotStarted
      break
    case WorkflowOfferNegotiationEnum.OfferNegotiationAcceptingOffers:
      acceptingOffersState = TimelineStepState.InProgress
      contractSignedState = TimelineStepState.NotStarted
      break
    case WorkflowOfferNegotiationEnum.OfferNegotiationComplete:
      acceptingOffersState = TimelineStepState.Completed
      contractSignedState = TimelineStepState.Completed
      break
  }

  return (
    <Box display="flex" flexDirection="row" sx={{ width: "100%" }}>
      <TimelineStep
        state={agentAppointmentPreparingState}
        inProgressDescription="Preparing"
        completedDescription="Prepared"
        agentBranding={agentBranding}
        sx={{ width: 1 / 7 }}
      />
      <TimelineStep
        state={agentAppointmentAwaitingSigningState}
        inProgressDescription="Signing in Progress"
        completedDescription="Signed"
        agentBranding={agentBranding}
        sx={{ width: 1 / 7 }}
      />
      <TimelineStep
        state={agentAppointmentAwaitingSellerAdmissionState}
        inProgressDescription="Awaiting Seller Admission"
        completedDescription="Seller Admitted"
        sx={{ width: 1 / 7 }}
        agentBranding={agentBranding}
      />
      <TimelineStep
        state={sellerSolicitorSelectionState}
        inProgressDescription="Awaiting Seller Solicitor Selection"
        completedDescription="Solicitor Selected"
        sx={{ width: 1 / 7 }}
        agentBranding={agentBranding}
      />
      <TimelineStep
        state={sellerSolicitorAdmissionState}
        inProgressDescription="Awaiting Seller Solicitor Admission"
        completedDescription="Solicitor Admitted"
        sx={{ width: 1 / 7 }}
        agentBranding={agentBranding}
      />
      <TimelineStep
        state={contractPreparationState}
        inProgressDescription="Preparing Contract"
        completedDescription="Contract Prepared"
        sx={{ width: 1 / 7 }}
        agentBranding={agentBranding}
      />
      <TimelineStep
        state={acceptingOffersState}
        inProgressDescription="Accepting Offers"
        completedDescription="Contract Prepared"
        sx={{ width: 1 / 7 }}
        agentBranding={agentBranding}
      />
      <TimelineStep
        state={contractSignedState}
        inProgressDescription="Contract Signed"
        completedDescription="Contract Signed"
        sx={{ width: 1 / 7 }}
        agentBranding={agentBranding}
      />
    </Box>
  )
}

const Timeline: FC<TimelineProps> = ({ workflow, workspace }) => {
  let agentBranding: Branding | undefined
  
  if (!workspace || !workspace.branding) {
    agentBranding = {
      logo: "34",
      primaryColor: "#FCD600",
      secondaryColor: "#FCD600",
      companyType: BrandingCompanyTypeEnum.RealEstateAgency,
    }
  } else {
    agentBranding = workspace.branding.find(
      b =>
        b.companyType === BrandingCompanyTypeEnum.RealEstateAgency ||
        BrandingCompanyTypeEnum.Solicitor,
    )
  }

  return (
    <Stack>
      <Box sx={{ bgcolor: "white" }}>
        <Box display="flex" flexDirection="row">
          <TimelineStage
            stageName="Agent Appointment"
            stageColor={lighten(agentBranding?.primaryColor!, 0.5)}
            sx={{ width: 3 / 8 }}
          />
          <TimelineStage
            stageName="Contract Preparation"
            stageColor={agentBranding?.primaryColor!}
            sx={{ width: 3 / 8 }}
          />
          <TimelineStage
            stageName="Offer Negotiation"
            stageColor={agentBranding?.primaryColor!}
            sx={{ width: 2 / 8 }}
          />
        </Box>

        <TimelineProgress workflow={workflow} agentBranding={agentBranding!} />
      </Box>
    </Stack>
  )
}

export default Timeline
