import { useAppDispatch, useAppSelector } from "../../app/hooks"

import React, { useEffect, useState } from "react"

import { Box, Container, Paper, Typography } from "@mui/material"
import KonveiStepper from "../../components/konvei-stepper"
import SellerDetails from "./components/seller-details"
import { getOffer, selectOffer } from "../../app/slices/offers-slice"
import { selectWorkspace } from "../../app/slices/workspaces-slice"
import SellerAgent from "./components/seller-agent"
import Encumbrances from "./components/encumbrances"
import Finance from "./components/finance"
import Price from "./components/price"
import Property from "./components/property"
import SpecialConditions from "./components/special-conditions"
import BuyersDetails from "./components/buyers-details"
import SellerSolicitor from "./components/seller-solicitor"
import BuyersSolicitor from "./components/buyers-solicitor"
import PoolSafety from "./components/pool-safety"
import SafetyAlarmsAndSwitches from "./components/safety-alarms-and-switches"
import NeighbourhoodDisputes from "./components/neighbourhood-disputes"
import Annexures from "./components/annexures"
import Review from "./components/review"
import { getContract } from "../../app/slices/contract-slice"
import { ArrowBack } from "@mui/icons-material"
import { useNavigate, useParams } from "react-router-dom"
import Settlement from "./components/settlement"
import OfferDraftView from "../offer-draft/offer-draft-page"

const sellerSolicitorSteps = [
  "sellers",
  "agent",
  "buyers",
  "price",
  "solicitor",
  "finance",
  "buyers-solicitor",
  "property",
  "encumbrances",
  "special-conditions",
  "pool-safety",
  "safety-switches-and-alarms",
  "neighbourhood-disputes",
  "annexures",
  "review",
]

const sellerSolicitorPreparationSteps = [
  "sellers",
  "agent",
  "solicitor",
  "property",
  "encumbrances",
  "special-conditions",
  "pool-safety",
  "safety-switches-and-alarms",
  "neighbourhood-disputes",
  "annexures",
  "review",
]

const buyerSolicitorSteps = [
  "agent",
  "solicitor",
  "property",
  "buyers-solicitor",
  "encumbrances",
  "special-conditions",
  "finance",
  "pool-safety",
  "safety-switches-and-alarms",
  "neighbourhood-disputes",
  "annexures",
  "review",
]

const sellerAgentSteps = [
  "buyers",
  "buyers-solicitor",
  "price",
  "special-conditions",
  "settlement",
  "offer-draft-view",
]

const ContractStepperPage: React.FC = () => {
  const dispatch = useAppDispatch()

  const offer = useAppSelector(selectOffer)
  const workspace = useAppSelector(selectWorkspace)
  const navigate = useNavigate()
  const params = useParams()
  const workspaceId = params.workspaceId ? parseInt(params.workspaceId) : undefined

  const offerId = params.offerId ? parseInt(params.offerId) : undefined

  useEffect(() => {
    if (workspace != undefined) {
      dispatch(getContract(workspace!.workspace!.id))
    }
  }, [dispatch, workspace])

  useEffect(() => {
    if (offerId != offer?.id) {
      dispatch(
        getOffer({ offerId: offerId!, workspaceId: workspaceId! }),
      )
    }
  }, [dispatch, offerId, offer])

  function onStepChanged(step: string): void {
    setStep(step)
  }

  let steps: string[] = []
  // let title: string 
  let initialStep: string = ""
  if (workspace != undefined) {
    switch (workspace!.workspace?.role) {
      case "seller":
      case "sellerSolicitor":
        if (!offer) {
          steps = sellerSolicitorPreparationSteps
          // title = "Contract and Disclosures"
          initialStep = "sellers"
        } else {
          steps = sellerSolicitorSteps
          // title = "Contract"
          initialStep = "sellers"
        }
        break

      case "sellerAgent":
        steps = sellerAgentSteps
        // title = "Offer"
        initialStep = "buyers"
        break

      case "buyer":
      case "buyerSolicitor":
        steps = buyerSolicitorSteps
        // title = "Contract and Disclosures"
        initialStep = "buyers"
        break

      default:
        break
    }
  }

  const [step, setStep] = useState<string>(initialStep)
  // console.log(title)
  return (
    <Paper sx={{ height: "100%" }}>
      <Container maxWidth="lg" sx={{ height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            fontSize: 18,
            mt: 2,
            cursor: "pointer",
            alignItems: "center",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBack /> Back to Workspace
        </Box>
        <Typography variant="h5" sx={{ padding: 1 }}>
          Contract
        </Typography>
        <KonveiStepper
          steps={steps!}
          onStepChanged={onStepChanged}
          activeStep={step}
          isCompressed={true}
        />

        {step == "sellers" && <SellerDetails />}
        {step == "buyers" && <BuyersDetails />}
        {step == "agent" && <SellerAgent />}
        {step == "price" && <Price />}
        {step == "solicitor" && <SellerSolicitor />}
        {step == "buyers-solicitor" && <BuyersSolicitor />}
        {step == "settlement" && <Settlement />}
        {step == "finance" && <Finance />}
        {step == "property" && <Property />}
        {step == "encumbrances" && <Encumbrances />}
        {step == "special-conditions" && <SpecialConditions />}
        {step == "pool-safety" && <PoolSafety />}
        {step == "safety-switches-and-alarms" && <SafetyAlarmsAndSwitches />}
        {step == "neighbourhood-disputes" && <NeighbourhoodDisputes />}
        {step == "annexures" && <Annexures />}
        {step == "offer-draft-view" && <OfferDraftView />}
        {step == "review" && <Review />}
      </Container>
    </Paper>
  )
}

export default ContractStepperPage
