import axios, { AxiosResponse } from "axios"
import { User } from "oidc-client-ts"
import { contractsApi } from "../../api"
import { appConfig } from "../../constants"
import {
  Contract,
  Invitation,
  Annexure,
  AddAnnexureDocumentTypeEnum,
} from "../../landconnex-api-client"
import { createAppSlice } from "../createAppSlice"

export interface ContractState {
  //   status: ContractStatus
  contract: Contract | undefined
  annexures: Annexure[]
  loading: {
    pdf: boolean
  }
  pdfUrl: string | undefined
  error: {
    pdf: string | undefined
  }
}

const initialState: ContractState = {
  contract: undefined,
  annexures: [],
  pdfUrl: undefined,
  loading: {
    pdf: false,
  },
  error: {
    pdf: undefined,
  },
}

export interface AddAnnexureArgs {
  workspaceId: number
  documentName: string
  documentType: AddAnnexureDocumentTypeEnum
  file: File
}

export interface UpdateAnnexurePositionArgs {
  workspaceId: number
  annexureId: number
  position: number
}

export const contractSlice = createAppSlice({
  name: "contract",
  initialState,
  reducers: create => ({
    getContract: create.asyncThunk<Contract, number>(
      async (workspaceId: number) => {
        const response = await contractsApi.getContract(workspaceId)
        return response.data
      },
      {
        fulfilled: (state, action) => {
          state.contract = action.payload
        },
      },
    ),
    putContract: create.asyncThunk<
      void,
      { workspaceId: number; contract: Contract }
    >(async ({ workspaceId, contract }) => {
      await contractsApi.putContract(workspaceId, {
        contract: contract,
      })
    }),
    addAnnexure: create.asyncThunk<void, AddAnnexureArgs>(
      async ({ workspaceId, documentName, documentType, file }) => {
        await contractsApi.addAnnexure(
          workspaceId,
          documentName,
          documentType,
          file,
        )
      },
    ),

    completeContractDrafting: create.asyncThunk<void, number>(
      async (workspaceId: number) => {
        await contractsApi.completeContractDrafting(workspaceId)
      },
    ),

    deleteAnnexure: create.asyncThunk<
      void,
      { workspaceId: number; annexureId: number }
    >(async ({ workspaceId, annexureId }) => {
      await contractsApi.deleteAnnexure(workspaceId, annexureId)
    }),

    getContractAnnexures: create.asyncThunk<Annexure[], number>(
      async (workspaceId: number) => {
        const response = await contractsApi.getContractAnnexures(workspaceId)
        return response.data.items
      },
      {
        fulfilled: (state, action) => {
          state.annexures = action.payload
        },
      },
    ),

    getPdf: create.asyncThunk<string, number>(
      async (workspaceId: number) => {
        const oidcStorage = sessionStorage.getItem(
          `oidc.user:${appConfig.authority}:${appConfig.clientId}`,
        )
        const user = User.fromStorageString(oidcStorage!)
        let response: AxiosResponse<Blob>

        response = await axios.get(
          `${appConfig.workspacesUri}/workspaces/${workspaceId}/contract/pdf`,
          {
            headers: { Authorization: `Bearer ${user.access_token}` },
            responseType: "blob",
          },
        )

        const url = window.URL.createObjectURL(response.data)

        return url
      },
      {
        pending: state => {
          state.loading.pdf = true
          state.error.pdf = undefined
        },
        fulfilled: (state, action) => {
          state.loading.pdf = false
          state.pdfUrl = action.payload
        },
        rejected: (state, action) => {
          state.loading.pdf = false
          state.error.pdf = action.error.message
        },
      },
    ),

    inviteSellerSolicitor: create.asyncThunk<
      void,
      { workspaceId: number; invitation: Invitation }
    >(async ({ workspaceId, invitation }) => {
      await contractsApi.inviteSellerSolicitor(workspaceId, invitation)
    }),

    updateAnnexurePosition: create.asyncThunk<void, UpdateAnnexurePositionArgs>(
      async ({ workspaceId, annexureId, position }) => {
        await contractsApi.updateAnnexurePosition(
          workspaceId,
          annexureId,
          position,
        )
      },
    ),
  }),
  selectors: {
    selectContract: contract => contract.contract,
    selectAnnexures: contract => contract.annexures,
    selectLoading: contract => contract.loading,
    selectPdfUrl: contract => contract.pdfUrl,
    selectError: contract => contract.error,
  },
})

export const {
  getContract,
  putContract,
  addAnnexure,
  completeContractDrafting,
  deleteAnnexure,
  getContractAnnexures,
  getPdf,
  inviteSellerSolicitor,
  updateAnnexurePosition,
} = contractSlice.actions
export const {
  selectContract,
  selectAnnexures,
  selectLoading,
  selectPdfUrl,
  selectError,
} = contractSlice.selectors
