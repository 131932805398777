import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"

import React, { Fragment } from "react"
import {
  Participant,
  ParticipantParticipantTypeEnum,
} from "../landconnex-api-client"
import { Control } from "react-hook-form"

import FormInputPattern from "./form-input-pattern"
import FormInputCheckbox from "./form-input-checkbox"
import FormInputText from "./form-input-text"
import { Box } from "@mui/material"

export interface IndividualNameFormProps {
  control: Control<Participant>
  captureMiddleName?: boolean
  organisationLabel?: string
  captureGst?: boolean
  participantType: ParticipantParticipantTypeEnum
  disabled?: boolean
}

const ParticipantNameForm: React.FC<IndividualNameFormProps> = ({
  control,
  captureMiddleName = false,
  captureGst,
  participantType,
  organisationLabel,
  disabled,
}) => {
  return (
    <Grid container spacing={2}>
      {participantType == ParticipantParticipantTypeEnum.Individual ? (
        <Grid item xs={12} sm={6} md={captureMiddleName ? 4 : 6}>
          <FormInputText
            name="firstName"
            required
            label="First name"
            control={control}
            key="firstName"
            disabled={disabled}
          />
        </Grid>
      ) : (
        <></>
      )}

      {participantType == ParticipantParticipantTypeEnum.Individual &&
      captureMiddleName ? (
        <Grid item xs={12} sm={6} md={4}>
          <FormInputText
            name="middleNames"
            label="Middle Name"
            control={control}
            key="middleNames"
            disabled={disabled}
          />
        </Grid>
      ) : null}

      {participantType == ParticipantParticipantTypeEnum.Individual ? (
        <Grid item xs={12} sm={6} md={captureMiddleName ? 4 : 6}>
          <FormInputText
            name="lastName"
            required
            label="Last Name"
            control={control}
            key="lastName"
            disabled={disabled}
          />
        </Grid>
      ) : null}
      {participantType != ParticipantParticipantTypeEnum.Individual ? (
        <Grid item xs={12}>
          <FormInputText
            control={control}
            name="organisationName"
            required
            key={"organisationName"}
            label={organisationLabel!}
            disabled={disabled}
          />
        </Grid>
      ) : null}
      {participantType != ParticipantParticipantTypeEnum.Individual ? (
        <Fragment>
          <Grid item xs={12} sm={6}>
            <FormInputPattern
              format="## ### ### ###"
              name="abn"
              required
              label="ABN"
              control={control}
              disabled={disabled}
            ></FormInputPattern>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInputPattern
              format="### ### ###"
              name="acn"
              required
              label="ACN"
              control={control}
              disabled={disabled}
            ></FormInputPattern>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ maxWidth: "110px" }}>
              <Button
                size={`small`}
                onClick={() =>
                  window.open(
                    "https://abr.business.gov.au/",
                    "_blank",
                    "noopener,noreferrer",
                  )
                }
              >
                ABN Lookup
              </Button>
            </Box>
          </Grid>
        </Fragment>
      ) : null}
      {captureGst ? (
        <Grid item xs={12}>
          <FormInputCheckbox
            control={control}
            name="registeredForGst"
            label="Registered for GST?"
          />
        </Grid>
      ) : null}
    </Grid>
  )
}

export default ParticipantNameForm
