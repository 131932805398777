import { Autocomplete, Box, TextField, Typography } from "@mui/material"
import { useAppSelector } from "../../app/hooks"
import { User } from "../../landconnex-api-client"
import { Fragment } from "react/jsx-runtime"
import { selectTeamMembers } from "../../app/slices/team-members-slice"

export interface SelectAgentProps {
  selectedAgent: User | null
  onAgentSelected: (agent: User | null) => void
}
const SelectAgent = ({ selectedAgent, onAgentSelected }: SelectAgentProps) => {
  //const state = useAppSelector(createWorkspaceState)
  const teamMembers = useAppSelector(selectTeamMembers)
  const handleAgentChange = (_event: any, agent: User | null): void => {
    onAgentSelected(agent)
  }
  return (
    <Fragment>
      <Typography variant="h6">Agent</Typography>
      <Box sx={{ display: "flex", margin: "15px 0" }}>
        <Autocomplete
          disablePortal
          id="agent"
          fullWidth
          size="small"
          value={selectedAgent}
          onChange={handleAgentChange}
          options={teamMembers.map<User>(user => ({
            id: user.sub,
            firstName: user.participant.firstName!,
            lastName: user.participant.lastName!,
          }))}
          getOptionLabel={option => `${option.firstName} ${option.lastName}`}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField
              {...params}
              label="Select Agent"
              variant="filled"
              // error={!!agentError}
              // helperText={agentError}
            />
          )}
        />
      </Box>
    </Fragment>
  )
}

export default SelectAgent
