import { createListenerMiddleware } from "@reduxjs/toolkit"
import {
  getWorkflow,
  getWorkspace,
  getWorkspaces,
  postWorkspace,
  workspacesSlice,
} from "../slices/workspaces-slice"
import {  presentOfferToSeller, startOfferWorkflow } from "../slices/offers-slice"
export const actionsMiddleware = createListenerMiddleware()

actionsMiddleware.startListening({
  actionCreator: workspacesSlice.actions.getWorkspaces.fulfilled,
  effect: async (action, listenerApi) => {
    action.payload.items!.forEach(workspace => {
      listenerApi.dispatch(getWorkflow(workspace.id))
    })
  },
})



actionsMiddleware.startListening({
  actionCreator: workspacesSlice.actions.getWorkspace.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(getWorkflow(action.payload.workspace!.id))
  },
})

actionsMiddleware.startListening({
  actionCreator: postWorkspace.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(getWorkflow(action.payload.id))
    listenerApi.dispatch(getWorkspaces())
  },
})

actionsMiddleware.startListening({
  actionCreator: presentOfferToSeller.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(getWorkspace(action.payload.workspaceId))
  },
})

actionsMiddleware.startListening({
  actionCreator: startOfferWorkflow.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(getWorkspace(action.payload))
  },
})

// export const actionsMiddleware = actionsMiddleware.middleware
