import { createAsyncThunk } from "@reduxjs/toolkit"
import { teamMembersApi, teamsApi } from "../../api"
import {
  GetUsersResponse,
  Team,
  TeamInvitation,
  TeamParticipant,
} from "../../landconnex-api-client"

export const getTeamMembers = createAsyncThunk<GetUsersResponse, void>(
  "teams/getTeamMembers",
  async () => {
    const response = await teamMembersApi.getUsers()

    return response.data
  },
)

export const getTeam = createAsyncThunk<Team, void>(
  "teams/getTeam",
  async () => {
    const response = await teamsApi.getTeam()

    return response.data
  },
)

export const getTeamInvitations = createAsyncThunk<TeamInvitation[], void>(
  "teams/getTeamInvitations",
  async () => {
    const response = await teamMembersApi.getTeamInvitations()

    return response.data.items
  },
)

export const cancelInvitation = createAsyncThunk<void, number>(
  "teams/cancelInvitation",
  async invitationId => {
    await teamMembersApi.cancelInvitation(invitationId)
  },
)

export const inviteUserToTeam = createAsyncThunk<void, string>(
  "teams/inviteUserToTeam",
  async emailAddress => {
    await teamMembersApi.inviteUserToTeam(emailAddress)
  },
)

export const removeUserFromTeam = createAsyncThunk<void, string>(
  "teams/removeUserFromTeam",
  async sub => {
    await teamMembersApi.removeUserFromTeam(sub)
  },
)

export interface UpdateUserParams {
  sub: string
  teamParticipant: TeamParticipant
}
export const updateUser = createAsyncThunk<void, UpdateUserParams>(
  "teams/updateUser",
  async ({ sub, teamParticipant }) => {
    await teamMembersApi.updateUser(sub, { user: teamParticipant.participant })
  },
)
