import { FC } from "react"
import { Workflow, WorkspaceSummaryNew } from "../../../landconnex-api-client"
import Typography from "@mui/material/Typography"
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from "@mui/material"
import {
  convertCamelCase,
  fullName,
  singleLineAddress,
} from "../../../helpers/helpers"
import { useAppDispatch } from "../../../app/hooks"
import { getWorkspace } from "../../workspace/thunks"
import { getDocuments } from "../../../app/slices/documents-slice"
import { getParticipants } from "../../../app/slices/participants-slice"
import { useNavigate } from "react-router-dom"
import { getWorkflow } from "../../../app/slices/workspaces-slice"
import { getProperty } from "../../../app/slices/property-slice"
export interface WorkspaceSummaryProps {
  workspaceSummary?: WorkspaceSummaryNew
  workflow?: Workflow
  isCardView?: boolean
}
const WorkspaceSummary: FC<WorkspaceSummaryProps> = ({
  workspaceSummary,
  workflow,
  isCardView,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  function handleCardOnlick(): void {
    dispatch(getWorkspace(workspaceSummary!.id))
    dispatch(getDocuments(workspaceSummary!.id))
    dispatch(getParticipants(workspaceSummary!.id))
    dispatch(getWorkflow(workspaceSummary!.id))
    dispatch(getProperty(workspaceSummary!.id))
    navigate(`/workspaces/${workspaceSummary!.id}`)
  }

  const sellers = workspaceSummary?.sellers
    .map(seller => fullName(seller))
    .join(", ")
  return (
    <Card
      style={{
        boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        borderRadius: "12px",
      }}
    >
      <CardActionArea
        onClick={() => {
          handleCardOnlick()
        }}
      >
        {isCardView && (
          <Box sx={{ position: "relative" }}>
            <CardMedia
              component="img"
              height="250"
              image={`https://landconnex-prod-public.ap-south-1.linodeobjects.com/${workspaceSummary?.id}.jpeg`}
              alt="Workspace Image"
            />
          </Box>
        )}
        <CardContent>
          <Typography gutterBottom variant="h6">
            {singleLineAddress(workspaceSummary?.property!)}
          </Typography>
          <Typography gutterBottom variant="body2" color="text.secondary">
            Status: {workflow ? convertCamelCase(workflow?.state) : "N/A"}
          </Typography>
          <Typography gutterBottom variant="body2" color="text.secondary">
            Sellers: {sellers || "Preparing"}
          </Typography>

          <Typography
            variant="body2"
            color="text.secondary"
            component="div"
          ></Typography>
          {/* <WorkflowStatus workflow={workflow!} date={formattedDate} /> */}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default WorkspaceSummary
