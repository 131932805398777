import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material"
import { TableRow, TableCell, IconButton, Collapse } from "@mui/material"
import { FC, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Fragment } from "react/jsx-runtime"
import { fullName, formatCurrency, formatDate } from "../../helpers/helpers"
import { OfferDetail, WorkflowOffersInner } from "../../landconnex-api-client"
import OfferCard from "./offer-card"
import OfferStatusDisplay from "./offer-status-display"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"

interface OfferRowProps {
  offer: OfferDetail
  workflowOffer: WorkflowOffersInner
}
const OfferRow: FC<OfferRowProps> = ({ offer, workflowOffer }) => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  return (
    <Fragment>
      <TableRow
        key={offer.offer.id}
        sx={{
          "& > *": { borderBottom: "none" },
          ".MuiTableCell-root": {
            padding: "10px 0",
            textAlign: "center",
            borderBottom: "none",
          },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          <IconButton
            aria-label="open offer"
            size="small"
            onClick={() => navigate(`offers/${offer.offer.id}`)}
          >
            {<EditOutlinedIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {offer.participants?.map(buyer => fullName(buyer)).join(", ")}
        </TableCell>
        <TableCell>{formatCurrency(offer.offer.purchasePrice)}</TableCell>
        <TableCell>{formatCurrency(offer.offer.depositInitial)}</TableCell>
        <TableCell>{formatDate(offer.offer.settlementDate)}</TableCell>
        <TableCell>
          <OfferStatusDisplay state={workflowOffer?.state} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0, background: "#f6f7f9" }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <OfferCard offerId={offer.offer.id} />
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default OfferRow
