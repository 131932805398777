import { Box, Button, Typography } from "@mui/material"
import Page from "../../components/page"
import { useEffect } from "react"
import { useAuth } from "react-oidc-context"
import { useNavigate } from "react-router-dom"

const OnBoardingComplete: React.FC = () => {
  const auth = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    auth.startSilentRenew()
  }, [auth])

  return (
    <Page>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          justifyItems: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          🎉 Onboarding Complete 🎉
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          Congratulations! You have successfully completed the onboarding
          process.
        </Typography>

        <Box sx={{ display: "flex", gap: 2 }}>
          <Box width={140}>
            <Button
              color="primary"
              size="large"
              onClick={() => navigate("/dashboard")}
            >
              Dashboard
            </Button>
          </Box>
          <Box width={140}>
            <Button
              color="inherit"
              size="large"
              onClick={() => navigate("/profile")}
            >
              Workspaces
            </Button>
          </Box>
        </Box>
      </Box>
    </Page>
  )
}

export default OnBoardingComplete
