import { FC, useState } from "react"
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  IconButton,
  ListItemAvatar,
  Avatar,
  styled,
  OutlinedInput,
  InputAdornment,
  Button,
  CardHeader,
} from "@mui/material"
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import KonveiModal from "../../../components/modal/konvei-modal"
import SearchIcon from "@mui/icons-material/Search"
import SortByAlphaOutlinedIcon from "@mui/icons-material/SortByAlphaOutlined"
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined"
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined"
import { Participant, TeamParticipant } from "../../../landconnex-api-client"
import MemberView from "./MemberView"
import { useAppDispatch } from "../../../app/hooks"
import { removeUserFromTeam } from "../thunks"

interface MembersListProps {
  members: Partial<TeamParticipant>[]
  handleDeleteMember: (email: string) => void
  handleUpdateMember: (updatedMember: Partial<Participant>) => void
}

const MembersList: FC<MembersListProps> = ({ members = [] }) => {
  const dispatch = useAppDispatch()
  const [deleteSub, setDeleteSub] = useState<string>("")
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const [sortAsc, setSortAsc] = useState<boolean>(true)
  const [selectedMember, setSelectedMember] =
    useState<Partial<Participant> | null>(null)

  const [selectedSub, setSelectedSub] = useState<string>("")
  const openDeleteModal = (sub: string) => {
    setDeleteSub(sub)
    setModalOpen(true)
  }

  const closeDeleteModal = () => {
    setDeleteSub("")
    setModalOpen(false)
  }

  const confirmDelete = () => {
    dispatch(removeUserFromTeam(deleteSub)).then(_ => {
      closeDeleteModal()
    })
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const filteredMembers = members.filter(member =>
    member
      .participant!.firstName?.toLowerCase()
      .includes(searchValue.toLowerCase()),
  )

  const toggleSorting = () => {
    setSortAsc(prevSortAsc => !prevSortAsc)
  }

  const sortedMembers = filteredMembers.sort((a, b) => {
    if (sortAsc) {
      return a.participant!.firstName!.localeCompare(b.participant!.firstName!)
    } else {
      return b.participant!.firstName!.localeCompare(a.participant!.firstName!)
    }
  })

  const openMemberDetailsModal = (
    sub: string,
    member: Partial<Participant>,
  ) => {
    setSelectedMember(member)
    setSelectedSub(sub)
    setModalOpen(true)
  }

  const renderDeleteConfirmation = () => (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Confirm Deletion
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Are you sure you want to remove this member?
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 2 }}>
        <Button onClick={closeDeleteModal}>Cancel</Button>
        <Button onClick={confirmDelete}>Delete</Button>
      </Box>
    </Box>
  )

  return (
    <Card>
      <CardHeader title="Member List" />
      <CardContent>
        <Box
          sx={{
            width: "100%",
            padding: "30px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: {
              xs: 2,
              md: 1,
            },
          }}
        >
          <OutlinedInput
            id="input-search-header"
            value={searchValue}
            onChange={handleSearchChange}
            placeholder="Search Member"
            sx={{ width: { xs: "100%", md: "500px" }, borderRadius: "5px" }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <IconButton onClick={toggleSorting}>
            <SortByAlphaOutlinedIcon />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          {sortedMembers.map((member, index) => (
            <Grid item xs={12} lg={6} key={index}>
              <Card
                style={{
                  boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
                }}
                onClick={() =>
                  openMemberDetailsModal(member.sub!, member.participant!)
                }
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ListItemAvatar>
                      <Avatar
                        sx={{ width: 48, height: 48, bgcolor: "#8F1219" }}
                      >
                        {`${member.participant!.firstName?.charAt(
                          0,
                        )}${member.participant!.lastName?.charAt(0)}`}
                      </Avatar>
                    </ListItemAvatar>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" gutterBottom>
                        {member.participant!.firstName}{" "}
                        {member.participant!.lastName}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          marginBottom: 1,
                        }}
                      >
                        <AlternateEmailOutlinedIcon fontSize="small" />
                        <StyledLink
                          href={`mailto:${member.participant!.email}`}
                          sx={{ color: "#000", textDecoration: "none" }}
                        >
                          {member.participant!.email}
                        </StyledLink>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          marginBottom: 1,
                        }}
                      >
                        <PhoneAndroidOutlinedIcon fontSize="small" />
                        <StyledLink
                          href={`tel:${member.participant!.mobilePhone}`}
                          sx={{ color: "#000", textDecoration: "none" }}
                        >
                          {member.participant!.mobilePhone}
                        </StyledLink>
                      </Box>
                    </Box>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={e => {
                        e.stopPropagation()
                        openDeleteModal(member.sub!)
                      }}
                    >
                      <DeleteForeverOutlinedIcon sx={{ color: "#8F1219" }} />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <KonveiModal open={modalOpen} onClose={closeDeleteModal}>
          {deleteSub
            ? renderDeleteConfirmation()
            : selectedMember && (
                <MemberView sub={selectedSub} member={selectedMember} />
              )}
        </KonveiModal>
      </CardContent>
    </Card>
  )
}

const StyledLink = styled("a")(({ theme }) => ({
  ...theme.typography.body2,
  color: "#000",
}))

export default MembersList
