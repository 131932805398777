import { Box, Chip, darken, Typography } from "@mui/material"
import { ParticipantRoleEnum } from "../landconnex-api-client"

const RoleChip = ({ role }: { role: ParticipantRoleEnum }) => {
  const ParticipantChip = ({
    role,
    color,
  }: {
    role: string
    color: string
  }) => {
    return (
      <Box
        sx={{
          backgroundColor: color,
          border: "1px solid",
          borderColor: darken(color, 0.4),
          borderRadius: "4px",
          pl: 1,
          pr: 1,
          textAlign: "center",
        }}
      >
        <Typography variant="caption" textTransform="uppercase">
          {role}
        </Typography>
      </Box>
    )
  }
  switch (role) {
    case ParticipantRoleEnum.Seller:
        return <ParticipantChip role="Seller" color="#72E7B4" />
    case ParticipantRoleEnum.Buyer:
      return <Chip label="Buyer" />
    case ParticipantRoleEnum.SellerAgent:
      return <ParticipantChip role="Agent" color="#72BBE7" />
    case ParticipantRoleEnum.SellerSolicitor:
        return <ParticipantChip role="Solicitor" color="#B6E772" />

    case ParticipantRoleEnum.BuyerSolicitor:
      return <Chip label="Buyer Solicitor" />
    case ParticipantRoleEnum.Licencee:
      return <Chip label="Licencee" />
  }
}

export default RoleChip
