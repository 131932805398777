import { createSvgIcon } from "@mui/material"

// or with custom SVG
const OfferIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="h-6 w-6"
  >
    <rect
      id="rect200"
      width="18.382902"
      height="18.382902"
      x="2.8498774"
      y="3.4441996"
    />
    <text x="6.4695811" y="18.298901" id="text4999">
      <tspan id="tspan4997" x="6.4695811" y="18.298901">
        $
      </tspan>
    </text>

    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4.5v15m7.5-7.5h-15"
    />
  </svg>,
  "Offers",
)

export default OfferIcon
