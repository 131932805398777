import {
  Box,
  Button,
  Container,
  debounce,
  Stack,
  TextField,
} from "@mui/material"
import Page from "./components/page"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "./app/hooks"
import { Controller, useForm } from "react-hook-form"
import { Property } from "./landconnex-api-client"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import {
  getProperty,
  putProperty,
} from "./app/slices/property-slice"
import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getWorkspaces, selectWorkspaces } from "./app/slices/workspaces-slice"

type Properties<Input> = Required<{
  [K in keyof Input]: z.ZodType<Input[K], any, Input[K]>
}>

const Test = () => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const [workspaceId, setWorkspaceId] = useState<number>(0)
  const workspaces = useAppSelector(selectWorkspaces)
  const navigate = useNavigate()
  // Zod Schema for Validation
  const propertySchema = z.object<Properties<Property>>({
    area: z.string().optional(),
    holdingType: z.string().optional(),
    isBuiltOn: z.boolean().optional(),
    lga: z.string().optional(),
    locality: z.string(),
    lot: z.string().optional(),
    plan: z.string().optional(),
    postCode: z.string(),
    presentUse: z.string().optional(),
    stateOrTerritory: z.string(),
    streetAddress1: z
      .string()
      .min(1, { message: "Street Address 1 is required" }),
    streetAddress2: z.string().optional(),
    titleReference: z.string().optional(),
  })

  const handleOnSubmit = (data: Property) => {
    dispatch(
      putProperty({
        workspaceId: workspaceId,
        property: data,
      }),
    )
  }

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid, isDirty, defaultValues },
  } = useForm<Property>({
    resolver: zodResolver(propertySchema),
    mode: "onChange",
    defaultValues: {
      streetAddress1: "",
      streetAddress2: "",
      postCode: "",
      locality: "",
      stateOrTerritory: "",
      lot: "",
    },
  })

  useEffect(() => {
    if (workspaceId !== parseInt(params.workspaceId!)) {
      dispatch(getProperty(parseInt(params.workspaceId!))).then(data => {
        reset(data.payload as Property)
      })
      setWorkspaceId(parseInt(params.workspaceId!))
    }
  }, [dispatch, workspaceId, params])

  useEffect(() => {
    if (workspaces.length === 0) {
      dispatch(getWorkspaces())
    }
  }, [workspaces])
  const formValues = watch()

  // Debounced autosave function
  const debouncedAutoSave = React.useCallback(
    debounce((data: Property) => {
      dispatch(
        putProperty({
          workspaceId: 488,
          property: data,
        }),
      )
      reset(data, { keepErrors: true })
    }, 1000),
    [dispatch],
  )

  // Trigger autosave when form values change
  useEffect(() => {
    if (isDirty) {
      debouncedAutoSave(formValues)
    }
  }, [formValues, isDirty, debouncedAutoSave])

  return (
    <Page>
      <Container>
        <Stack direction="row" spacing={2}>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <Controller
              name="lot"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Lot"
                  error={!!errors.lot}
                  helperText={errors.lot?.message}
                  margin="normal"
                />
              )}
            />
            <Button type="submit" variant="contained" color="primary">
              Save Profile
            </Button>
          </form>
          <Box>
            {workspaces.map(workspace => (
              <Button
                sx={{ width: "100px" }}
                key={workspace.workspace.id}
                onClick={() => navigate(`/test/${workspace.workspace.id}`)}
              >
                {workspace.workspace.id}
              </Button>
            ))}
          </Box>
        </Stack>
        <Box sx={{ border: "1px solid #ccc", p: 2, borderRadius: 2 }}>
          <pre>isDirty: {isDirty ? "true" : "false"}</pre>
          <pre>isValid: {isValid ? "true" : "false"}</pre>
          <pre>{JSON.stringify(defaultValues, null, 2)}</pre>
        </Box>
      </Container>
    </Page>
  )
}

export default Test
