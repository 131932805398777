import React, { useCallback } from "react"
import FormInputText from "../../../components/form-input-text"
import { debounce } from "@mui/material"
import { useForm, SubmitHandler, useWatch } from "react-hook-form"
import useDeepCompareEffect from "use-deep-compare-effect"
import { Contract } from "../../../landconnex-api-client"
import FormWrapper from "../../agent-appointment/components/form-wrapper"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { putContract, selectContract } from "../../../app/slices/contract-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"

const SpecialConditions: React.FC = ({}) => {
  const dispatch = useAppDispatch()

  const contract = useAppSelector(selectContract)
  const workspace = useAppSelector(selectWorkspace)
  const { control, formState, handleSubmit } = useForm<Contract>({
    defaultValues: contract!,
  })

  // useEffect(() => {
  //   reset(contract);
  //   trigger();
  // }, [contract]);

  const onSubmit: SubmitHandler<Contract> = data => {
    dispatch(
      putContract({
        workspaceId: workspace!.workspace!.id,
        contract: data,
      }),
    )
  }

  const watchedData = useWatch({
    control: control,
    defaultValue: contract,
  })

  const debouncedSave = useCallback(
    debounce(() => {
      console.log("Saving")
      handleSubmit(onSubmit)()
    }, 1000),
    [],
  )
  useDeepCompareEffect(() => {
    console.log("Triggered")
    if (formState.isDirty) {
      debouncedSave()
    }
  }, [watchedData])

  return (
    <FormWrapper title="Special Conditions">
      <FormInputText
        control={control}
        name="specialConditions"
        label="Special Conditions"
        multiline
        minRows={4}
      />
    </FormWrapper>
  )
}

export default SpecialConditions
