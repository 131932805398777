import type { Action, ThunkAction } from "@reduxjs/toolkit"
import { combineSlices, configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"

import { workspacesSlice } from "./slices/workspaces-slice"
// import { workspaceSlice } from "../features/workspace/workspace-slice"
// import { createWorkspaceSlice } from "../features/create-workspace/create-workspace-slice"
// import { agentAppointmentSlice } from "../features/agent-appointment/agent-appointment-slice"
// import { offersSlice } from "../features/offers/offers-slice"
import { sessionSlice } from "./slices/session-slice"
import { authCallbackSlice } from "../features/auth-callback/auth-callback-slice"
import { offerDraftSlice } from "../features/offer-draft/offer-drafts-slice"
import { viewInvoiceSlice } from "../features/view-invoice/view-invoice-slice"
import { viewDocumentSlice } from "../features/view-document/view-document-slice"
// import { createSubscriptionSlice } from "../features/create-subscription/create-subscription-slice"
import { workspaceEventsSlice } from "../features/events/eventsSlice"
import { manageUsersSlice } from "../features/manage-users/manage-users-slice"
// import { contractSlice } from "../features/contract/contract-slice"
import { settingsSlice } from "./slices/settings-slice"
import { myDetailsSlice } from "./slices/my-details-slice"
import { teamSlice } from "./slices/team-slice"
import { participantsSlice } from "./slices/participants-slice"
import { documentsSlice } from "./slices/documents-slice"
import { offersSlice } from "./slices/offers-slice"
import { contractSlice } from "./slices/contract-slice"
import { propertySlice } from "./slices/property-slice"
import { agentAppointmentSlice } from "./slices/agent-appointment-slice"
import { teamMembersSlice } from "./slices/team-members-slice"
import { titlesSlice } from "./slices/titles-slice"
import { addressSearchSlice } from "./slices/address-search-slice"
import { actionsMiddleware } from "./middleware/actions-middleware"
import { reportsSlice } from "./slices/reports-slice"
// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
const rootReducer = combineSlices(
  addressSearchSlice,
  // agentAppointmentSlice,
  agentAppointmentSlice,
  authCallbackSlice,
  contractSlice,
  // createWorkspaceSlice,
  documentsSlice,
  // createSubscriptionSlice,
  manageUsersSlice,
  myDetailsSlice,
  offerDraftSlice,
  offersSlice,
  participantsSlice,
  propertySlice,
  reportsSlice,
  sessionSlice,
  settingsSlice,
  settingsSlice,
  teamSlice,
  teamMembersSlice,
  titlesSlice,
  viewDocumentSlice,
  viewInvoiceSlice,
  workspaceEventsSlice,
  workspacesSlice,
  // workspaceSlice,
)
// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof rootReducer>

const aMiddleware = actionsMiddleware.middleware

// The store setup is wrapped in `makeStore` to allow reuse
// when setting up tests that need the same store config
export const makeStore = (preloadedState?: Partial<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(aMiddleware),

    preloadedState,
  })
  // configure listeners using the provided defaults
  // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
  setupListeners(store.dispatch)
  return store
}

export const store = makeStore()

// Infer the type of `store`
export type AppStore = typeof store
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore["dispatch"]
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>
