import { createAppSlice } from "../../app/createAppSlice"
import {
  OfferDetail,
  Property,
  Workflow,
  WorkspaceSummary,
  WorkspaceSummaryNew,
} from "../../landconnex-api-client"
import { workspacesApi } from "../../api"

export enum WorkspacesStatus {
  initial,
  loading,
  ready,
  error,
}
export interface IndexedWorkflowState extends Workflow {
  workspaceId: number
}

export interface WorkspaceDetails {
  workspace: WorkspaceSummaryNew
  workflow: Workflow | undefined
}
interface WorkspacesState {
  workspacesStatus: WorkspacesStatus
  workspaceStatus: WorkspacesStatus
  acceptInvitationStatus: WorkspacesStatus
  workspaces: Array<WorkspaceDetails>
  workspace: WorkspaceSummary | undefined
  workflow: Workflow | undefined
  workflowStates: Array<IndexedWorkflowState>
  offers: OfferDetail[]
}

const initialState: WorkspacesState = {
  workspacesStatus: WorkspacesStatus.initial,
  workspaceStatus: WorkspacesStatus.initial,
  acceptInvitationStatus: WorkspacesStatus.initial,
  workspaces: [],
  workspace: undefined,
  workflow: undefined,
  workflowStates: [],
  offers: [],
}

export interface GetWorkflowStateArgs {
  workspaceId: number
}

export interface PostWorkspaceResponse {
  workspace: WorkspaceSummaryNew
  workflow: Workflow
}

export interface PostWorkspaceArgs {
  agentId: string
  property: Property
}

export interface GetWorkflowStateResponse {
  workspaceId: number
  workflow: Workflow
}
// If you are not using async thunks you can use the standalone `createSlice`.
export const workspacesSlice = createAppSlice({
  name: "workspaces",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: create => ({
    // The function below is called a thunk and allows us to perform async logic. It
    // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
    // will call the thunk with the `dispatch` function as the first argument. Async
    // code can then be executed and other actions can be dispatched. Thunks are
    // typically used to make async requests.
    getWorkspaces: create.asyncThunk(
      async () => {
        const result = workspacesApi.getWorkspaces2()
        return (await result).data
      },
      {
        pending: state => {
          state.workspacesStatus = WorkspacesStatus.loading
        },
        fulfilled: (state, action) => {
          state.workspacesStatus = WorkspacesStatus.ready
          state.workspaces = action.payload.items!.map<WorkspaceDetails>(
            workspace => ({
              workspace: workspace,
              workflow: undefined,
            }),
          )
        },
        rejected: state => {
          state.workspacesStatus = WorkspacesStatus.error
        },
      },
    ),
    getWorkspace: create.asyncThunk(
      async (workspaceId: number) => {
        const result = await workspacesApi.getWorkspace(workspaceId)
        return result.data
      },
      {
        pending: state => {
          state.workspaceStatus = WorkspacesStatus.loading
          state.workspace = undefined
          state.workflow = undefined
          state.offers = []
        },
        fulfilled: (state, action) => {
          state.workspaceStatus = WorkspacesStatus.ready
          state.workspace = action.payload
        },
        rejected: state => {
          state.workspaceStatus = WorkspacesStatus.error
        },
      },
    ),
    postWorkspace: create.asyncThunk<WorkspaceSummaryNew, PostWorkspaceArgs>(
      async ({ agentId, property }) => {
        const result = await workspacesApi.postWorkspace({
          property: property,
          agentId: agentId,
        })

        const newWorkspace: WorkspaceSummaryNew = {
          id: result.data.id!,
          property: property,
          sellers: [],
          price: 0,
          agent: {
            id: 1,
            firstName: "",
            lastName: "",
          },
          createdDate: new Date().toISOString(),
          role: "sellerAgent",
        }
        return newWorkspace
      },
      {
        pending: state => {
          state.workspaceStatus = WorkspacesStatus.loading
        },
        fulfilled: (state, _action) => {
          state.workspaceStatus = WorkspacesStatus.ready
          
          
        },
        rejected: state => {
          state.workspaceStatus = WorkspacesStatus.error
        },
      },
    ),
    getWorkflow: create.asyncThunk<GetWorkflowStateResponse, number>(
      async (workspaceId: number) => {
        const result = await workspacesApi.getWorkflow(workspaceId)
        return { workspaceId: workspaceId, workflow: result.data }
      },
      {
        fulfilled: (state, action) => {
          const workspace = state.workspaces.find(
            w => w.workspace.id === action.payload.workspaceId,
          )
          if (workspace) {
            workspace.workflow = action.payload.workflow
          }
          state.workflow = action.payload.workflow
        },
      },
    ),
    acceptInvitation: create.asyncThunk<string, string>(
      async (invitationToken: string) => {
        await workspacesApi.acceptInvitation(invitationToken)
        return invitationToken
      },
      {
        pending: state => {
          state.acceptInvitationStatus = WorkspacesStatus.loading
        },
        fulfilled: state => {
          state.acceptInvitationStatus = WorkspacesStatus.ready
        },
        rejected: state => {
          state.acceptInvitationStatus = WorkspacesStatus.error
        },
      },
    ),
  }),
  // You can define your selectors here. These selectors receive the slice
  // state as their first argument.
  selectors: {
    selectWorkspaces: workspaces => workspaces.workspaces,
    selectOffers: workspaces => workspaces.offers,
    selectWorkspacesStatus: workspaces => workspaces.workspacesStatus,
    selectWorkspaceStatus: workspaces => workspaces.workspaceStatus,

    selectWorkspace: workspaces => workspaces.workspace,
    selectWorkflow: workspaces => workspaces.workflow,
    selectAcceptInvitationStatus: workspaces =>
      workspaces.acceptInvitationStatus,
  },
})

// Action creators are generated for each case reducer function.
export const {
  getWorkspaces,
  getWorkflow,
  getWorkspace,
  acceptInvitation,
  postWorkspace,
} = workspacesSlice.actions

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const {
  selectWorkspaces,
  selectOffers,
  selectWorkspacesStatus,
  selectWorkspaceStatus,
  selectAcceptInvitationStatus,
  selectWorkspace,
  selectWorkflow,
} = workspacesSlice.selectors

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState())

//     if (currentValue % 2 === 1 || currentValue % 2 === -1) {
//       dispatch(incrementByAmount(amount))
//     }
//   }
