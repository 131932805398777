import {
  Grid,
  Typography,
  debounce,
  useTheme,
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import React, { FC, useEffect, useState } from "react"
import ConfirmationDialog from "../../../components/confirmation-dialog"
import {
  Address,
  Participant,
  ParticipantInvitationStatusEnum,
  ParticipantParticipantTypeEnum,
  ParticipantRoleEnum,
  PhysicalAustralianAddress,
} from "../../../landconnex-api-client"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { useAppDispatch } from "../../../app/hooks"
import {
  getParticipant,
  putParticipant,
} from "../../../app/slices/participants-slice"
import { useParams } from "react-router-dom"
import AddressLookup from "../../../components/address-lookup"
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import FormInputText from "../../../components/form-input-text"

export interface ClientDetailProps {
  client: Participant
  index: number
  disabled?: boolean
  participantId?: number
}

const ClientDetail: FC<ClientDetailProps> = ({
  index,
  disabled,
  participantId,
}) => {
  const [confirmationDialogState, setConfirmationDialogState] =
    useState(Array<number>())
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const params = useParams()
  const [editingParticipantId, setEditingParticipantId] = useState<number>(0)
  const workspaceId = Number(params.workspaceId)

  type Properties<Input> = Required<{
    [K in keyof Input]: z.ZodType<Input[K], any, Input[K]>
  }>

  const australianPostcodeSchema = z.string().refine(
    (postcode: any) => {
      // Remove any whitespace and check it's exactly 4 digits
      const cleaned = postcode.replace(/\s/g, "")
      return /^\d{4}$/.test(cleaned)
    },
    { message: "Invalid Australian postcode - must be 4 digits" },
  )
  const australianPhoneSchema = z.string().refine(
    (phone: any) => {
      // Remove all non-digit characters
      const cleaned = phone.replace(/[^\d]/g, "")

      // Check common Australian phone number formats
      return (
        // Mobile numbers (start with 04)
        /^04\d{8}$/.test(cleaned) ||
        // Landline numbers (start with 02, 03, 07, 08)
        /^0[23578]\d{8}$/.test(cleaned) ||
        // International format with country code
        /^\+61[45]\d{8}$/.test(cleaned) ||
        /^\+61[23578]\d{8}$/.test(cleaned)
      )
    },
    { message: "Invalid Australian phone number" },
  )
  const participantSchema = z.object<Properties<Participant>>({
    id: z.number(),
    firstName: z
      .string()
      .min(2, { message: "Name must be at least 2 characters" })
      .max(50, { message: "Name cannot exceed 50 characters" }),
    lastName: z
      .string()
      .min(2, { message: "Name must be at least 2 characters" })
      .max(50, { message: "Name cannot exceed 50 characters" }),
    participantType: z.nativeEnum(ParticipantParticipantTypeEnum),
    externalReference: z.string().optional(),
    role: z.nativeEnum(ParticipantRoleEnum),
    licenceeNumber: z.string().optional(),
    licenceeExpiryDate: z.string().optional(),
    tradingName: z.string().optional(),
    createdAt: z.string().optional(),
    createdBy: z.string().optional(),
    modifiedAt: z.string().optional(),
    modifiedBy: z.string().optional(),
    invitationStatus: z.nativeEnum(ParticipantInvitationStatusEnum),
    middleNames: z.string().optional(),
    streetAddress1: z.string().optional(),
    streetAddress2: z.string().optional(),
    locality: z.string().optional(),
    stateOrTerritory: z.string().optional(),
    postCode: australianPostcodeSchema,
    country: z.string().optional(),
    phone: australianPhoneSchema,
    mobilePhone: australianPhoneSchema.optional(),
    countryCode: z.string().optional(),
    email: z.string().email({ message: "Invalid email address" }),
    organisationName: z.string().optional(),
    abn: z.string().optional(),
    acn: z.string().optional(),
    registeredForGst: z.boolean().optional(),
  })

  const participantForm = useForm<Participant>({
    resolver: zodResolver(participantSchema),
    mode: "onChange",
    defaultValues: {
      id: 0,
      firstName: "",
      lastName: "",
      participantType: ParticipantParticipantTypeEnum.Individual,
      role: ParticipantRoleEnum.Buyer,
      licenceeNumber: "",
      licenceeExpiryDate: "",
      tradingName: "",
      middleNames: "",
      streetAddress1: "",
      streetAddress2: "",
      locality: "",
      stateOrTerritory: "",
      postCode: "",
      country: "",
      phone: "",
      mobilePhone: "",
      countryCode: "",
      email: "",
      organisationName: "",
      abn: "",
      acn: "",
      registeredForGst: false,
    },
  })
  const {
    control,
    watch,
    formState: { errors, isDirty, defaultValues },
    setValue,
    reset,
  } = participantForm

  const formValues = watch()

  const saveParticipant = (data: Participant) => {
    try {
      dispatch(putParticipant({ participant: data, workspaceId: workspaceId! }))
      reset(data, { keepErrors: true })
    } catch (serverError) {
      console.error("Autosave failed", serverError)
    }
  }

  // Debounced autosave function
  const debouncedAutoSave = React.useCallback(
    debounce(async (data: Participant) => {
      saveParticipant(data)
    }, 1000),
    [dispatch, workspaceId],
  )
  useEffect(() => {
    if (isDirty) {
      debouncedAutoSave(formValues)
    }
  }, [formValues, isDirty, debouncedAutoSave])

  useEffect(() => {
    if (editingParticipantId !== participantId) {
      dispatch(
        getParticipant({
          participantId: participantId!,
          workspaceId: workspaceId!,
        }),
      ).then(data => {
        reset(data.payload as Participant)
      })
      setEditingParticipantId(participantId!)
    }
  }, [editingParticipantId])

  function handleAddressSelected(
    _address: PhysicalAustralianAddress,
    add: Address,
  ): void {
    const update = { ...formValues }
    update.streetAddress1 = add.streetAddress1
    update.locality = add.locality
    update.stateOrTerritory = add.stateOrTerritory
    update.postCode = add.postCode
    update.country = add.country
    saveParticipant(update)
  }

  return (
    <React.Fragment key={participantId!}>
      <Grid item xs={12} sm={12}>
        <ConfirmationDialog
          open={confirmationDialogState.indexOf(participantId!) > -1}
          message={`Remove ${
            defaultValues!.firstName ? defaultValues!.firstName : "un-named"
          } from the contract?`}
          title="Remove client?"
          onClose={result => {
            if (result) {
              setConfirmationDialogState([])
            } else {
              setConfirmationDialogState([])
            }
          }}
        ></ConfirmationDialog>
      </Grid>
      <Grid item>
        <Grid component="form" container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">Client {index + 1}</Typography>

              <IconButton
                size="small"
                color="primary"
                onClick={() => setConfirmationDialogState([participantId!])}
              >
                <DeleteForeverOutlinedIcon fontSize="large" color="error" />
              </IconButton>
            </Box>
            <Divider />
          </Grid>
          <Grid item xs={3} sm={3}>
            <FormControl fullWidth variant="filled">
              <InputLabel size="small" id="clientTypeLabel">
                client type
              </InputLabel>
              <Select
                size="small"
                labelId="clientTypeLabel"
                name="participantType"
                label="Client Type"
                variant="standard"
                disabled={disabled}
                defaultValue={defaultValues!.participantType}
                onChange={val => {
                  const p = { ...defaultValues }
                  p.participantType = val.target
                    .value as ParticipantParticipantTypeEnum
                  setValue("participantType", p.participantType)
                }}
              >
                {Object.values(ParticipantParticipantTypeEnum).map(p => (
                  <MenuItem key={p} value={p}>
                    {p}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Name
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormInputText
              name="firstName"
              control={control}
              label="First Name"
              error={errors.firstName}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormInputText
              name="middleNames"
              control={control}
              label="Middle Names"
              error={errors.middleNames}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormInputText
              name="lastName"
              control={control}
              label="Last Name"
              error={errors.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Contact
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormInputText
              name="phone"
              control={control}
              label="Phone"
              error={errors.phone}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FormInputText
              name="email"
              control={control}
              label="Email"
              error={errors.email}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Address
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <AddressLookup onAddressSelected={handleAddressSelected} />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInputText
              name="streetAddress1"
              control={control}
              label="Street Address"
              error={errors.streetAddress1}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInputText
              name="streetAddress2"
              control={control}
              label="Street Address 2"
              error={errors.streetAddress2}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormInputText
              name="locality"
              control={control}
              label="Locality"
              error={errors.locality}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormInputText
              name="stateOrTerritory"
              control={control}
              label="State or Territory"
              error={errors.stateOrTerritory}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormInputText
              name="postCode"
              control={control}
              label="Post Code"
              error={errors.postCode}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default ClientDetail
