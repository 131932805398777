import { Card, CardContent, CircularProgress, Typography } from "@mui/material"

const AwaitingTaskCompletion = ({
  children,
}: {
  children: React.ReactNode
}) => {
  //  const theme = useTheme()
  // const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  // const isMedium = useMediaQuery(theme.breakpoints.between("sm", "lg"))
  // const isLarge = useMediaQuery(theme.breakpoints.up("lg"))
  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "50vh",
          gap: 3,
          background: "#fff",
        }}
      >
        <CircularProgress size={80} />
        <Typography variant="h6" align="center">
          {children}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default AwaitingTaskCompletion
