import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material"
import { Participant } from "../landconnex-api-client"
import { participantName } from "../helpers/helpers"
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined"
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined"
import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"

export interface ParticipantCardProps {
  participant: Participant
}
const ParticipantCard: React.FC<ParticipantCardProps> = ({ participant }) => {
  const roleTitles: Record<string, string> = {
    sellerAgent: "Agent",
    seller: "Seller",
    sellerSolicitor: "Seller Solicitor",
    buyer: "Buyer",
    buyerSolicitor: "Buyer Solicitor",
  }
  const title = roleTitles[participant.role!] || ""
  return (
    <Card sx={{ width: "100%" }}>
      <CardHeader
        title={participantName(participant)}
        subheader={title}
        action={
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={() => window.open(`tel:${participant.phone}`, "_self")}
            >
              <PhoneIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                window.open(`mailto:${participant.email}`, "_self")
              }
            >
              <EmailIcon />
            </IconButton>
          </Box>
        }
      />
      <CardContent sx={{ padding: 0 }}>
        <List>
          <ListItem>
            <IconButton
              onClick={() => window.open(`tel:${participant.phone}`, "_self")}
            >
              <PhoneAndroidOutlinedIcon sx={{ marginRight: 1 }} />
            </IconButton>
            <ListItemText>{participant.phone}</ListItemText>
          </ListItem>
          <ListItem>
            <IconButton
              onClick={() =>
                window.open(`mailto:${participant.email}`, "_self")
              }
            >
              <AlternateEmailOutlinedIcon sx={{ marginRight: 1 }} />
            </IconButton>
            <ListItemText>{participant.email}</ListItemText>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}

export default ParticipantCard
