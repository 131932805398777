import { FC, Fragment, useEffect, useState } from "react"
import {
  Grid,
  Typography,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  Box,
} from "@mui/material"
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import {
  Participant,
  ParticipantParticipantTypeEnum,
} from "../../../landconnex-api-client"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import ConfirmationDialog from "../../../components/confirmation-dialog"
import ContactDetails from "../../../components/contact-details"
import ParticipantNameForm from "../../../components/participant-name-form"
import AddressForm from "../../../components/address-form"
import AutoSave from "../../../components/auto-save"
import {
  deleteParticipant,
  postParticipant,
  putParticipant,
} from "../../../app/slices/participants-slice"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"

export interface ClientDetailProps {
  client: Participant
  index: number
}

const ClientDetail: FC<ClientDetailProps> = ({ client, index }) => {
  const dispatch = useAppDispatch()
  const workspace = useAppSelector(selectWorkspace)
  const [localClient, setLocalClient] = useState(client)
  const [confirmationDialogState, setConfirmationDialogState] = useState<
    number[]
  >([])
  const theme = useTheme()
  const formControls = useForm<Participant>({
    defaultValues: localClient,
    // resolver: yupResolver(schema),
  })
  const { handleSubmit, control, setValue, reset, trigger } = formControls

  useEffect(() => {
    setLocalClient(client)
    reset(client)
    trigger()
  }, [client])

  const onSubmit: SubmitHandler<Participant> = data => {
    if (data.id == null) {
      dispatch(
        postParticipant({
          workspaceId: workspace!.workspace!.id,
          participant: data,
        }),
      )
    } else {
      dispatch(
        putParticipant({
          workspaceId: workspace!.workspace!.id,
          participant: data,
        }),
      )
    }
  }

  const handleDelete = () => {
    dispatch(
      deleteParticipant({
        workspaceId: workspace!.workspace!.id,
        participant: localClient,
      }),
    )
  }

  const handleChanged = (data: Participant) => {
    if (data.id == null) {
      dispatch(
        postParticipant({
          workspaceId: workspace!.workspace!.id,
          participant: data,
        }),
      )
    } else {
      dispatch(
        putParticipant({
          workspaceId: workspace!.workspace!.id,
          participant: data,
        }),
      )
    }
  }
  return (
    <Fragment>
      <Grid key={localClient.id} item xs={12} sm={12}>
        <ConfirmationDialog
          open={confirmationDialogState.includes(localClient.id!)}
          message={`Remove ${
            client.organisationName ||
            (client.firstName && client.lastName
              ? `${client.firstName} ${client.lastName}`
              : `Client ${index + 1}`)
          } from the contract?`}
          title="Remove client?"
          onClose={result => {
            if (result) {
              handleDelete()
            }
            setConfirmationDialogState([])
          }}
        />
      </Grid>

      <Grid item>
        <FormProvider {...formControls}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">
                    {`${
                      client.organisationName ||
                      (client.firstName && client.lastName
                        ? `${client.firstName} ${client.lastName}`
                        : `Client ${index + 1}`)
                    }`}
                  </Typography>
                  <IconButton
                    color="primary"
                    onClick={() =>
                      setConfirmationDialogState([localClient.id!])
                    }
                  >
                    <DeleteForeverOutlinedIcon fontSize="large" color="error" />
                  </IconButton>
                </Box>
                <Divider />
              </Grid>

              {/* Select client type */}
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth variant="filled">
                  <InputLabel size="small" id="clientTypeLabel">
                    Client Type
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="clientTypeLabel"
                    name="participantType"
                    label="Client Type"
                    value={localClient.participantType}
                    onChange={event => {
                      const updatedClient = {
                        ...localClient,
                        participantType: event.target
                          .value as ParticipantParticipantTypeEnum,
                      }
                      setLocalClient(updatedClient) // Update local client state
                      handleChanged(updatedClient) // Call onChanged with updated client
                    }}
                  >
                    {Object.values(ParticipantParticipantTypeEnum).map(type => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Participant name form */}
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ParticipantNameForm
                  control={control}
                  captureMiddleName={true}
                  captureGst={true}
                  participantType={localClient.participantType!}
                />
              </Grid>

              {/* Contact details */}
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Contact Details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ContactDetails control={control} />
              </Grid>

              {/* Address form */}
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Address
                </Typography>
              </Grid>
              <AddressForm
                control={control}
                address={localClient}
                setValue={setValue}
              />
            </Grid>
            <AutoSave defaultValues={client} onSubmit={onSubmit} />
          </form>
        </FormProvider>
      </Grid>
    </Fragment>
  )
}

export default ClientDetail
