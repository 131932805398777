import { documentsApi } from "../../api"
import { AnnexureDocument } from "../../landconnex-api-client"
import { createAppSlice } from "../createAppSlice"

export enum DocumentsStatus {
  initial = "initial",
  loading = "loading",
  success = "success",
  error = "error",
}

export interface DocumentsState {
  status: DocumentsStatus
  documents: AnnexureDocument[]
}

const initialState: DocumentsState = {
  status: DocumentsStatus.initial,
  documents: [],
}

export const documentsSlice = createAppSlice({
  name: "documents",
  initialState,

  reducers: create => ({
    getDocuments: create.asyncThunk<AnnexureDocument[], number>(
      async (workspaceId: number) => {
        const response = await documentsApi.getDocuments(workspaceId)
        return response.data.items!
      },
      {
        pending: state => {
          state.status = DocumentsStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = DocumentsStatus.success
          state.documents = action.payload
        },
        rejected: state => {
          state.status = DocumentsStatus.error
        },
      },
    ),
  }),
  selectors: {
    selectDocuments: documents => documents.documents,
    selectDocumentsStatus: documents => documents.status,
  },
})

export const { getDocuments } = documentsSlice.actions
export const { selectDocuments, selectDocumentsStatus } = documentsSlice.selectors

