import { FC } from "react";
import { Property } from "../landconnex-api-client";
import { Box, Typography, Stack, Divider, Link } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArticleIcon from '@mui/icons-material/Article';

export interface AddressLabelProps {
  property: Property;
}

const AddressLabel: FC<AddressLabelProps> = ({ property }) => {
  const getGoogleMapsUrl = () => {
    const address = [
      property.streetAddress1,
      property.streetAddress2,
      property.locality,
      property.stateOrTerritory,
      property.postCode
    ].filter(Boolean).join(', ');
    
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  };

  return (
    <Box sx={{ p: 1, borderRadius: 1, bgcolor: 'background.paper' }}>
      <Stack spacing={1}>
        {/* Primary Address */}
        <Link
          href={getGoogleMapsUrl()}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ 
            textDecoration: 'none',
            '&:hover': {
              '& .MuiTypography-root': {
                color: 'error.dark'
              }
            }
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <LocationOnIcon color="error" />
            <Typography variant="h6" color="error.main" fontWeight={500}>
              {property.streetAddress1}
            </Typography>
          </Stack>
        </Link>

        {/* Secondary Address */}
        {property.streetAddress2 && (
          <Typography variant="subtitle1" color="primary">
            {property.streetAddress2}
          </Typography>
        )}

        {/* Location Details */}
        <Typography variant="body1">
          {property.locality}, {property.stateOrTerritory} {property.postCode}
        </Typography>

        <Divider sx={{ my: 1 }} />

        {/* Property Details */}
        <Stack spacing={0.5}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <ArticleIcon fontSize="small" color="action" />
            <Typography variant="body2" color="text.secondary">
              Lot {property.lot} on plan {property.plan}
            </Typography>
          </Stack>
          
          <Typography variant="body2" color="text.secondary">
            Title Reference: {property.titleReference}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AddressLabel;
