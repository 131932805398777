import { FC, Fragment, useEffect } from "react"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormGroup,
  Grid,
  Typography,
  useTheme,
} from "@mui/material"
import AddressForm from "../../../components/address-form"
import ParticipantNameForm from "../../../components/participant-name-form"
import FormInputCheckbox from "../../../components/form-input-checkbox"
import FormInputText from "../../../components/form-input-text"
import ContactDetails from "../../../components/contact-details"
import FormInputDatePicker from "../../../components/form-input-date-picker"
import {
  AgentAppointment,
  Participant,
  ParticipantRoleEnum,
} from "../../../landconnex-api-client"

import FormWrapper, { Notes } from "./form-wrapper"
import { useParams } from "react-router-dom"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import AutoSave from "../../../components/auto-save"
import {
  putParticipant,
  selectParticipants,
} from "../../../app/slices/participants-slice"
import {
  selectAgentAppointment,
  updateAgentAppointment,
} from "../../../app/slices/agent-appointment-slice"

const LicenceeDetails: FC = () => {
  const agentAppointment = useAppSelector(selectAgentAppointment)
  const participants = useAppSelector(selectParticipants)

  const agent = participants.find(
    p => p.role === ParticipantRoleEnum.SellerAgent,
  )
  const params = useParams()
  const dispatch = useAppDispatch()
  const workspaceId = Number(params.workspaceId)
  const theme = useTheme()

  // const licenceeSchema = yup
  //   .object({
  //     organisationName: yup.string().required(),
  //     abn: yup
  //       .string()
  //       .matches(/^(\d *?){11}$/, "must have 11 digits")
  //       .required(),
  //     acn: yup
  //       .string()
  //       .matches(/^(\d *?){9}$/, "must have 9 digits")
  //       .required(),
  //   })
  //   .required();

  const agentAppointmentFormMethods = useForm<AgentAppointment>({
    defaultValues: agentAppointment,
    //resolver: yupResolver(agentAppointmentSchema),
  })
  const {
    handleSubmit: agentAppointmentHandleSubmit,
    control: agentAppointmentControl,
    //  reset: agentAppointmentReset,
  } = agentAppointmentFormMethods

  const licenceeAgentFormMethods = useForm<Participant>({
    defaultValues: agent,
    //  resolver: yupResolver(participantSchema),
  })

  const {
    handleSubmit: sellerAgentHandleSubmit,
    control: sellerAgentControl,
    reset: sellerAgentReset,
    setValue: sellerAgentSetValue,
    trigger: sellerAgentTrigger,
  } = licenceeAgentFormMethods

  // useEffect(() => {
  //   agentAppointmentReset(agentAppointment)
  // }, [agentAppointment])

  useEffect(() => {
    sellerAgentReset(agent)
    sellerAgentTrigger()
  }, [agent])

  const onSellerAgentSubmit: SubmitHandler<Participant> = data => {
    dispatch(putParticipant({ participant: data, workspaceId: workspaceId }))
  }

  const notes = [
    {
      title: "Licensee Type:",
      items: [
        "More than one box may be ticked if appropriate.",
        "Annexures detailing conjuncting agents may be attached if required.",
      ],
    },
    {
      title: "Licensee Name:",
      items: [
        "Where a corporation licensee is to be appointed, state the corporation’s name and licence number.",
        "Where a sole trader is to be appointed, state the individual’s name and licence number.",
      ],
    },
  ]

  const onSubmitAgentAppointment: SubmitHandler<AgentAppointment> = data => {
    dispatch(
      updateAgentAppointment({
        agentAppointment: data,
        workspaceId: workspaceId,
      }),
    )
  }

  return (
    <Card sx={{ border: "none" }}>
      <CardHeader title="Licencee Details" />
      <CardContent>
        <Notes notes={notes} />
        <FormProvider {...agentAppointmentFormMethods}>
          <form
            onSubmit={agentAppointmentHandleSubmit(onSubmitAgentAppointment)}
          >
            <FormGroup>
              <Box sx={{ display: "flex" }}>
                <FormInputCheckbox
                  control={agentAppointmentControl}
                  name="isRealEstateAgent"
                  label="Real estate agent"
                />
                <FormInputCheckbox
                  control={agentAppointmentControl}
                  name="isLettingAgent"
                  label="Resident Letting Agent"
                />
                <FormInputCheckbox
                  control={agentAppointmentControl}
                  name="isPropertyAuctioneer"
                  label="Property Auctioneer"
                />
              </Box>
            </FormGroup>
            <AutoSave
              defaultValues={agentAppointment}
              onSubmit={onSubmitAgentAppointment}
            ></AutoSave>
          </form>
        </FormProvider>
        <FormProvider {...licenceeAgentFormMethods}>
          <form onSubmit={sellerAgentHandleSubmit(onSellerAgentSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Licencee
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">Agent Name</Typography>
              </Grid>

              <Grid item xs={12}>
                <ParticipantNameForm
                  control={sellerAgentControl}
                  participantType="individual"
                  captureMiddleName={false}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">Licencee</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  control={sellerAgentControl}
                  name="tradingName"
                  label="Trading name"
                />
              </Grid>

              <Grid item xs={12}>
                <ParticipantNameForm
                  control={sellerAgentControl}
                  participantType={agent!.participantType!}
                  captureMiddleName={false}
                  organisationLabel="Licencee Name"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormInputText
                  control={sellerAgentControl}
                  label="Licence Number"
                  name="licenceeNumber"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormInputDatePicker
                  control={sellerAgentControl}
                  name={"licenceeExpiryDate"}
                  label={"Licence Expiry"}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Contact details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ContactDetails control={sellerAgentControl} />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Address
                </Typography>
              </Grid>
              <AddressForm
                control={sellerAgentControl}
                setValue={sellerAgentSetValue}
                address={{ ...agent }}
              />
              <AutoSave defaultValues={agent} onSubmit={onSellerAgentSubmit} />
            </Grid>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  )

  return (
    <FormWrapper title="Licencee Details" notes={notes}>
      <Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Licencee Type</Typography>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Fragment>
    </FormWrapper>
  )
}

export default LicenceeDetails
