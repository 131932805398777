import "./App.css"
import { Provider } from "react-redux"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { store } from "./app/store"
import { Box, ThemeProvider, createTheme } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/en-au"
import WorkspacePage from "./features/workspace/workspace-page"
import Login from "./features/login"
import AuthCallback from "./features/auth-callback/auth-callback"
import LandingPage from "./features/landing/landing-page"
import CreateWorkspacePage from "./features/create-workspace/create-workspace-page"
import WorkspacesPage from "./features/workspaces/workspaces-page"
import ViewInvoice from "./features/view-invoice/view-invoice"
import AcceptInvitation from "./features/accept-invitation/accept-invitation"
import ViewDocument from "./features/view-document/view-document"
// import { loadStripe } from "@stripe/stripe-js"
import Questionnaire from "./features/questionnaire"
import OpenHouse from "./features/open-house/open-house"
import SettingsPage from "./features/settings/settings-page"
import OnboardingPage from "./features/customer-onboarding/on-boarding-page"
import OnBoardingComplete from "./features/customer-onboarding/on-boarding-complete"
import Dashboard from "./features/dashboard/dashboard"
import OfferPage from "./features/offer/offer-page"
import Test from "./test"

const router = createBrowserRouter(
  [
    { path: "/test", element: <Test /> },
    { path: "login", element: <Login /> },
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/questionnaire", element: <Questionnaire /> },

    {
      path: "/auth-callback",
      element: <AuthCallback />,
    },
    {
      path: "/silent-callback",
      element: <AuthCallback />,
    },
    // {
    //   path: "/subscriptions",
    //   element: <CreateSubscription stripePromise={stripePromise!} />,
    // },
    // {
    //   path: "/subscriptions/complete",
    //   element: <CreateSubscriptionSuccess />,
    // },
    {
      path: "/",
      element: <LandingPage />,
      id: "landingPage",
    },
    {
      path: "on-boarding",
      element: <OnboardingPage />,
      // children: [
      //   {
      //     path: "",
      //     element: <Navigate to={"payment-details"} />,
      //   },
      //   {
      //     path: "payment-details",
      //     id: "payment-details",
      //     element: <PaymentDetails />,
      //   },
      // ],
    },
    {
      path: "on-boarding-complete",
      element: <OnBoardingComplete />,
    },

    {
      path: "/workspaces",
      element: <WorkspacesPage />,

      id: "workspaces",
    },
    {
      path: "/create-workspace",
      element: <CreateWorkspacePage />,

      id: "create-workspaces",
    },
    {
      path: "open-house/:workspaceId",
      element: <OpenHouse />,
    },
    {
      path: "/workspaces/:workspaceId/invoice",
      element: <ViewInvoice />,
    },
    {
      path: "/workspaces/:workspaceId",
      element: <WorkspacePage />,
      id: "view-workspace",
    },
    {
      path: "/workspaces/:workspaceId/offers/:offerId",
      element: <OfferPage />,
      id: "view-offer",
    },
    {
      path: "/workspaces/:workspaceId/document/:documentName",
      element: <ViewDocument />,
      id: "view-document",
    },
    {
      path: "/accept-invitation/:invitation",
      element: <AcceptInvitation />,
      id: "accept-invitation",
    },
    {
      path: "/settings",
      element: <SettingsPage />,
      id: "settings",
    },
    // {
    //   path: "/manage-users",
    //   element: <ManageUsers />,
    //   id: "manage-users",
    // },
  ],
  {
    future: {
      v7_normalizeFormMethod: true,
    },
  },
)

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#8F1219",
      contrastText: "#fafafa",
    },
    secondary: {
      main: "#FCC222",
    },
    // success: {
    //  // main: "#FCC222",
    // },
    custom: {
      agentAppointment: "#FFECB9",
      contractPreparation: "#FFE08B",
      offerNegotiation: "#FCD466",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    text: {
      primary: "#333",
    },
  },
  typography: {
    h6: {
      [`@media (max-width:350px)`]: {
        fontSize: "1rem",
      },
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          //   padding: "1rem",
        },
      },
    },

    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableRipple: true,
        disableElevation: true,
        size: "small",
        color: "primary",
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          const colorStyles: Record<
            "primary" | "success" | "inherit" | "info",
            {
              backgroundColor: string
              color?: string
              border?: string
              "&:hover": {
                backgroundColor: string
                color: string
                border: string
              }
            }
          > = {
            primary: {
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.common.white,
                color: "#8F1219",
                border: "2px solid #8F1219",
              },
            },
            info: {
              backgroundColor: theme.palette.common.white,
              border: `2px solid ${theme.palette.common.black}`,
              color: theme.palette.common.black,
              "&:hover": {
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
                border: `2px solid ${theme.palette.common.black}`,
              },
            },
            success: {
              backgroundColor: theme.palette.success.main,
              "&:hover": {
                backgroundColor: "transparent",
                color: theme.palette.success.main,
                border: `2px solid ${theme.palette.success.main}`,
              },
            },
            inherit: {
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
              "&:hover": {
                backgroundColor: "transparent",
                color: theme.palette.common.black,
                border: `2px solid ${theme.palette.common.black}`,
              },
            },
          }

          const { color } = ownerState

          const resolvedStyles =
            color && color in colorStyles
              ? colorStyles[color as keyof typeof colorStyles]
              : {}

          return {
            width: "100%",
            borderRadius: "30px",
            paddingLeft: 0,
            paddingRight: 0,
            border: "2px solid transparent",
            ...resolvedStyles,
          }
        },
      },
    },

    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: "5px",
          overflow: "hidden",

          position: "relative",
          backgroundColor: theme.palette.background.paper,
        }),
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: "text.primary",
          padding: "1rem",
        },
        title: {
          fontSize: "1.25rem",
          [`@media (max-width:350px)`]: {
            fontSize: "1rem",
          },
          fontWeight: "bold",
          color: "#444",
        },
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          color: "#000",
          paddingBottom: "16px",
        },
      },
    },

    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
})

declare module "@mui/material/styles" {
  interface Palette {
    custom: {
      agentAppointment: string
      contractPreparation: string
      offerNegotiation: string
    }
  }
  interface PaletteOptions {
    custom?: {
      agentAppointment: string
      contractPreparation: string
      offerNegotiation: string
    }
  }
}

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Box>
            <RouterProvider router={router} />
          </Box>
        </Provider>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
