import { Box, Typography, Button } from "@mui/material"

import {
  startOfferWorkflow,
} from "../app/slices/offers-slice"
import { useAppDispatch } from "../app/hooks"

const StartOfferWorkflow = ({
  workspaceId,
  offerId,
}: {
  workspaceId: number
  offerId: number
}) => {
  const dispatch = useAppDispatch()
  return (
    <Box sx={{ border: "1px solid #ccc", borderRadius: 1, p: 1 }}>
      <Typography variant="h6" color="#666" fontWeight="bold">
        Offer not started
      </Typography>
      <Typography component="div" variant="body1" color="#666">
        This offer has not been started. Until it is started, you cannot:
        <ul>
          <li>share the offer with the seller or buyer</li>
          <li>send the offer for signing</li>
        </ul>
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          dispatch(startOfferWorkflow({ workspaceId, offerId }))
        }}
      >
        Start Offer
      </Button>
    </Box>
  )
}

export default StartOfferWorkflow
