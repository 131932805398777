import {
  Grid2,
  Typography,
  Stack,
  Paper,
  List,
  CircularProgress,
} from "@mui/material"
import { selectParticipants } from "../../../app/slices/participants-slice"
import { Fragment } from "react"
import { useAppSelector } from "../../../app/hooks"
import Timeline from "../../timeline/timeline"
import {
  DocumentsStatus,
  selectDocuments,
  selectDocumentsStatus,
} from "../../../app/slices/documents-slice"
import WorkflowAction from "./workflow-action"
import ParticipantListItem from "../../../components/participant-list-item"
import WorkspaceHeader from "./workspace-header"
import {
  selectWorkflow,
  selectWorkspace,
} from "../../../app/slices/workspaces-slice"
import DocumentListItem from "../../../components/document-list-item"

const WorkspacePageLarge = () => {
  const participants = useAppSelector(selectParticipants)
  // const participantsStatus = useAppSelector(selectParticipantsStatus)
  const documents = useAppSelector(selectDocuments)
  const documentsStatus = useAppSelector(selectDocumentsStatus)

  const workspace = useAppSelector(selectWorkspace)

  const workflow = useAppSelector(selectWorkflow)

  const roles = [
    "sellerAgent",
    "seller",
    "sellerSolicitor",
    "buyer",
    "buyerSolicitor",
  ]

  const filteredParticipants = participants.filter(participant => {
    return (
      roles.includes(participant.role!) &&
      participant.firstName != undefined &&
      participant.lastName != undefined
    )
  })

  return (
    <Grid2 container spacing={0.5}>
      <Grid2 size={12}>
        <WorkspaceHeader />
      </Grid2>
      <Grid2 size={12}>
        {workflow === undefined ? (
          <></>
        ) : (
          <Timeline workflow={workflow} workspace={workspace!} />
        )}
      </Grid2>
      <Grid2 size={8}>
        <WorkflowAction />
      </Grid2>
      <Grid2 size={4}>
        <Stack gap={1}>
          {filteredParticipants.length > 0 && (
            <Paper sx={{ padding: 1 }}>
              {/* {participantsStatus === ParticipantsStatus.loading && (
                <CircularProgress />
              )} */}
              <Typography component="span" variant="h6">
                Seller Side Participants
              </Typography>
              <Typography component="span" variant="body1" marginLeft={1}>
                ({filteredParticipants.length})
              </Typography>

              <List>
                {filteredParticipants.map(participant => (
                  <ParticipantListItem
                    key={participant.id}
                    participant={participant}
                  />
                ))}
              </List>
            </Paper>
          )}

          {documents.length > 0 && (
            <Paper sx={{ p: 1 }}>
              <Typography variant="h6">Documents</Typography>
              {documentsStatus === DocumentsStatus.loading && (
                <CircularProgress />
              )}
              {documentsStatus === DocumentsStatus.success && (
                <Fragment>
                  <List>
                    {documents.map((document, key) => (
                      <DocumentListItem
                        key={key}
                        document={document}
                        workspaceId={workspace?.workspace?.id!}
                      />
                    ))}
                  </List>
                </Fragment>
              )}
            </Paper>
          )}
        </Stack>
      </Grid2>
    </Grid2>
  )
}

export default WorkspacePageLarge
