import {
  IconButton,
  ListItem,
  ListItemText,
  MenuItem,
  Menu,
  ListItemIcon,
} from "@mui/material"
import { AnnexureDocument } from "../landconnex-api-client"
import { Fragment } from "react/jsx-runtime"
import { Download as DownloadIcon, OpenInNew } from "@mui/icons-material"
import { MoreVert as MoreVertIcon } from "@mui/icons-material"
import { useState } from "react"
export interface DocumentListItemProps {
  document: AnnexureDocument
  workspaceId: number
}
const DocumentListItem = ({ document :annexure,  workspaceId }: DocumentListItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleDownload = () => {
    fetch(`/v3/workspaces/${workspaceId}/document/${annexure.name}`)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = annexure.name!;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      });
    handleClose()
  }
  const handleOpen = () => {
    window.open(
      `/workspaces/${workspaceId}/document/${annexure.name}`,
      "_blank",
    )
    handleClose()
  }
  return (
    <ListItem
      secondaryAction={
        <Fragment>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleDownload}>
              <ListItemIcon>
                <DownloadIcon />
              </ListItemIcon>
              <ListItemText>Download</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleOpen}>
              <ListItemIcon>
                <OpenInNew />
              </ListItemIcon>
              <ListItemText>Open</ListItemText>
            </MenuItem>
          </Menu>
         
        </Fragment>
      } 
    >
      <ListItemText>{annexure.name}</ListItemText>
    </ListItem>
  )
}

export default DocumentListItem
