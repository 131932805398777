import { Box, Stack, SxProps, Typography } from "@mui/material"
import { FC } from "react"

interface FactProps {
  title: string
  value: string
  icon?: any
  sx?: SxProps
}

const Fact: FC<FactProps> = ({ title, value, icon, sx }) => {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{
        backgroundColor: "#d3e8eb",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: icon && "100%",
          justifyContent: icon && "space-between",
        }}
      >
        <Box sx={{ textAlign: icon ? " left" : "center", ml: icon && 5 }}>
          <Typography
            variant="h4"
            sx={{color: "#555", fontSize: { lg: 34, sm: 27, xs: 24 } }}
          >
            {value}
          </Typography>
          <Typography variant="caption" sx={{color: "#555"}}>{title}</Typography>
        </Box>
        {icon && (
          <Box
            sx={{
              color: "#555",
              display: "flex",
              justifyContent: "center",
              mr: { lg: 2, sm: 3, xs: 1 },
              opacity: "0.6",
            }}
          >
            {icon}
          </Box>
        )}
      </Box>
    </Stack>
  )
}

export default Fact
