import React, { Fragment } from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form"
import { Contract } from "../../../landconnex-api-client/api"
import { useTheme } from "@mui/material"
import FormInputText from "../../../components/form-input-text"
import FormInputDatePicker from "../../../components/form-input-date-picker"
import FormWrapper from "../../agent-appointment/components/form-wrapper"
import FormInputNumeric from "../../../components/form-input-numeric"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import AutoSave from "../../../components/auto-save"
import { useParams } from "react-router-dom"
import { getParams } from "../helpers"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { putContract, selectContract } from "../../../app/slices/contract-slice"
import { produce } from "immer"
const Encumbrances: React.FC = () => {
  const dispatch = useAppDispatch()
  const workspace = useAppSelector(selectWorkspace)
  const contract = useAppSelector(selectContract)

  const workspaceParams = getParams(useParams())
  const disableField = ["buyer", "buyerSolicitor"].includes(
    workspace!.workspace?.role!,
  )

  const theme = useTheme()
  const formMethods = useForm<any>({
    defaultValues: contract,
  })

  const { control, handleSubmit } = formMethods

  const onSubmit: SubmitHandler<Contract> = data => {
    dispatch(
      putContract({
        workspaceId: workspaceParams.workspaceId,
        contract: data,
      }),
    )
  }

  // function onChange(event: ChangeEvent<HTMLInputElement>): void {
  //   const newContract = { ...contract };
  //   switch (event.currentTarget.name) {
  //     case "hasEncumbrances":
  //       newContract.hasEncumbrances = event.currentTarget.value == "true";
  //       break;
  //     case "hasTenant":
  //       newContract.hasTenant = event.currentTarget.value == "true";
  //       break;

  //     default:
  //       break;
  //   }
  //   dispatch(saveContract(newContract));
  // }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper title="Encumbrances">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Title Encumbrances
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Typography variant="body1">
                Is the Property sold subject to any Encumbrances?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Controller
                name="hasEncumbrances"
                control={control}
                render={() => (
                  <RadioGroup
                    defaultValue={contract?.hasEncumbrances ? "true" : "false"}
                    onChange={({ target }) => {
                      onSubmit(
                        produce(contract!, draft => {
                          draft.hasEncumbrances = target.value == "true"
                        }),
                      )
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                      disabled={disableField}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                      disabled={disableField}
                    />
                  </RadioGroup>
                )}
              ></Controller>
            </Grid>
            {contract?.hasEncumbrances ? (
              <Grid item xs={12} sm={12}>
                <FormInputText
                  name="encumbrances"
                  label="Encumbrances"
                  control={control}
                  required
                  multiline
                  minRows={6}
                  disabled={disableField}
                />
              </Grid>
            ) : null}

            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Tenancies
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Typography variant="body1">Is the Property tenanted?</Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Controller
                control={control}
                name="hasTenant"
                render={() => (
                  <RadioGroup
                    defaultValue={contract?.hasTenant ? "true" : "false"}
                    onChange={({ target }) => {
                      onSubmit(
                        produce(contract!, draft => {
                          draft.hasTenant = target.value == "true"
                        }),
                      )
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                      disabled={disableField}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                      disabled={disableField}
                    />
                  </RadioGroup>
                )}
              />
            </Grid>
            {contract?.hasTenant ? (
              <Fragment>
                <Grid item xs={12} sm={12}>
                  <FormInputText
                    control={control}
                    name="tenancyTenantName"
                    label="Tenants Name"
                    required={contract?.hasTenant}
                    disabled={disableField}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormInputText
                    control={control}
                    name="tenancyTermAndOptions"
                    label="Terms & Options"
                    multiline
                    minRows={4}
                    required={contract?.hasTenant}
                    disabled={disableField}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormInputDatePicker
                    control={control}
                    name="tenancyTermStart"
                    label="Tenancy Term Start"
                    required={contract?.hasTenant}
                    disabled={disableField}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormInputDatePicker
                    control={control}
                    name="tenancyTermEnd"
                    label="Tenancy Term End"
                    required={contract?.hasTenant}
                    disabled={disableField}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormInputNumeric
                    control={control}
                    name="tenancyRent"
                    label="Rent"
                    required={contract?.hasTenant}
                    disabled={disableField}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormInputNumeric
                    control={control}
                    name="tenancyBond"
                    label="Bond"
                    required={contract?.hasTenant}
                    disabled={disableField}
                  />
                </Grid>
              </Fragment>
            ) : null}
          </Grid>
        </FormWrapper>
        <AutoSave onSubmit={onSubmit} defaultValues={contract}></AutoSave>
      </form>
    </FormProvider>
  )
}

export default Encumbrances
