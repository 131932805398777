import { Box, Typography, Grid2 } from "@mui/material"
import { singleLineFullAddress } from "../../../helpers/helpers"
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import SubtitlesOutlinedIcon from "@mui/icons-material/SubtitlesOutlined"
import PollOutlinedIcon from "@mui/icons-material/PollOutlined"
import WorkspaceCard, {
  WorkspaceCardContent,
} from "../../../components/workspace-card"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { useAppSelector } from "../../../app/hooks"
import { BrandingCompanyTypeEnum } from "../../../landconnex-api-client"

const WorkspaceHeader = () => {
  const workspaceSummary = useAppSelector(selectWorkspace)

  if (!workspaceSummary) {
    return <></>
  }

  let agentBranding = workspaceSummary?.branding.find(
    b => b.companyType === BrandingCompanyTypeEnum.RealEstateAgency,
  )
  if (!agentBranding) {
    agentBranding = {
      logo: "34",
      primaryColor: "#FCD600",
      secondaryColor: "#FCD600",
      companyType: BrandingCompanyTypeEnum.RealEstateAgency,
    }
  }
  return (
    <WorkspaceCard>
      <WorkspaceCardContent>
        <Grid2 container>
          <Grid2 size={6}>
            <img
              style={{ border: "1 solid black" }}
              src={`https://landconnex-prod-public.ap-south-1.linodeobjects.com/logos/${agentBranding?.logo}.png`}
              height="96px"
              alt=""
            />
          </Grid2>
          <Grid2 size={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <Typography variant="h5">{`${workspaceSummary?.workspace?.id} - ${singleLineFullAddress(
                workspaceSummary?.property!,
              )}`}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 3,
                  color: "#3e3e3e",
                  alignItems: "center",
                }}
              >
                {[
                  {
                    label: "Lot",
                    value: workspaceSummary?.property?.lot || "N/A",
                    icon: <HomeOutlinedIcon />,
                  },
                  {
                    label: "Title Reference",
                    value: workspaceSummary?.property?.titleReference || "N/A",
                    icon: <SubtitlesOutlinedIcon />,
                  },
                  {
                    label: "Plan",
                    value: workspaceSummary?.property?.plan || "N/A",
                    icon: <PollOutlinedIcon />,
                  },
                ].map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item.icon}
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        fontWeight: 500,
                        marginRight: 1,
                        marginLeft: 0.5,
                      }}
                    >
                      {item.label}:
                    </Typography>
                    <Typography variant="body2" component="span">
                      {item.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid2>
        </Grid2>
      </WorkspaceCardContent>
    </WorkspaceCard>
  )
}

export default WorkspaceHeader
