
import * as React from "react"
import { styled, CSSObject, Theme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import MuiDrawer from "@mui/material/Drawer"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined"
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "../app/hooks"
import { selectIsGroupAdministrator, selectIsWorkspaceAdministrator } from "../app/slices/session-slice"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"


interface DrawerProps {
  open: boolean
  toggleDrawer: () => void
}

interface Route {
  name: string
  path: string
  icon: React.ReactNode
}

const drawerWidth = 200

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
})

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}))

const CustomDrawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}))

const KonveiMiniDrawer: React.FC<DrawerProps> = ({ open, toggleDrawer }) => {
  const navigate = useNavigate()
  const isWorkspaceAdministrator = useAppSelector(
    selectIsWorkspaceAdministrator,
  )
  const isGroupAdministrator = useAppSelector(selectIsGroupAdministrator)

  const navItems: Route[] = []

  if (isWorkspaceAdministrator || isGroupAdministrator) {
    navItems.push({
      name: "Dashboard",
      path: "/dashboard",
      icon: <DashboardOutlinedIcon />,
    })
  }

  navItems.push({
    name: "Workspaces",
    path: "/workspaces",
    icon: <WorkspacesOutlinedIcon />,
  })
  navItems.push({
    name: "New",
    path: "/create-workspace",
    icon: <AddCircleOutlineOutlinedIcon />,
  })


  return (
    <CustomDrawer
      variant="permanent"
      open={open}
      sx={{
        "& .MuiDrawer-paper": {
          border: "none",
          background: "#fff",
        },
      }}
    >
      <Box>
        <DrawerHeader>
          <Box
            onClick={() => toggleDrawer()}
            component="div"
            sx={{ flexGrow: 1, display: open ? "block" : "none" }}
          >
            <Typography
              className="logo roboto-bold"
              variant="h5"
              sx={{
                color: "#8F1219",
                fontFamily: "Roboto",
                fontWeight: "700",
                fontStyle: "normal",
              }}
            >
              LandConnex
            </Typography>
          </Box>
          <IconButton onClick={() => toggleDrawer()}>
            <ChevronLeftIcon fontSize="large" />
          </IconButton>
        </DrawerHeader>

        <List>
          {navItems.map((item, _index) => (
            <ListItemButton
              key={item.name}
              onClick={() => {
                toggleDrawer()
                navigate(item.path)
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          ))}

        </List>
      </Box>
    </CustomDrawer>
  )
}

export default KonveiMiniDrawer
