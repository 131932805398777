import React, { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material"
import { questionSteps } from "./questions"
import Page from "../../components/page"
import LCCard from "../../components/cards/lc-card"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"

enum QuestionType {
  TEXT = "text",
  YES_NO = "yesno",
  YES_NO_NA = "yesno-na",
  FILE = "file",
}

interface Question {
  id: string
  question: string
  type: QuestionType
  label?: string
  notes?: string | string[]
  parent?: string
  condition?: string
}

interface Step {
  title: string
  questions: Question[]
}

const Questionnaire: React.FC = () => {
  const [step, setStep] = useState(0)
  const { control, handleSubmit, watch, setValue } = useForm<
    Record<string, any>
  >({
    defaultValues: {},
  })

  const currentStep = questionSteps[step] as Step
  const answers = watch()

  const renderYesNoQuestion = (q: Question) => (
    <Box
      key={q.id}
      sx={{
        marginLeft: q.parent ? 1.5 : 0,
        marginY: q.parent ? 1 : 1.5,
      }}
    >
      <Typography>{q.question}</Typography>
      {q.label && (
        <Typography variant="caption" color="textSecondary" display="block">
          {q.label}
        </Typography>
      )}
      <Box display="flex" alignItems="center">
        <Controller
          name={q.id}
          control={control}
          render={({ field }) => (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value === "yes"}
                    onChange={() =>
                      field.onChange(field.value === "yes" ? "" : "yes")
                    }
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value === "no"}
                    onChange={() =>
                      field.onChange(field.value === "no" ? "" : "no")
                    }
                  />
                }
                label="No"
              />
            </>
          )}
        />
      </Box>
      {q.notes && (
        <Collapse in={Boolean(q.notes)} timeout={300}>
          {Array.isArray(q.notes)
            ? q.notes.map((note, index) => (
                <Typography
                  key={index}
                  style={{ fontSize: "0.9em", color: "gray" }}
                >
                  • {note}
                </Typography>
              ))
            : null}
        </Collapse>
      )}
    </Box>
  )

  const renderYesNoNAQuestion = (q: Question) => (
    <Box
      key={q.id}
      sx={{
        marginLeft: q.parent ? 1.5 : 0,
        marginY: q.parent ? 1 : 1.5,
      }}
    >
      <Typography>{q.question}</Typography>
      {q.label && (
        <Typography variant="caption" color="textSecondary" display="block">
          {q.label}
        </Typography>
      )}
      <Box display="flex" alignItems="center">
        <Controller
          name={q.id}
          control={control}
          render={({ field }) => (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value === "yes"}
                    onChange={() =>
                      field.onChange(field.value === "yes" ? "" : "yes")
                    }
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value === "no"}
                    onChange={() =>
                      field.onChange(field.value === "no" ? "" : "no")
                    }
                  />
                }
                label="No"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value === "na"}
                    onChange={() =>
                      field.onChange(field.value === "na" ? "" : "na")
                    }
                  />
                }
                label="N/A"
              />
            </>
          )}
        />
      </Box>
    </Box>
  )

  const renderTextQuestion = (q: Question) => (
    <Box
      key={q.id}
      sx={{
        marginLeft: q.parent ? 1.5 : 0,
        marginY: q.parent ? 1 : 1.5,
      }}
    >
      <Typography variant="body1" gutterBottom>
        {q.question}
      </Typography>
      {q.label && (
        <Typography variant="caption" color="textSecondary" display="block">
          {q.label}
        </Typography>
      )}
      <Controller
        name={q.id}
        control={control}
        rules={{ required: "This field is required" }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            fullWidth
            variant="standard"
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
          />
        )}
      />
    </Box>
  )

  const renderFileUpload = (q: Question) => (
    <Box
      key={`${q.id}-file`}
      sx={{
        marginLeft: q.parent ? 1.5 : 0,
        marginY: q.parent ? 1 : 1.5,
      }}
    >
      <Typography>{q.question}</Typography>
      {q.label && (
        <Typography variant="caption" color="textSecondary" display="block">
          {q.label}
        </Typography>
      )}
      <Controller
        name={q.id}
        control={control}
        render={({ field }) => (
          <Box>
            {field.value ? (
              <Box display="flex" alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  {field.value.name}
                </Typography>
                <Box sx={{ width: 80, zIndex: 1, marginLeft: 2 }}>
                  <Button
                    onClick={() => setValue(q.id, null)}
                  >Remove</Button>
                </Box>
              </Box>
            ) : (
              <Box sx={{ width: 100, zIndex: 1 }}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    backgroundColor: "green",
                    color: "#fff",
                    border: `3px solid green`,
                    width: "100%",
                    height: "30px",
                    fontSize: "11px",
                    borderRadius: "15px",
                    padding: 0,
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "green",
                    },
                  }}
                >
                  Upload File
                  <input
                    type="file"
                    hidden
                    onChange={e => {
                      const file = e.target.files ? e.target.files[0] : null
                      setValue(q.id, file)
                    }}
                  />
                </Button>
              </Box>
            )}
          </Box>
        )}
      />
    </Box>
  )

  const renderQuestions = (questions: Question[]) => {
    return questions.map(q => {
      const isVisible = !q.parent || answers[q.parent] === q.condition

      return (
        <Collapse
          key={q.id}
          in={isVisible}
          timeout={300}
          //   sx={{
          //     marginLeft: q.parent ? 1.5 : 0,
          //     marginY: q.parent ? 1 : 0,
          //   }}
        >
          {(() => {
            if (q.type === QuestionType.TEXT) {
              return renderTextQuestion(q)
            }

            if (q.type === QuestionType.YES_NO) {
              return renderYesNoQuestion(q)
            }

            if (q.type === QuestionType.YES_NO_NA) {
              return renderYesNoNAQuestion(q)
            }

            if (q.type === QuestionType.FILE) {
              return renderFileUpload(q)
            }

            return null
          })()}
        </Collapse>
      )
    })
  }

  const handleNext = () => {
    if (step < questionSteps.length - 1) {
      setStep(prevStep => prevStep + 1)
    }
  }

  const handleBack = () => {
    if (step > 0) {
      setStep(prevStep => prevStep - 1)
    }
  }

  const onSubmit = (data: Record<string, any>) => {
    console.log("Submitted Answers:", data)
    alert("Thank you for submitting your answers!")
  }

  return (
    <Page>
      <LCCard
        title="Please answer the following questions"
        txtColor="#000"
        titleColor="#000"
        bgColor="#fff"
      >
        <Box maxWidth={750} margin="0 auto">
          <LCCard
            title={currentStep.title}
            titleColor="#000"
            txtColor="#000"
            bgColor="#fff"
            sxCard={{
              width: "100%",
              minHeight: 300,
              height: "auto",
              outline: "2px solid rgba(208, 226, 255, .5)",
            }}
            sxCardContent={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {renderQuestions(currentStep.questions)}

            <Box
              display="flex"
              justifyContent={
                step > 0 && step < questionSteps.length
                  ? "space-between"
                  : "flex-end"
              }
              mt={3}
              sx={{
                display: "flex",
                justifyContent:
                  step > 0 && step < questionSteps.length
                    ? "space-between"
                    : "flex-end",
                marginTop: 3,
              }}
            >
              {step > 0 && (
                <Box sx={{ width: 100, zIndex: 1 }}>
                  <Button
                    onClick={handleBack}
                    startIcon={<KeyboardArrowLeft />}
                  >Back</Button>
                </Box>
              )}
              {step < questionSteps.length - 1 ? (
                <Box sx={{ width: 100, zIndex: 1 }}>
                  <Button
                    onClick={handleNext}
                    endIcon={<KeyboardArrowRight />}
                  >Next</Button>
                </Box>
              ) : (
                <Box sx={{ width: 120, zIndex: 1 }}>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    endIcon={<KeyboardArrowRight />}
                  >Submit</Button>
                </Box>
              )}
            </Box>
          </LCCard>
        </Box>
      </LCCard>
    </Page>
  )
}

export default Questionnaire
