import { propertyApi } from "../../api"
import { Property } from "../../landconnex-api-client"
import { createAppSlice } from "../createAppSlice"

export interface SetPropertyProps {
  workspaceId: number
  property: Property
}

export interface PropertyState {
  property: Property | null
}

const initialState: PropertyState = {
  property: null,
}

export const propertySlice = createAppSlice({
  name: "property",
  initialState,
  reducers: create => ({
    putProperty: create.asyncThunk<void, SetPropertyProps>(
      async ({ workspaceId, property }) => {
        await propertyApi.putProperty(workspaceId, property)
      },
    ),
    getProperty: create.asyncThunk<Property, number>(
      async workspaceId => {
        const property = await propertyApi.getProperty(workspaceId)
        return property.data
      },
      {
        fulfilled: (state, action) => {
          state.property = action.payload
        },
      },
    ),
  }),
  selectors: {
    selectProperty: property => property.property,
  },
})

export const { putProperty, getProperty } = propertySlice.actions
export const { selectProperty } = propertySlice.selectors
