import {
  CardHeader,
  Card,
  FormControlLabel,
  Radio,
  RadioGroup,
  CardContent,
  Stack,
  TextField,
} from "@mui/material"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { FC } from "react"
import {
  AgentAppointment,
  AgentAppointmentCommissionPaymentTermEnum,
  AgentAppointmentCommissionTypeEnum,
} from "../../../landconnex-api-client"
// import * as yup from "yup";
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
} from "react-hook-form"
import FormInputText from "../../../components/form-input-text"
import { Notes } from "./form-wrapper"
import { useParams } from "react-router-dom"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import {
  selectAgentAppointment,
  updateAgentAppointment,
} from "../../../app/slices/agent-appointment-slice"
import AutoSave from "../../../components/auto-save"
import { NumericFormat } from "react-number-format"

const Commission: FC = () => {
  const agentAppointment = useAppSelector(selectAgentAppointment)
  const params = useParams()
  const dispatch = useAppDispatch()
  const workspaceId = Number(params.workspaceId)

  // const schema = yup.object<AgentAppointment>({
  //   commissionPaymentTermInstructions: yup
  //     .string()
  //     .when(["commissionPaymentTerm"], {
  //       is: (val: string) =>
  //         val == AgentAppointmentCommissionPaymentTermEnum.Other,
  //       then: () => yup.string().required(),
  //       otherwise: () => yup.string().notRequired(),
  //     }),
  // });

  const agentAppointmentFormMethods = useForm<AgentAppointment>({
    defaultValues: agentAppointment,
    //resolver: yupResolver(schema),
  })

  const { handleSubmit, control } = agentAppointmentFormMethods

  // useEffect(() => {
  //   reset(agentAppointment);
  // }, [agentAppointment]);

  const onSubmit: SubmitHandler<AgentAppointment> = data => {
    dispatch(
      updateAgentAppointment({
        workspaceId: workspaceId,
        agentAppointment: data,
      }),
    )
  }

  const notes = [
    {
      title: "To the client:",
      items: [
        "The commission is negotiable. It must be written as a percentage or dollar amount.",
        "Make sure you understand when commission is payable. If you choose ‘Other’ and the contract does not settle, the agent may still seek commission.",
      ],
    },
    {
      title: "To the agent:",
      items: [
        "You should ensure that commission is clearly expressed and the client fully understands the likely amount and when it is payable.",
        "Refer to section 104 and 105 of the Property Occupations Act 2014",
      ],
    },
  ]

  return (
    <FormProvider {...agentAppointmentFormMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ border: "none" }}>
          <CardHeader title="Commission" />
          <CardContent>
            <Notes notes={notes}></Notes>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  The client and the agent agree that the commission including
                  GST payable for the service to be performed by the agent is:
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {agentAppointment?.commissionType ===
                AgentAppointmentCommissionTypeEnum.Fixed ? (
                  <Controller
                    control={control}
                    name="commissionAmount"
                    render={({ field }) => (
                      <NumericFormat
                        {...field}
                        value={field.value}
                        customInput={TextField}
                        prefix="$ "
                        variant="filled"
                        fixedDecimalScale={true}
                        allowNegative={false}
                        label="Commission Amount"
                        fullWidth
                        decimalScale={2}
                        size="small"
                        thousandSeparator
                      />
                    )}
                  />
                ) : (
                  <Controller
                    control={control}
                    name="commissionAmount"
                    render={({ field }) => (
                      <NumericFormat
                        {...field}
                        customInput={TextField}
                        value={field.value}
                        variant="filled"
                        decimalScale={2}
                        suffix="%"
                        size="small"
                        fixedDecimalScale={true}
                        allowNegative={false}
                        label="Commission Percentage"
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={9}>
                <Controller
                  control={control}
                  name="commissionType"
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <Stack direction="row" spacing={1}>
                        <FormControlLabel
                          value={AgentAppointmentCommissionTypeEnum.Fixed}
                          control={<Radio />}
                          label="Fixed"
                        />
                        <FormControlLabel
                          value={AgentAppointmentCommissionTypeEnum.Percentage}
                          control={<Radio />}
                          label="Percentage"
                        />
                      </Stack>
                    </RadioGroup>
                  )}
                ></Controller>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  className="roboto-bold"
                  sx={{ color: "#c20029", fontSize: "18px" }}
                >
                  When commission is payable
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="commissionPaymentTerm"
                  render={({ field }) => (
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      {...field}
                    >
                      <FormControlLabel
                        value={
                          AgentAppointmentCommissionPaymentTermEnum.Settlement
                        }
                        control={<Radio />}
                        label="For sales, including actions, commission is payable if a contract is entered into and settlement of the contract occurs."
                      />
                      <div
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        <FormControlLabel
                          value={
                            AgentAppointmentCommissionPaymentTermEnum.Other
                          }
                          control={<Radio />}
                          label="Other:"
                        />
                        <FormInputText
                          control={control}
                          name="commissionPaymentTermInstructions"
                          label="Other Commission Terms"
                          minRows={5}
                          multiline
                        />
                      </div>
                    </RadioGroup>
                  )}
                ></Controller>
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  control={control}
                  label="for all other types of appointments"
                  name="commissionPaymentTermOtherAppointments"
                  minRows={5}
                  multiline
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <AutoSave
          onSubmit={onSubmit}
          defaultValues={agentAppointment}
        ></AutoSave>
      </form>
    </FormProvider>
  )
}

export default Commission
