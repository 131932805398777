import { createAppSlice } from "../createAppSlice"
import { addressSearchApi } from "../../api"
import {
  FlattenedAddress,
  PhysicalAustralianAddress,
} from "../../landconnex-api-client"

export enum AddressSearchStatus {
  initial = "initial",
  loading = "loading",
  ready = "ready",
  error = "error",
}

export interface AddressSearchState {
  addressSearchResults: FlattenedAddress[]
  searchAddressStatus: AddressSearchStatus
  getAddressDetailsStatus: AddressSearchStatus
  addressDetails: PhysicalAustralianAddress | null
}

const initialState: AddressSearchState = {
  addressSearchResults: [],
  searchAddressStatus: AddressSearchStatus.initial,
  getAddressDetailsStatus: AddressSearchStatus.initial,
  addressDetails: null,
}

export const addressSearchSlice = createAppSlice({
  name: "addressSearch",
  initialState,
  reducers: create => ({
    searchAddress: create.asyncThunk<FlattenedAddress[], string>(
      async address => {
        const response = await addressSearchApi.searchAddress(address)
        return response.data.items!
      },
      {
        pending: state => {
          state.searchAddressStatus = AddressSearchStatus.loading
        },
        fulfilled: (state, action) => {
          state.addressSearchResults = action.payload
          state.searchAddressStatus = AddressSearchStatus.ready
        },
        rejected: state => {
          state.searchAddressStatus = AddressSearchStatus.error
        },
      },
    ),
    getAddressDetails: create.asyncThunk<PhysicalAustralianAddress, string>(
      async address => {
        const response = await addressSearchApi.getAddress(address)
        return response.data
      },
      {
        pending: state => {
          state.getAddressDetailsStatus = AddressSearchStatus.loading
        },
        fulfilled: (state, action) => {
          state.addressDetails = action.payload
          state.getAddressDetailsStatus = AddressSearchStatus.ready
        },
        rejected: state => {
          state.getAddressDetailsStatus = AddressSearchStatus.error
        },
      },
    ),
  }),
  selectors: {
    selectAddressSearchResults: addressSearchState =>
      addressSearchState.addressSearchResults,
    selectAddressDetails: addressSearchState =>
      addressSearchState.addressDetails,
    selectSearchAddressStatus: addressSearchState =>
      addressSearchState.searchAddressStatus,
    selectGetAddressDetailsStatus: addressSearchState =>
      addressSearchState.getAddressDetailsStatus,
  },
})

export const { searchAddress, getAddressDetails } = addressSearchSlice.actions
export const {
  selectAddressSearchResults,
  selectAddressDetails,
  selectSearchAddressStatus,
  selectGetAddressDetailsStatus,
} = addressSearchSlice.selectors
