import React from "react"
import {
  Autocomplete,
  Grid,
  Typography,
  TextField,
  useTheme,
} from "@mui/material"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { Contract } from "../../../landconnex-api-client"
import AutoSave from "../../../components/auto-save"
import FormInputNumeric from "../../../components/form-input-numeric"
import FormWrapper from "../../agent-appointment/components/form-wrapper"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { putOffer, selectOffer } from "../../../app/slices/offers-slice"
const Price: React.FC = () => {
  const dispatch = useAppDispatch()
  const theme = useTheme()

  // const contract = useAppSelector(selectContract)
  const workspace = useAppSelector(selectWorkspace)
  const offer = useAppSelector(selectOffer)

  const depositInitialDueOptions = [
    "1 day after contract signing",
    "3 days after contract signing",
  ]

  const depositBalanceDueOptions = [
    "1 business day from satisfaction of finance condition",
    "1 business day from satisfaction of building and pest condition",
    "1 business day from unconditional date",
  ]

  const contractFormMethods = useForm<Contract>({
    defaultValues: offer,
  })

  const { handleSubmit, control } = contractFormMethods

  const handleOnSubmit = (data: Contract) => {
    dispatch(
      putOffer({
        workspaceId: workspace!.workspace!.id,
        offer: data,
      }),
    )
  }

  return (
    <FormProvider {...contractFormMethods}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <FormWrapper title="Price">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Price
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormInputNumeric
                name="purchasePrice"
                label="Price"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Deposits
              </Typography>
            </Grid>

            {/* Initial Deposit Field */}
            <Grid item xs={12} sm={6}>
              <FormInputNumeric
                name="depositInitial"
                label="Initial Deposit"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="depositInitialDue"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    freeSolo
                    options={depositInitialDueOptions}
                    onChange={(_, value) => field.onChange(value)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        size="small"
                        variant="filled"
                        label="Initial deposit due"
                      />
                    )}
                  />
                )}
              />
            </Grid>

            {/* Balance Deposit Field */}
            <Grid item xs={12} sm={6}>
              <FormInputNumeric
                name="depositBalance"
                label="Balance Deposit"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="depositBalanceDue"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    freeSolo
                    options={depositBalanceDueOptions}
                    onChange={(_, value) => field.onChange(value)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        size="small"
                        variant="filled"
                        label="Balance deposit due"
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
          <AutoSave onSubmit={handleOnSubmit} defaultValues={offer} />
        </FormWrapper>
      </form>
    </FormProvider>
  )
}

export default Price
