import Grid from "@mui/material/Grid"
import React from "react"

import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { Contract } from "../../../landconnex-api-client"
import { Controller, FormProvider, useForm } from "react-hook-form"
import Select from "@mui/material/Select"
import { Box, MenuItem, Typography } from "@mui/material"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { putContract, selectContract } from "../../../app/slices/contract-slice"

const financeDateOptions = [
  "7 days from Contract Date",
  "14 days from Contract Date",
  "21 days from Contract Date",
  "30 days from Contract Date",
]
const financierOptions = ["buyer's choice"]
const financeAmountOptions = ["sufficient to complete"]

const Finance: React.FC = () => {
  const dispatch = useAppDispatch()

  const workspace = useAppSelector(selectWorkspace)
  const contract = useAppSelector(selectContract)

  const handleOnSubmit = (data: Contract) => {
    dispatch(
      putContract({
        workspaceId: workspace!.workspace!.id,
        contract: data,
      }),
    )
  }

  const formMethods = useForm<Contract>({
    defaultValues: contract!,
  })
  const { handleSubmit, control } = formMethods
  return (
    <React.Fragment>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Box
            sx={{
              maxWidth: "1024px",
              margin: "15px auto",
            }}
          >
            <Box
              sx={{
                background: "#8F1219",
                color: "#fff",
                padding: "10px 15px",
                marginBottom: "30px",
              }}
            >
              <Typography variant="h5">Finance</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={3} sm={3}>
                <Controller
                  control={control}
                  name="financeAmount"
                  render={({ field }) => (
                    <Select
                      label="Finance Amount"
                      fullWidth
                      variant="filled"
                      {...field}
                    >
                      {financeAmountOptions.map(f => (
                        <MenuItem value={f}>{f}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
              <Grid item xs={9} sm={9}>
                <Controller
                  control={control}
                  name="financier"
                  render={({ field }) => (
                    <Select
                      label="Financier"
                      fullWidth
                      variant="filled"
                      {...field}
                    >
                      {financierOptions.map(f => (
                        <MenuItem value={f}>{f}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <Controller
                  control={control}
                  name="financeDate"
                  render={({ field }) => (
                    <Select
                      label="Finance Due"
                      fullWidth
                      variant="filled"
                      {...field}
                    >
                      {financeDateOptions.map(f => (
                        <MenuItem value={f}>{f}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </React.Fragment>
  )
}
export default Finance
