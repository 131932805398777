import { FC, Fragment, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import PDFViewer from "../../components/pdf-viewer"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  ViewDocumentStatus,
  selectViewDocumentState,
} from "./view-document-slice"
import { getDocument } from "./thunks"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import CircularProgress from "@mui/material/CircularProgress"
import Paper from "@mui/material/Paper"
import { withAuthenticationRequired } from "react-oidc-context"
import RedirectToLogin from "../../components/redirect-to-login"
import { Typography } from "@mui/material"
import { KeyboardArrowLeft } from "@mui/icons-material"

const ViewDocument: FC = () => {
  const dispatch = useAppDispatch()
  const state = useAppSelector(selectViewDocumentState)
  const navigate = useNavigate()

  let { workspaceId, documentName } = useParams()
  useEffect(() => {
    dispatch(
      getDocument({
        workspaceId: parseInt(workspaceId!),
        documentName: documentName!,
      }),
    )
  }, [workspaceId, documentName])

  return (
    // <Page title="" showBackNavigation={true}>
    <Fragment>
      <Typography
        onClick={() => {
          navigate(`/workspaces/${workspaceId}`)
          window.location.reload()
        }}
        sx={{ display: "flex", cursor: "pointer" }}
      >
        <KeyboardArrowLeft /> Back to Workspace
      </Typography>
      <Container>
        {state.status == ViewDocumentStatus.loading ? (
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100}></CircularProgress>
          </Grid>
        ) : null}
        {state.status == ViewDocumentStatus.ready ? (
          <Grid
            container
            spacing={2}
            padding={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid id="pdf" item padding={2}>
              <Paper square>
                <Grid item xs={12} className="generate-container">
                  <PDFViewer url={state.url} />
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        ) : null}
      </Container>
    </Fragment>
    // </Page>
  )
}

export default withAuthenticationRequired(ViewDocument, {
  OnRedirecting: () => <RedirectToLogin />,
})
