import { FC, SetStateAction, useEffect, useState } from "react"
import Page from "../../components/page"
import { withAuthenticationRequired } from "react-oidc-context"
import RedirectToLogin from "../../components/redirect-to-login"
import {
  BottomNavigationAction,
  BottomNavigation,
  Box,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
  Container,
} from "@mui/material"
import { selectIsWorkspaceAdministrator } from "../../app/slices/session-slice"
import MyDetails from "./components/my-details"
import PersonIcon from "@mui/icons-material/Person"
import GroupIcon from "@mui/icons-material/Group"
import TeamPage from "./components/team-page"
import { useAppSelector } from "../../app/hooks"
import { Business, CreditCard } from "@mui/icons-material"
import ManageUsers from "../manage-users"
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined"
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined"
import ContactEmergencyOutlinedIcon from "@mui/icons-material/ContactEmergencyOutlined"

const SettingsPage: FC = () => {
  const [selectedTab, setSelectedTab] = useState("me")
  const isWorkspaceAdministrator = useAppSelector(
    selectIsWorkspaceAdministrator,
  )
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"))

  useEffect(() => {
    if (!selectedTab) {
      setSelectedTab("me")
    }
  }, [selectedTab])

  const handleChange = (_event: any, newValue: SetStateAction<string>) => {
    setSelectedTab(newValue)
  }

  const renderContent = () => {
    switch (selectedTab) {
      case "me":
        return <MyDetails />
      case "team":
        return <TeamPage />
      case "members":
        return <ManageUsers />
      case "billing":
        return <h1>Billing</h1>
      default:
        return <h1>Team</h1>
    }
  }

  if (isSmall || isMedium) {
    return (
      <Page title="Settings">
        <Box sx={{ position: "relative", overflow: "hidden" }}>
          <Box mt={2}>{renderContent()}</Box>
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1000,
            }}
          >
            {isWorkspaceAdministrator && (
              <BottomNavigation
                value={selectedTab}
                onChange={(_event, newValue) => setSelectedTab(newValue)}
                showLabels
              >
                <BottomNavigationAction
                  label="Me"
                  icon={<PersonIcon />}
                  value="me"
                />
                <BottomNavigationAction
                  label="Company"
                  icon={<Business />}
                  value="team"
                />
                <BottomNavigationAction
                  label="Members"
                  icon={<GroupIcon />}
                  value="members"
                />
                <BottomNavigationAction
                  label="Billing"
                  icon={<CreditCard />}
                  value="billing"
                />
              </BottomNavigation>
            )}
          </Box>
        </Box>
      </Page>
    )
  }

  return (
    <Page title="Settings">
      <Container maxWidth="xl">
        <Tabs value={selectedTab} onChange={handleChange}>
          <Tab
            label="My Details"
            value="me"
            icon={<ContactEmergencyOutlinedIcon />}
          />
          <Tab
            label="Team Details"
            value="team"
            icon={<Groups2OutlinedIcon />}
          />
          <Tab
            label="Manage Team Members"
            value="members"
            icon={<GroupAddOutlinedIcon />}
          />
          <Tab label="Billing" value="billing" icon={<CreditCard />} />
        </Tabs>
        <Box>{renderContent()}</Box>
      </Container>
    </Page>
  )
}

export default withAuthenticationRequired(SettingsPage, {
  OnRedirecting: () => <RedirectToLogin />,
  signinRedirectArgs: { redirect_uri: window.location.href },
})
