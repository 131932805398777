import { Home, Person, Settings } from "@mui/icons-material"
import { Box, BottomNavigation, BottomNavigationAction, List, ListItem, ListItemText } from "@mui/material"
import Page from "./components/page"

const Test = () => {
  return <Page>
    <Box sx={{ position: "relative", height: "calc(100vh - 70px)", overflow: "hidden" }}>
      <Box sx={{ height: "100%", overflow: "auto", pb: 8 }}>
        <List>
          {[...Array(100)].map((_, index) => (
            <ListItem key={index}>
              <ListItemText primary={`Item ${index + 1}`} />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1000 }}>
        <BottomNavigation showLabels>
          <BottomNavigationAction label="Home" icon={<Home />} />
          <BottomNavigationAction label="Profile" icon={<Person />} />
          <BottomNavigationAction label="Settings" icon={<Settings />} />
        </BottomNavigation>
      </Box>
    </Box>
  </Page>
}

export default Test