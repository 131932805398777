import CircularProgress from "@mui/material/CircularProgress"
import { FC, useEffect, useRef, useCallback } from "react"
import PDFViewer from "../../../components/pdf-viewer"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import "./generate.css"
import { useNavigate, useParams } from "react-router-dom"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import {
  AgentAppointmentStatus,
  GeneratedPDFStatus,
  getAgentAppointmentPdf,
  selectAgentAppointmentPdf,
  selectAgentAppointmentPdfStatus,
  selectAgentAppointmentStatus,
  sendAgentAppointmentForSigning,
} from "../../../app/slices/agent-appointment-slice"

import { Box, Card, CardActions, CardContent } from "@mui/material"

export interface GenerateProps {
  url: string
  fileName: string
  isLoading: boolean
}

const Generate: FC = () => {
  const status = useAppSelector(selectAgentAppointmentStatus)
  const pdf = useAppSelector(selectAgentAppointmentPdf)
  const pdfStatus = useAppSelector(selectAgentAppointmentPdfStatus)
  const params = useParams()
  const dispatch = useAppDispatch()
  const workspaceId = Number(params.workspaceId)
  const navigate = useNavigate()
  const pdfViewerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (
      pdfStatus === GeneratedPDFStatus.initial ||
      pdfStatus === GeneratedPDFStatus.stale
    ) {
      dispatch(getAgentAppointmentPdf(workspaceId))
    }
  }, [pdfStatus, dispatch, workspaceId])

  const handleOnSendAgentAppointmentForSigning = useCallback(async () => {
    try {
      const success = await dispatch(
        sendAgentAppointmentForSigning(workspaceId),
      )
      if (success) {
        setTimeout(() => {
          navigate(`/workspaces/${workspaceId}`)
        }, 1500)
      } else {
        console.error("Failed to send agent appointment")
      }
    } catch (error) {
      console.error("Error occurred while sending agent appointment:", error)
    }
  }, [dispatch, workspaceId, navigate])

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: pdfViewerRef.current?.offsetTop || 0,
      behavior: "smooth",
    })
  }, [])

  if (status === AgentAppointmentStatus.loading) {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          height: "100%",
          pt: 2,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={100} />
      </Grid>
    )
  }

  return (
    <>
      <Card>
        <CardContent>
          <CardContent>
            Your agent appointment is ready to be signed. You can electronically or manually sign the agent appointment.
            <ul>
              <li>If you choose to manually sign the agent appointment, you must upload a scanned copy of the document.</li>
            </ul>
          </CardContent>
          <CardActions>
            <Button>Manually Sign</Button>
            <Button onClick={handleOnSendAgentAppointmentForSigning}>E-sign</Button>
          </CardActions>
        </CardContent>
      </Card>

    

      <Box ref={pdfViewerRef}>
        <PDFViewer url={pdf!} />
      </Box>

      <Box sx={{ margin: "0 auto 15px auto", width: "150px" }}>
        <Button onClick={scrollToTop} color="primary">
          Scroll to Top
        </Button>
      </Box>
    </>
  )
}

export default Generate
