import { FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { withAuthenticationRequired } from "react-oidc-context"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import Page from "../../components/page"
import KonveiModal from "../../components/modal/konvei-modal"
import { Box, Button } from "@mui/material"
import RedirectToLogin from "../../components/redirect-to-login"
import {
  acceptInvitation,
  selectAcceptInvitationStatus,
  WorkspacesStatus,
} from "../../app/slices/workspaces-slice"

const AcceptInvitation: FC = () => {
  const { invitation } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const status = useAppSelector(selectAcceptInvitationStatus)
  const [modalOpen, setModalOpen] = useState(true)

  const [processedInvitation, setProcessedInvitation] = useState<
    string | undefined
  >(undefined)
  useEffect(() => {
    if (
      processedInvitation !== invitation &&
      status === WorkspacesStatus.initial
    ) {
      dispatch(acceptInvitation(invitation as string))
    } else if (status === WorkspacesStatus.ready) {
      setProcessedInvitation(invitation)
    }
  }, [invitation, status, dispatch])

  const renderContent = () => {
    switch (status) {
      case WorkspacesStatus.initial:
      case WorkspacesStatus.loading:
        return <CircularProgress />
      case WorkspacesStatus.ready:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              🎉 Success! You're In! 🎉
            </Typography>
            <Typography variant="body1" paragraph>
              You've just accepted an invitation to join the{" "}
              <strong>landconnex workspace</strong> for <em>[Property Name]</em>
              . Welcome aboard!
            </Typography>
            <Typography variant="body2" style={{ marginBottom: 30 }}>
              Ready to explore? Head over to the{" "}
              <Link onClick={() => navigate("/workspaces")} color="primary">
                workspaces
              </Link>{" "}
              to view the property and get started.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <Button onClick={() => navigate("/workspaces")}>
                Go to Workspaces
              </Button>
            </Box>
          </>
        )
      case WorkspacesStatus.error:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              The invitation has already been accepted.
            </Typography>
            <Typography variant="body1" style={{ marginBottom: 30 }}>
              It looks like this invitation has already been accepted. If you
              believe this is an error, please contact support.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <Button onClick={() => navigate("/workspaces")}>
                Go to Workspaces
              </Button>
            </Box>
          </>
        )
      default:
        return <></>
    }
  }

  const handleModalClose = () => {
    setModalOpen(false)
    navigate("/")
  }

  return (
    <Page title="Accept an invitation">
      <KonveiModal
        open={modalOpen}
        onClose={handleModalClose}
        children={renderContent()}
      />
    </Page>
  )
}

export default withAuthenticationRequired(AcceptInvitation, {
  OnRedirecting: () => <RedirectToLogin />,
})
