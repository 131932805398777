import { FC, Fragment, useState } from "react"
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  List,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material"
import { Home } from "@mui/icons-material"
import NotificationsIcon from "@mui/icons-material/Notifications"
import FolderIcon from "@mui/icons-material/Folder"
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber"
import ContactPhoneIcon from "@mui/icons-material/ContactPhone"

import { Participant, WorkspaceSummary } from "../../landconnex-api-client"
import SelectSolicitor, {
  ActingForEnum,
} from "./components/actions/select-solicitor"
import { useAppSelector } from "../../app/hooks"
import { singleLineFullAddress } from "../../helpers/helpers"
import Timeline from "../timeline/timeline"
import DocumentsTable from "../../components/documents-table"
import { useNavigate } from "react-router-dom"
import {
  selectWorkflow,
  selectWorkspace,
} from "../../app/slices/workspaces-slice"
import { selectDocuments } from "../../app/slices/documents-slice"
import ParticipantListItem from "../../components/participant-list-item"
import WorkflowAction from "./components/workflow-action"
import DocumentListItem from "../../components/document-list-item"

interface WorkspaceRetailProps {
  workspace: WorkspaceSummary
  participants: Participant[]
  inviteSolicitor?: any
}

const WorkspaceRetail: FC<WorkspaceRetailProps> = ({ participants }) => {
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))
  const [value, setValue] = useState(0)

  // const sessState = useAppSelector(sessionState)
  const workspace = useAppSelector(selectWorkspace)
  const workflow = useAppSelector(selectWorkflow)
  const documents = useAppSelector(selectDocuments)
  const navigate = useNavigate()
  // const roles = [
  //   "sellerAgent",
  //   "seller",
  //   "sellerSolicitor",
  //   // "buyer",
  //   // "buyerSolicitor",
  // ]

  // const filteredParticipants = participants.filter(participant => {
  //   return (
  //     roles.includes(participant.role!) &&
  //     participant.firstName &&
  //     participant.phone //&&
  //     //participant.email !== sessState.details?.email!
  //   )
  // })

  const onDocumentClicked = (document: string): void => {
    if (!document) {
      console.error("Invalid document ID")
      return
    }
    navigate(`document/${document}`)
  }

  if (!mdUp) {
    return (
      <Box sx={{ position: "relative", height: "100vh", overflow: "hidden" }}>
        <Box
          id="property-image"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: "url(/retail-background.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            opacity: 0.5,
            zIndex: 0,
          }}
        />
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 9,
            backgroundColor: "rgba(0,0,0,0.5)",
            height: 60,
            placeContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
              color: "#fff",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <img
                src="https://www.momac.co.nz/wp-content/uploads/2021/06/ray_whitet_momac_website.png"
                alt="client logo"
                style={{ width: 100 }}
              />
              <Typography>{workspace?.property?.streetAddress1}</Typography>
            </Box>
            <Box sx={{ paddingRight: 1 }}>
              <NotificationsIcon />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
            height: "100%",
            overflow: "auto",
            zIndex: 1,
            pt: 7,
            pb: 8,
          }}
        >
          {value === 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                padding: "0 15px",
              }}
            >
              <Card>
                <CardHeader title={`Property Information`} />
                <CardContent sx={{ padding: "15px" }}>
                  <Typography variant="body1">
                    <Typography component="span" sx={{ fontWeight: 600 }}>
                      Address:
                    </Typography>{" "}
                    {singleLineFullAddress(workspace?.property!)}
                  </Typography>
                  <Typography variant="body1">
                    <Typography component="span" sx={{ fontWeight: 600 }}>
                      Lot & Plan:
                    </Typography>{" "}
                    {workspace?.property?.lot} {workspace?.property?.plan}
                  </Typography>
                  <Typography variant="body1">
                    <Typography component="span" sx={{ fontWeight: 600 }}>
                      Title Reference:
                    </Typography>{" "}
                    {workspace?.property?.titleReference}
                  </Typography>
                </CardContent>
              </Card>
              {workflow?.subState ===
                "contractPreparationAwaitingSellerSolicitorSelection" && (
                <SelectSolicitor actingFor={ActingForEnum.Seller} />
              )}
            </Box>
          )}

          {value === 1 && (
            <List
              sx={{
                backgroundColor: "#f0f0f0",
                pt: 0,
                pb: 0,
                borderBottom: "solid 1px #555",
              }}
            >
              {participants.map(participant => (
                <ParticipantListItem
                  sx={{
                    border: "solid 1px #555",
                    borderBottomWidth: 0,
                  }}
                  participant={participant}
                />
              ))}
            </List>
          )}
          {value === 2 && <WorkflowAction />}

          {value === 3 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                padding: "0 15px",
              }}
            >
              <Card>
                <CardHeader title={`Documents`} />
                <CardContent sx={{ padding: "15px" }}>
                  <DocumentsTable
                    onDocumentViewClicked={onDocumentClicked}
                    documents={documents}
                  />
                </CardContent>
              </Card>
            </Box>
          )}
        </Box>
        <Box
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1000 }}
        >
          <BottomNavigation
            showLabels
            value={value}
            onChange={(_, newValue) => {
              setValue(newValue)
            }}
          >
            <BottomNavigationAction label="Home" icon={<Home />} />
            <BottomNavigationAction
              label="Contacts"
              icon={<ContactPhoneIcon />}
            />
            <BottomNavigationAction
              label="Offers"
              icon={<ConfirmationNumberIcon />}
            />
            <BottomNavigationAction label="Documents" icon={<FolderIcon />} />
          </BottomNavigation>
        </Box>
      </Box>
    )
  }
  return (
    <Fragment>
      <Box
        id="property-image"
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: "url(/retail-background-desktop.png)",
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          opacity: 0.5,
          zIndex: 0,
        }}
      />

      <Box
        sx={{
          position: "relative",
          padding: 2,
        }}
      >
        <Box
          sx={{
            marginBottom: 2, // Spacing between timeline and content
          }}
        >
          <Timeline workflow={workflow!} workspace={workspace!} />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "column",
              xs: "column",
            },
            gap: 2,
            overflow: "hidden",
          }}
        >
          <Card
            sx={{
              flex: {
                lg: "1 0 33.33%",
                md: "1 0 40%",
                xs: "1 0 100%",
              },
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 5,
              }}
            >
              <Box>
                <Typography variant="h6" mb={1}>
                  Property Information
                </Typography>
                <Typography variant="body1">
                  <Typography component="span" sx={{ fontWeight: 600 }}>
                    Address:
                  </Typography>{" "}
                  {singleLineFullAddress(workspace?.property!)}
                </Typography>
                <Typography variant="body1">
                  <Typography component="span" sx={{ fontWeight: 600 }}>
                    Lot & Plan:
                  </Typography>{" "}
                  {workspace?.property?.lot} {workspace?.property?.plan}
                </Typography>
                <Typography variant="body1">
                  <Typography component="span" sx={{ fontWeight: 600 }}>
                    Title Reference:
                  </Typography>{" "}
                  {workspace?.property?.titleReference}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" mb={1}>
                  Important Contacts
                </Typography>
                <Box sx={{ backgroundColor: "#f0f0f0" }}>
                  <List>
                    {participants.map(participant => (
                      <ParticipantListItem participant={participant} />
                    ))}
                  </List>
                </Box>
                {/* <ParticipantList
                  participants={participants}
                  currentUserEmail={sessState.details?.email!}
                  simplifiedLayout
                  roles={[
                    "sellerAgent",
                    "seller",
                    "sellerSolicitor",
                    "buyer",
                    "buyerSolicitor",
                  ]}
                /> */}
              </Box>
              <Box>
                <Typography variant="h6" mb={1}>
                  Documents
                </Typography>
                <List>
                  {documents.map(document => (
                    <DocumentListItem
                      document={document}
                      workspaceId={workspace?.workspace?.id!}
                    />
                  ))}
                </List>
              </Box>
            </CardContent>
          </Card>

          <Box
            sx={{
              flex: {
                lg: "1 0 66.66%",
                md: "1 0 60%",
                xs: "1 0 100%",
              },
            }}
          >
            <WorkflowAction />
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default WorkspaceRetail
