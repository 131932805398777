import Grid from "@mui/material/Grid2"
import Typography from "@mui/material/Typography"
import Page from "../../components/page"
import { PieChart } from "@mui/x-charts/PieChart"
import Fact from "./fact"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableHead from "@mui/material/TableHead"
import TableContainer from "@mui/material/TableContainer"
import { Stack, Box, useTheme, useMediaQuery } from "@mui/material"
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined"
import AnalyticsIcon from "@mui/icons-material/Analytics"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  getWorkspaces,
  selectWorkspaces,
  selectWorkspacesStatus,
  WorkspacesStatus,
} from "../../app/slices/workspaces-slice"
import {
  getDashboard,
  ReportsStatus,
  selectAgentStatistics,
  selectGcRevenue,
  selectReportsStatus,
  selectTotalListingsCount,
  selectTotalListingsValue,
  selectTotalSettlementsValueThisWeek,
  selectTotalGCRevenueThisWeek,
} from "../../app/slices/reports-slice"
import WorkspaceRow from "../../components/workspace-table/workspace-table-row"
import { useEffect } from "react"
import { formatCurrency } from "../../helpers/helpers"
import { selectTeamName } from "../../app/slices/session-slice"
import DashboardSmall from "./dashboard-small"

const Dashboard = () => {
  const dispatch = useAppDispatch()

  const gcRevenue = useAppSelector(selectGcRevenue)
  const reportsStatus = useAppSelector(selectReportsStatus)
  const totalListingsCount = useAppSelector(selectTotalListingsCount)
  const totalListingsValue = useAppSelector(selectTotalListingsValue)
  const totalSettlementsValueThisWeek = useAppSelector(
    selectTotalSettlementsValueThisWeek,
  )
  const totalGCRevenueThisWeek = useAppSelector(selectTotalGCRevenueThisWeek)
  const workspacesStatus = useAppSelector(selectWorkspacesStatus)
  const agentStatistics = useAppSelector(selectAgentStatistics)
  const teamName = useAppSelector(selectTeamName)
  const theme = useTheme()
  const isSmallOrMedium = useMediaQuery(theme.breakpoints.between("xs", "md"))
  useEffect(() => {
    if (reportsStatus === ReportsStatus.initial) {
      dispatch(getDashboard())
    }
  }, [dispatch, reportsStatus])

  const workspaces = useAppSelector(selectWorkspaces)
  useEffect(() => {
    if (workspacesStatus === WorkspacesStatus.initial) {
      dispatch(getWorkspaces())
    }
  }, [dispatch, workspacesStatus])

  const data = agentStatistics.map(agent => ({
    id: agent.sub,
    value: agent.gcRevenue / gcRevenue,
    label: agent.agentName,
  }))

  const dataColors = ["#f44236", "#1e88e5", "#00c854", "#673ab7"]

  if (isSmallOrMedium) {
    return <DashboardSmall />
  }
  return (
    <Page>
      <Grid container>
        <Grid size={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            gap={2}
            sx={{
              mt: { md: 0, xs: 2 },
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontSize: { lg: 34, md: 28, xs: 24 } }}
              color="text.secondary"
            >
              {teamName}
            </Typography>
          </Stack>
        </Grid>

        <Grid container size={12} spacing={1} sx={{ paddingBottom: 1 }}>
          <Grid
            size={{ xs: 12, md: 6, lg: 4 }}
            sx={{ backgroundColor: "#fff", padding: 1 }}
          >
            <Fact
              title="Total Listings"
              value={`${totalListingsCount}`}
              icon={
                <AnalyticsIcon sx={{ fontSize: { lg: 80, sm: 70, xs: 50 } }} />
              }
              sx={{
                background: "#eaf3fa",
                height: { lg: 150, sm: 120, xs: 100 },
              }}
            />
          </Grid>

          <Grid
            size={{ xs: 12, md: 6, lg: 4 }}
            sx={{ backgroundColor: "#fff", padding: 1 }}
          >
            <Fact
              title="Total GCR"
              sx={{
                background: "#eaf3fa",
                height: { lg: 150, sm: 120, xs: 100 },
              }}
              icon={
                <MonetizationOnOutlinedIcon
                  sx={{ fontSize: { lg: 80, sm: 70, xs: 50 } }}
                />
              }
              value={`${formatCurrency(gcRevenue)}`}
            />
          </Grid>
          <Grid
            size={{ xs: 12, md: 6, lg: 4 }}
            sx={{ backgroundColor: "#fff", padding: 1 }}
          >
            <Fact
              title="Total Listings Value"
              value={formatCurrency(totalListingsValue)}
              icon={
                <MonetizationOnOutlinedIcon
                  sx={{ fontSize: { lg: 80, sm: 70, xs: 50 } }}
                />
              }
              sx={{
                background: "#eaf3fa",
                height: { lg: 150, sm: 120, xs: 100 },
              }}
            />
          </Grid>
        </Grid>

        <Grid size={{ xs: 12, md: 6, lg: 8 }} container spacing={1}>
          <Grid sx={{ overflow: "auto", backgroundColor: "#fff", padding: 1 }}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
              <Typography variant="h6">In Progress Workspaces</Typography>
              <TableContainer sx={{ height: "700px" }}>
                <Table stickyHeader sx={{ whiteSpace: "nowrap" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Address</TableCell>
                      <TableCell>Seller/s</TableCell>
                      <TableCell>Agent</TableCell>
                      <TableCell>Asking Price</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Offers</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {workspaces.map(workspace => (
                      <WorkspaceRow
                        key={workspace.workspace.id}
                        workspace={workspace.workspace}
                        workflow={workspace.workflow}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
        <Grid
          size={{ xs: 12, md: 6, lg: 4 }}
          sx={{ backgroundColor: "#fff", padding: 1 }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Fact
              title="GCR This Week"
              value={formatCurrency(totalGCRevenueThisWeek)}
              icon={
                <MonetizationOnOutlinedIcon
                  sx={{ fontSize: { lg: 80, sm: 70, xs: 50 } }}
                />
              }
              sx={{ height: { lg: 150, sm: 120, xs: 100 }, flex: 1 }}
            />
            <Fact
              title="Settlements This Week"
              icon={
                <MonetizationOnOutlinedIcon
                  sx={{ fontSize: { lg: 80, sm: 70, xs: 50 } }}
                />
              }
              value={formatCurrency(totalSettlementsValueThisWeek)}
              sx={{ height: { lg: 150, sm: 120, xs: 100 }, flex: 1 }}
            />
          </Box>
          <Grid
            size={12}
            sx={{ display: "flex", justifyContent: "center", padding: 2 }}
          >
            <Stack>
              <PieChart
                colors={dataColors}
                width={400}
                height={200}
                series={[
                  {
                    data,
                    valueFormatter: value =>
                      `${(value.value * 100).toFixed(2)}%`,
                    innerRadius: 30,
                    outerRadius: 100,
                    paddingAngle: 2,
                    cornerRadius: 5,
                    startAngle: -45,
                  },
                ]}
              >
                {/* <PieCenterLabel>% GCR Contribution by Agent</PieCenterLabel> */}
              </PieChart>
              <Typography
                variant="h6"
                sx={{ textAlign: "center", color: "text.secondary" }}
              >
                GCR Contribution by Agent
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

export default Dashboard
