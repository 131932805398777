import { titlesApi } from "../../api"
import { TitleSearchResult } from "../../landconnex-api-client"
import { createAppSlice } from "../createAppSlice"

export enum TitleStatus {
  ready = "ready",
  loading = "loading",
  error = "error",
}

export interface TitleState {
  titles: TitleSearchResult[]
  status: TitleStatus
}

export const initialState: TitleState = {
  titles: [],
  status: TitleStatus.ready,
}

export const titlesSlice = createAppSlice({
  name: "titles",
  initialState,
  reducers: create => ({
    searchForTitleByAddress: create.asyncThunk<TitleSearchResult[], string>(
      async searchTerm => {
        const response = await titlesApi.searchForTitleByAddress(searchTerm)
        return response.data.items!
      },
      {
        pending: state => {
          state.titles = []
          state.status = TitleStatus.loading
        },
        fulfilled: (state, action) => {
          state.titles = action.payload
          state.status = TitleStatus.ready
        },
        rejected: state => {
          state.status = TitleStatus.error
        },
      },
    ),
  }),
  selectors: {
    selectTitles: titlesState => titlesState.titles,
    selectTitlesStatus: titlesState => titlesState.status,
  },
})

export const { searchForTitleByAddress } = titlesSlice.actions
export const { selectTitles, selectTitlesStatus } = titlesSlice.selectors
