import { Participant } from "../../../landconnex-api-client"
import { OnParticipantChanged } from "../../../components/events"
import ClientDetail from "./client-detail"
import { Card, CardHeader, CardContent } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { useParams } from "react-router-dom"
import {
  putParticipant,
  selectParticipants,
} from "../../../app/slices/participants-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"

export type OnAddBuyer = () => void

export interface SellerDetailsProps {
  sellers: Array<Participant>
  onChanged: OnParticipantChanged
  onAdded: OnAddBuyer
  onRemoved: OnParticipantChanged
}
const SellerDetails: React.FC = ({}) => {
  const participants = useAppSelector(selectParticipants)
  const workspace = useAppSelector(selectWorkspace)
  const dispatch = useAppDispatch()
  const params = useParams()
  const workspaceId = Number(params.workspaceId)

  let disableField = true
  if (workspace != undefined) {
    disableField = ["buyer", "buyerSolicitor"].includes(
      workspace!.workspace!.role!,
    )
  }

  function handleClientChanged(participant: Participant): void {
    dispatch(
      putParticipant({
        participant: participant,
        workspaceId: workspaceId,
      }),
    )
  }

  const sellers = participants.filter(p => p.role == "seller")
  return (
    <Card
      sx={{ margin: "15px 0", boxShadow: "0 1px 15px 0 rgba(0, 0, 0, 0.1)" }}
    >
      <CardHeader title="Seller's Details" />
      <CardContent>
        {sellers.map((client, index) => (
          <ClientDetail
            key={client.id}
            client={client}
            index={index}
            onChanged={handleClientChanged}
            disabled={disableField}
          />
        ))}
      </CardContent>
    </Card>
  )
}

export default SellerDetails
