import Grid from "@mui/material/Grid"
import React from "react"
import ParticipantCard from "../../../components/participant-card"
import FormWrapper from "../../agent-appointment/components/form-wrapper"
import { useAppSelector } from "../../../app/hooks"
import { selectParticipants } from "../../../app/slices/participants-slice"

const SellerAgent: React.FC = () => {
  const participants = useAppSelector(selectParticipants)
  const sellerAgent = participants.find(p => p.role == "sellerAgent")
  return (
    <FormWrapper title="Seller's Agent">
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <ParticipantCard participant={sellerAgent!} />
        </Grid>
      </Grid>
    </FormWrapper>
  )
}

export default SellerAgent
