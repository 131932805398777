import {
  Box,
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
  Typography,
  Button,
} from "@mui/material"
import { Address, Name, OnBoarding } from "../../landconnex-api-client"
import { FC } from "react"
import { convertCamelCase } from "../../helpers/helpers"

export interface CompanyDetailsProps {
  onBoarding: OnBoarding
  onComplete: (onBoarding: OnBoarding) => void
  onCompanyDetailsChange: (onBoarding: OnBoarding) => void
}

const CompanyDetails: FC<CompanyDetailsProps> = ({
  onBoarding,
  onComplete,
  onCompanyDetailsChange,
}) => {
  const handleAddressChange = (field: keyof Address, value: any) => {
    onCompanyDetailsChange({
      ...onBoarding,
      physicalAddress: {
        ...onBoarding.physicalAddress,
        [field]: value,
      },
    })
  }

  const handleCompanyDetailsChange = (field: keyof OnBoarding, value: any) => {
    onCompanyDetailsChange({
      ...onBoarding,
      [field]: value,
    })
  }

  const handlePrimaryContactChange = (field: keyof Name, value: string) => {
    onCompanyDetailsChange({
      ...onBoarding,
      primaryContactName: {
        ...onBoarding.primaryContactName,
        [field]: value,
      },
    })
  }

  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          mb: 4,
          mx: "auto",
          textAlign: "center",
        }}
      >
        <Typography variant="h6" sx={{ mb: 3 }}>
          {convertCamelCase(onBoarding.companyType)}
        </Typography>
        <img
          src={
            onBoarding.companyType === "realEstateAgency"
              ? "https://landconnex.com.au/assets/images/notification-man.png"
              : "https://landconnex.com.au/assets/images/data-entry.png"
          }
          alt={
            onBoarding.companyType === "realEstateAgency"
              ? "Real Estate Agent"
              : "Solicitor"
          }
          style={{ height: "100px" }}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Company Details</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            fullWidth
            label="Company Name"
            value={onBoarding.companyName || ""}
            onChange={e =>
              handleCompanyDetailsChange("companyName", e.target.value)
            }
            required
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            variant="filled"
            fullWidth
            label="ABN"
            value={onBoarding.abn || ""}
            onChange={e => handleCompanyDetailsChange("abn", e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="filled"
            fullWidth
            label="ACN"
            value={onBoarding.acn || ""}
            onChange={e => handleCompanyDetailsChange("acn", e.target.value)}
            required
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={onBoarding.registeredForGst}
                onChange={e =>
                  handleCompanyDetailsChange(
                    "registeredForGst",
                    e.target.checked,
                  )
                }
              />
            }
            label="Registered for GST"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Primary Contact</Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            variant="filled"
            fullWidth
            label="First Name"
            value={onBoarding.primaryContactName?.firstName || ""}
            onChange={e =>
              handlePrimaryContactChange("firstName", e.target.value)
            }
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="filled"
            fullWidth
            label="Last Name"
            value={onBoarding.primaryContactName?.lastName || ""}
            onChange={e =>
              handlePrimaryContactChange("lastName", e.target.value)
            }
            required
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="filled"
            label="Email"
            value={onBoarding.email || ""}
            onChange={e => handleCompanyDetailsChange("email", e.target.value)}
            required
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Physical Address</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            fullWidth
            label="Street Address"
            value={onBoarding.physicalAddress?.streetAddress1 || ""}
            onChange={e =>
              handleAddressChange("streetAddress1", e.target.value)
            }
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            fullWidth
            label="Street Address Line 2"
            value={onBoarding.physicalAddress?.streetAddress2 || ""}
            onChange={e =>
              handleAddressChange("streetAddress2", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="filled"
            fullWidth
            label="City/Suburb"
            value={onBoarding.physicalAddress?.locality || ""}
            onChange={e => handleAddressChange("locality", e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="filled"
            fullWidth
            label="State/Territory"
            value={onBoarding.physicalAddress?.stateOrTerritory || ""}
            onChange={e =>
              handleAddressChange("stateOrTerritory", e.target.value)
            }
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="filled"
            fullWidth
            label="Postcode"
            value={onBoarding.physicalAddress?.postCode || ""}
            onChange={e => handleAddressChange("postCode", e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="filled"
            fullWidth
            label="Country"
            value={onBoarding.physicalAddress?.country || ""}
            onChange={e => handleAddressChange("country", e.target.value)}
            required
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 6, width: 110, justifySelf: "center" }}>
        <Button size="large" onClick={() => onComplete(onBoarding)}>
          Next
        </Button>
      </Box>
    </Box>
  )
}

export default CompanyDetails
