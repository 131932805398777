import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
} from "@mui/material"
import { useAppSelector } from "../../../app/hooks"
import {
  selectWorkspaces,
} from "../../../app/slices/workspaces-slice"


import WorkspaceRow from "../../../components/workspace-table/workspace-table-row"



const WorkspacesLarge = () => {
  const workspaces = useAppSelector(selectWorkspaces)

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>Seller/s</TableCell>
            <TableCell>Agent</TableCell>
            <TableCell>Asking Price</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Offers</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workspaces.map(workspace => (
            <WorkspaceRow
              key={workspace.workspace.id}
              workspace={workspace.workspace}
              workflow={workspace.workflow}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default WorkspacesLarge
