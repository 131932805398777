import { Card, CardActions, CardContent, CardHeader, styled } from "@mui/material";

const WorkspaceCard = styled(Card)(() => ({
    // backgroundColor: "background.default",
}));

export const WorkspaceCardHeader= styled(CardHeader)(() => ({

    // backgroundColor: "#fff",
}));

export const WorkspaceCardContent= styled(CardContent)(() => ({
    // backgroundColor: "#fff",
}));

export const WorkspaceCardActions= styled(CardActions)(() => ({
    // backgroundColor: "#fff",
}));

export default WorkspaceCard;