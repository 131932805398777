import {  usersApi } from "../../api"
import { createAppSlice } from "../../app/createAppSlice"
import { Participant } from "../../landconnex-api-client"

export enum MyDetailsStatus {
  initial,
  loading,
  ready,
  error,
}

interface MyDetailsState {
  status: MyDetailsStatus
  myDetails: Participant | undefined
}

const initialState: MyDetailsState = {
  status: MyDetailsStatus.initial,
  myDetails: undefined,
}

export const myDetailsSlice = createAppSlice({
  name: "myDetails",
  initialState,
  reducers: create => ({
    getMyDetails: create.asyncThunk<Participant, void>(
      async () => {
        const response = await usersApi.getMyDetails()
        return response.data.user
      },
      {
        pending: state => {
          state.status = MyDetailsStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = MyDetailsStatus.ready
          state.myDetails = action.payload
        },
        rejected: state => {
          state.status = MyDetailsStatus.error
        },
      },
    ),
    updateMyDetails: create.asyncThunk<void, Participant>(
      async data => {
        await usersApi.putMyDetails({ user: data })
      },
      {
        pending: state => {
          state.status = MyDetailsStatus.loading
        },
        fulfilled: state => {
          state.status = MyDetailsStatus.ready
        },
        rejected: state => {
          state.status = MyDetailsStatus.error
        },
      },
    ),
  }),

  selectors: {
    selectMyDetails: settings => settings.myDetails,
    selectMyDetailsStatus: settings => settings.status,
  },
})

export const { getMyDetails, updateMyDetails } = myDetailsSlice.actions

export const { selectMyDetails, selectMyDetailsStatus } =
  myDetailsSlice.selectors
