import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React, { ChangeEvent } from "react"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import FormWrapper from "../../agent-appointment/components/form-wrapper"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { putContract, selectContract } from "../../../app/slices/contract-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { Contract } from "../../../landconnex-api-client"
import { produce } from "immer"

const PoolSafety: React.FC = () => {
  const dispatch = useAppDispatch()

  const contract = useAppSelector(selectContract)
  const workspace = useAppSelector(selectWorkspace)
  const disableField = ["buyer", "buyerSolicitor"].includes(
    workspace!.workspace!.role!,
  )

  function onChanged(event: ChangeEvent<HTMLInputElement>): void {
    let newContract: Contract | undefined = undefined

    switch (event.currentTarget.name) {
      case "hasPool":
        newContract = produce(contract!, draft => {
          draft.hasPool = event.currentTarget.value == "true"
          if (!draft.hasPool) {
            draft.hasPoolCertificate = false
          }
        })
        break
      case "hasPoolCertificate":
        newContract = produce(contract!, draft => {
          draft.hasPoolCertificate = event.currentTarget.value == "true"
        })
        break
    }

    if (newContract) {
      dispatch(
        putContract({
          workspaceId: workspace!.workspace!.id,
          contract: newContract,
        }),
      )
    }
  }

  return (
    <FormWrapper title="Pool Safety">
      <Grid container spacing={2}>
        <Grid item sm={12} lg={12}>
          <Typography variant="body1">
            Is there a pool on the Land or on adjacent land used in association
            with the Land?
          </Typography>
        </Grid>
        <Grid item sm={12} lg={12}>
          <RadioGroup
            value={contract!.hasPool ? contract!.hasPool : false}
            name="hasPool"
            onChange={onChanged}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Yes"
              disabled={disableField}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="No"
              disabled={disableField}
            />
          </RadioGroup>
        </Grid>

        {contract!.hasPool ? (
          <React.Fragment>
            <Grid item sm={12} lg={12}>
              <Typography variant="body1">
                Is there a Pool Compliance Certificate for the pool at the time
                of contract?
              </Typography>
            </Grid>
            <Grid item sm={12} lg={12}>
              <RadioGroup
                value={
                  contract!.hasPoolCertificate
                    ? contract!.hasPoolCertificate
                    : false
                }
                onChange={onChanged}
                name="hasPoolCertificate"
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                  disabled={disableField}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                  disabled={disableField}
                />
              </RadioGroup>
            </Grid>
          </React.Fragment>
        ) : null}
      </Grid>
    </FormWrapper>
  )
}

export default PoolSafety
