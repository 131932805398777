import React, { useEffect, useState } from "react"
import {
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { useDebounceValue } from "usehooks-ts"
import { useAppDispatch, useAppSelector } from "../../app/hooks"

import {
  FlattenedAddress,
  PhysicalAustralianAddress,
  TitleSearchResult,
} from "../../landconnex-api-client"
import {
  searchForTitleByAddress,
  selectTitles,
  selectTitlesStatus,
  TitleStatus,
} from "../../app/slices/titles-slice"
import {
  searchAddress,
  selectAddressSearchResults,
  getAddressDetails,
  selectAddressDetails,
  AddressSearchStatus,
  selectSearchAddressStatus,
  selectGetAddressDetailsStatus,
} from "../../app/slices/address-search-slice"
import { CheckCircle } from "@mui/icons-material"

export interface SearchForPropertyProps {
  selectedAddress: PhysicalAustralianAddress | null
  onAddressSelected: (address: PhysicalAustralianAddress | null) => void
  onTitleSelected: (title: TitleSearchResult | null) => void
  selectedTitle: TitleSearchResult | null
  missingTitleError?: boolean
}

const SearchForProperty: React.FC<SearchForPropertyProps> = ({
  // selectedAddress,
  onAddressSelected,
  onTitleSelected,
  selectedTitle,
  missingTitleError,
}) => {
  const dispatch = useAppDispatch()
  const titles = useAppSelector(selectTitles)
  const addressSearchResults = useAppSelector(selectAddressSearchResults)
  const titlesStatus = useAppSelector(selectTitlesStatus)
  const addressDetails = useAppSelector(selectAddressDetails)
  const addressSearchStatus = useAppSelector(selectSearchAddressStatus)
  const getAddressDetailsStatus = useAppSelector(selectGetAddressDetailsStatus)
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [selectedValue, setSelectedValue] = useState<FlattenedAddress | null>(
    null,
  )
  const [debouncedAddress] = useDebounceValue(searchTerm, 1000)

  useEffect(() => {
    if (debouncedAddress) {
      dispatch(searchAddress(debouncedAddress))
      // dispatch(lookupAddress(debouncedAddress))
    }
  }, [debouncedAddress, dispatch])

  useEffect(() => {
    if (addressDetails) {
      dispatch(searchForTitleByAddress(addressDetails.pid))
      onAddressSelected(addressDetails)
    }
  }, [addressDetails, dispatch])

  const handleAddressChange = (
    _: unknown,
    newValue: FlattenedAddress | null,
  ) => {
    if (newValue) {
      setSelectedValue(newValue)
      onTitleSelected(null)
      dispatch(getAddressDetails(newValue.pid!))
    }
  }

  const isTitleSearchLoading =
    titlesStatus === TitleStatus.loading ||
    getAddressDetailsStatus === AddressSearchStatus.loading
  const handleTitleSelected = (title: TitleSearchResult) => {
    onTitleSelected(title)
  }

  return (
    <>
      <Typography variant="h6">Property</Typography>
      <Typography variant="subtitle1">
        Enter the address of the property, then select the address from the
        dropdown.
      </Typography>
      <Box sx={{ display: "flex", margin: "15px 0", alignItems: "center" }}>
        <Autocomplete
          fullWidth
          autoComplete
          value={selectedValue}
          onChange={handleAddressChange}
          filterOptions={x => x}
          isOptionEqualToValue={(option, value) => option.pid === value.pid}
          options={addressSearchResults}
          onInputChange={(_, value) => setSearchTerm(value)}
          getOptionLabel={option => option.sla || ""}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              label="Address"
              variant="filled"
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {addressSearchStatus === AddressSearchStatus.loading && (
                      <CircularProgress color="inherit" size={24} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>
      {isTitleSearchLoading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography ml={2}>
            Searching title registry. This can take up to 30 seconds...
          </Typography>
        </Box>
      )}
      {addressSearchResults?.length > 0 && !isTitleSearchLoading && (
        <>
          <Typography variant="h6" mt={2}>
            Matching Titles
          </Typography>
          <Typography variant="body1">
            Select the title that matches the property being sold by the
            seller/s.
          </Typography>

          <List>
            {titles?.map((address, index) => (
              <ListItem
                key={index}
                sx={{
                  outline:
                    selectedTitle?.reference === address.reference
                      ? "2px solid lightblue"
                      : missingTitleError
                        ? "1px solid red"
                        : "1px solid #ccc",
                  borderRadius: "8px",
                  margin: "8px 0",
                  padding: "12px",
                  cursor: "pointer",
                  transition: "background-color 0.3s, box-shadow 0.3s",
                  backgroundColor:
                    selectedTitle?.reference === address.reference
                      ? "#f0f8ff"
                      : "#fff",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  },
                }}
                onClick={() => handleTitleSelected(address)}
              >
                <ListItemText
                  primary={address.address}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="subtitle2"
                        display="block"
                      >
                        Title: {address.reference}
                      </Typography>
                      <Typography
                        component="span"
                        variant="subtitle2"
                        display="block"
                      >
                        Lot on Plan: {address.lotOnPlan}
                      </Typography>
                      <Typography
                        component="span"
                        variant="subtitle2"
                        display="block"
                      >
                        Owner name: {address.ownerName}
                      </Typography>
                    </>
                  }
                />
                {selectedTitle?.reference === address.reference && (
                  <ListItemIcon>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                )}
              </ListItem>
            ))}
          </List>
        </>
      )}
    </>
  )
}

export default SearchForProperty
