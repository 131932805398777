import { FC, useState } from "react"
import {
  Box,
  Typography,
  Alert,
  FormControl,
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import { inviteBuyerSolicitor, inviteSellerSolicitor } from "../../thunks"
import { selectWorkspace } from "../../../../app/slices/workspaces-slice"

export enum ActingForEnum {
  Buyer,
  Seller,
}

export interface SelectSolicitorArgs {
  actingFor: ActingForEnum
  offerId?: number
}

// interface Solicitor {
//   id: number
//   name: string
//   email: string
//   firm: string
// }

const SelectSolicitor: FC<SelectSolicitorArgs> = ({ actingFor }) => {
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)
  const [invitedEmail, setInvitedEmail] = useState("")
  const [emailError, setEmailError] = useState("")

  const workspace = useAppSelector(selectWorkspace)
  // const params = useParams()
  // const workspaceParams = getParams(params)

  // const recommendedSolicitors: Solicitor[] = [
  //   {
  //     id: 1,
  //     name: "Robert Solicitor",
  //     email: "robert.ovens.solicitor@landconnex.com.au",
  //     firm: "Smith & Associates",
  //   },
  //   {
  //     id: 2,
  //     name: "Nadine Solicitor",
  //     email: "nadine.wismayer.solicitor@landconnex.com.au",
  //     firm: "The Real Estate Lawyer",
  //   },
  //   {
  //     id: 3,
  //     name: "David Solicitor",
  //     email: "david.maire.solicitor@landconnex.com.au",
  //     firm: "PropTech Legal",
  //   },
  // ]

  const handleInvite = () => {
    if (validateEmail(email)) {
      if (actingFor == ActingForEnum.Seller) {
        dispatch(
          inviteSellerSolicitor({
            workspaceId: workspace!.workspace!.id,
            emailAddress: email,
          }),
        )
      } else if (actingFor == ActingForEnum.Buyer) {
        dispatch(
          inviteBuyerSolicitor({
            workspaceId: workspace!.workspace!.id,
            emailAddress: email,
            offerId: 2,
          }),
        )
      }

      setSuccess(true)
      setInvitedEmail(email)
      setEmail("")
      setEmailError("")
    } else {
      setEmailError("Please enter a valid email address.")
    }
  }

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(String(email).toLowerCase())
  }

  // const handleSolicitorSelect = (solicitor: Solicitor) => {
  //   setEmail(solicitor.email)
  //   setSelectedSolicitorId(solicitor.id)
  //   if (success) setSuccess(false)
  //   if (emailError) setEmailError("")
  // }

  return (
    <Card>
      <CardHeader title={`Appoint a Solicitor`} />
      <CardContent sx={{ padding: "30px" }}>
        <Box>
          {/* <Typography sx={{ textAlign: "justify", marginBottom: 3 }}>
                Hello username, <br />
                Thank you for using Ray White for the successful sale of your
                property.
              </Typography> 
              <p>
                <strong>Next step:</strong> Invite a solicitor to prepare your
                contract.
              </p>*/}
          <Typography variant="body1" gutterBottom>
            Solicitors are conveyancing experts who have been trained in
            property law and will ensure your interests are protected throughout
            the sale. They will help you:
          </Typography>
          <List sx={{ listStyleType: "disc", pl: 2 }}>
            <ListItem sx={{ display: "list-item", padding: "0 0 0 10px" }}>
              <Typography variant="body1">
                Ensure you meet all legal disclosure requirements to the buyer
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "list-item", padding: "0 0 0 10px" }}>
              <Typography variant="body1">
                Check all documents are properly prepared and signed
              </Typography>
            </ListItem>
          </List>

          {success && (
            <Alert severity="success" sx={{ marginBottom: 2 }}>
              Solicitor invitation was successfully sent to {invitedEmail}.
            </Alert>
          )}
          <Box sx={{ width: "100%", my: 3 }}>
            <FormControl sx={{ minWidth: 300 }}>
              <TextField
                label="Solicitor Email"
                value={email}
                onChange={e => {
                  setEmail(e.target.value)
                  if (success) setSuccess(false)
                  if (emailError) setEmailError("")
                }}
                fullWidth
                variant="filled"
                error={Boolean(emailError)}
                helperText={emailError}
              />
            </FormControl>
          </Box>
        </Box>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Box sx={{ width: 120 }}>
          <Button onClick={handleInvite} variant="contained" fullWidth>
            Invite
          </Button>
        </Box>
      </CardActions>
    </Card>
  )
}

export default SelectSolicitor
