import { teamsApi } from "../../api"
import { createAppSlice } from "../createAppSlice"
import {
  Team,
  TeamParticipant,
  TeamInvitation,
  PutMyDetailsRequest,
  ReferralPartnersItemsInner,
  GetLicenceResponse,
  CompleteOnBoardingRequest,
  OnBoarding,
} from "../../landconnex-api-client"

interface ReferralPartner extends ReferralPartnersItemsInner {
  emailAddress: string
}

export enum TeamStatus {
  initial,
  loading,
  creatingCompany,
  ready,
  error,
}

interface TeamState {
  status: TeamStatus
  team: Team | undefined
  licencee: GetLicenceResponse | null
  teamMembers: TeamParticipant[]
  teamInvitations: TeamInvitation[]
  referralPartners: ReferralPartner[]
  currentReferralPartner: ReferralPartner | null
  logo: File | null

  clientSecretID: string | null
  setupIntentID: string | null
}

const initialState: TeamState = {
  status: TeamStatus.initial,
  team: undefined,
  licencee: null,
  teamMembers: [],
  teamInvitations: [],
  referralPartners: [],
  currentReferralPartner: null,
  logo: null,

  clientSecretID: null,
  setupIntentID: null,
}

export const teamSlice = createAppSlice({
  name: "team",
  initialState,
  reducers: create => ({
    startOnBoarding: create.asyncThunk(
      async (onBoarding: OnBoarding) => {
        const result = await teamsApi.startOnBoarding(onBoarding)
        return result.data
      },
      {
        pending: state => {
          state.status = TeamStatus.creatingCompany
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready
          state.clientSecretID = action.payload.clientSecretID
          state.setupIntentID = action.payload.setupIntentID
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
    completeOnBoarding: create.asyncThunk(
      async (request: CompleteOnBoardingRequest) => {
        await teamsApi.completeOnBoarding(request)
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: state => {
          state.status = TeamStatus.ready
          state.clientSecretID = null
          state.setupIntentID = null
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
    getLicenceDetails: create.asyncThunk(
      async () => {
        const result = await teamsApi.getLicencee()
        return result.data
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready
          state.licencee = action.payload
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
    getTeam: create.asyncThunk(
      async () => {
        const result = await teamsApi.getTeam()
        return result.data
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready

          if (!action.payload.primaryContact) {
            action.payload.primaryContact = {
              name: { firstName: "", lastName: "" },
            }
          }
          state.team = action.payload
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
    updateTeam: create.asyncThunk(
      async (team: Team) => {
        await teamsApi.putTeam(team)
        return team
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready
          state.team = action.payload
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
    uploadTeamLogo: create.asyncThunk<void, File>(
      async (file: File) => {
        await teamsApi.putTeamLogo(file)
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: state => {
          state.status = TeamStatus.ready
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
    getReferralPartners: create.asyncThunk(
      async () => {
        const result = await teamsApi.getReferralPartners()
        return result.data
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready
          state.referralPartners = (action.payload.items ||
            []) as ReferralPartner[]
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
    getReferralPartner: create.asyncThunk(
      async (emailAddress: string) => {
        const result = await teamsApi.getReferralPartner(emailAddress)
        return result.data as ReferralPartner
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready
          state.currentReferralPartner = action.payload
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
    addReferralPartner: create.asyncThunk(
      async (partner: ReferralPartner) => {
        await teamsApi.postReferralPartner(partner)
        return partner
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready
          state.referralPartners = [...state.referralPartners, action.payload]
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
    updateReferralPartner: create.asyncThunk(
      async ({
        emailAddress,
        details,
      }: {
        emailAddress: string
        details: PutMyDetailsRequest
      }) => {
        const result = await teamsApi.putReferralPartner(emailAddress, details)
        return result.data as ReferralPartner
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready
          state.referralPartners = state.referralPartners.map(partner =>
            partner.emailAddress === action.payload.emailAddress
              ? action.payload
              : partner,
          )
          state.currentReferralPartner = action.payload
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
    deleteReferralPartner: create.asyncThunk(
      async (emailAddress: string) => {
        await teamsApi.deleteReferralPartner(emailAddress)
        return emailAddress
      },
      {
        pending: state => {
          state.status = TeamStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = TeamStatus.ready
          state.referralPartners = state.referralPartners.filter(
            partner => partner.emailAddress !== action.payload,
          )
          if (state.currentReferralPartner?.emailAddress === action.payload) {
            state.currentReferralPartner = null
          }
        },
        rejected: state => {
          state.status = TeamStatus.error
        },
      },
    ),
  }),

  selectors: {
    selectTeam: settings => settings.team,
    selectTeamStatus: settings => settings.status,
    selectLicencee: settings => settings.licencee,
    selectTeamMembers: settings => settings.teamMembers,
    selectTeamInvitations: settings => settings.teamInvitations,
    selectReferralPartners: settings => settings.referralPartners,
    selectCurrentReferralPartner: settings => settings.currentReferralPartner,
    selectLogo: settings => settings.logo,
    selectClientSecretID: settings => settings.clientSecretID,
    selectSetupIntentID: settings => settings.setupIntentID,
  },
})

export const {
  getTeam,
  getLicenceDetails,
  updateTeam,
  uploadTeamLogo,
  getReferralPartners,
  getReferralPartner,
  addReferralPartner,
  updateReferralPartner,
  deleteReferralPartner,
  startOnBoarding,
  completeOnBoarding,
} = teamSlice.actions

export const {
  selectTeam,
  selectTeamStatus,
  selectLicencee,
  selectTeamMembers,
  selectTeamInvitations,
  selectReferralPartners,
  selectCurrentReferralPartner,
  selectLogo,
  selectClientSecretID,
  selectSetupIntentID,
} = teamSlice.selectors
