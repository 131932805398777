import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import Radio from "@mui/material/Radio"
import Button from "@mui/material/Button"
import RadioGroup from "@mui/material/RadioGroup"
import Typography from "@mui/material/Typography"
import { FC, useEffect, useState } from "react"
import {
  AgentAppointment,
  AgentAppointmentAppointmentTermTypeEnum,
} from "../../../landconnex-api-client"
import { OnAgentAppointmentChanged } from "../../../components/events"
import FormControl from "@mui/material/FormControl"
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormGroup,
} from "@mui/material"
import FormInputText from "../../../components/form-input-text"
import FormInputDatePicker from "../../../components/form-input-date-picker"

import CurrencyField from "../../../components/currency-field"
import { useParams } from "react-router-dom"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import AutoSave from "../../../components/auto-save"
import {
  selectAgentAppointment,
  updateAgentAppointment,
} from "../../../app/slices/agent-appointment-slice"

export interface AppointmentProps {
  agent: AgentAppointment
  onAgentChanged: OnAgentAppointmentChanged
}
const Appointment: FC = () => {
  const agentAppointment = useAppSelector(selectAgentAppointment)
  const params = useParams()
  const dispatch = useAppDispatch()
  const workspaceId = Number(params.workspaceId)

  const [localAppointment, setLocalAppointment] = useState<AgentAppointment>({
    ...agentAppointment,
  })

  const agentAppointmentFormMethods = useForm<AgentAppointment>({
    defaultValues: agentAppointment,
  })
  const { handleSubmit, control, reset, trigger } = agentAppointmentFormMethods

  useEffect(() => {
    setLocalAppointment(agentAppointment!)

    reset(agentAppointment)
    trigger()
  }, [agentAppointment])

  const onSubmit: SubmitHandler<AgentAppointment> = data => {
    dispatch(
      updateAgentAppointment({
        workspaceId: workspaceId,
        agentAppointment: data,
      }),
    )
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    const updatedAgentAppointment = { ...localAppointment }

    switch (name) {
      case "Sale":
        updatedAgentAppointment.appointmentIsSale = checked
        break
      case "Purchase":
        updatedAgentAppointment.appointmentIsPurchase = checked
        break
      case "Letting":
        updatedAgentAppointment.appointmentIsLetting = checked
        break
      case "Leasing":
        updatedAgentAppointment.appointmentIsLeasing = checked
        break
      case "Auction":
        updatedAgentAppointment.appointmentIsAuction = checked
        break
      case "Other":
        updatedAgentAppointment.appointmentIsOther = checked
        break
      default:
        break
    }

    setLocalAppointment(updatedAgentAppointment)
    onSubmit(updatedAgentAppointment)
  }

  return (
    <FormProvider {...agentAppointmentFormMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ border: "none" }}>
          <CardHeader title="Appointment of property agent" />
          <CardContent>
            <Box
              className="section-1"
              sx={{
                display: "flex",
                flexDirection: "column",

                marginBottom: 4,
                borderBottom: "1px solid silver",
                paddingBottom: 5,
              }}
            >
              <Typography variant="h6" component="span" className="roboto-bold">
                Section 1
              </Typography>
              <Box>
                <Typography variant="h6">Performance of Service</Typography>
                <Typography variant="body2">
                  Annexures detailing the performance of service may be attached
                  if required.
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="filled" fullWidth>
                    <Typography component="legend">Service</Typography>
                    <FormGroup sx={{ display: "inline-block" }}>
                      {[
                        { name: "Sale", field: "appointmentIsSale" },
                        { name: "Purchase", field: "appointmentIsPurchase" },
                        { name: "Letting", field: "appointmentIsLetting" },
                        { name: "Leasing", field: "appointmentIsLeasing" },
                        { name: "Auction", field: "appointmentIsAuction" },
                        { name: "Other", field: "appointmentIsOther" },
                      ].map(({ name, field }) => (
                        <FormControlLabel
                          key={field}
                          control={
                            <Checkbox
                              checked={
                                !!localAppointment[
                                  field as keyof AgentAppointment
                                ]
                              }
                              onChange={handleCheckboxChange}
                              name={name}
                            />
                          }
                          label={name}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>

                {agentAppointment!.appointmentIsOther ? (
                  <Grid item xs={6}>
                    <FormInputText
                      control={control}
                      name="appointmentIsOtherDescription"
                      label="Description of Other"
                      required
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Box>

            <Box
              className="section-2"
              sx={{
                display: "flex",
                flexDirection: "column",

                marginBottom: 4,
                borderBottom: "1px solid silver",
                paddingBottom: 5,
              }}
            >
              <Typography variant="h6" component="span" className="roboto-bold">
                Section 2
              </Typography>
              <Typography variant="h6">Term of Appointment</Typography>
              <Typography variant="body2">
                Sole and exclusive appointments: for sales of one or two
                residential properties, the term is negotiable and agent can be
                appointed or reappointed up to a maximum of 90 days per term.
                There are no limitations on the length of an appointment for
                anything other than a residential property sale.
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={
                      agentAppointment!.appointmentTermType
                        ? agentAppointment!.appointmentTermType
                        : AgentAppointmentAppointmentTermTypeEnum.Single
                    }
                    onChange={value => {
                      const u = { ...agentAppointment }
                      u.appointmentTermType = value.currentTarget
                        .value as AgentAppointmentAppointmentTermTypeEnum
                      onSubmit(u)
                    }}
                    row
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value={AgentAppointmentAppointmentTermTypeEnum.Single}
                      control={<Radio />}
                      label="Single Appointment"
                    />

                    <FormControlLabel
                      value={AgentAppointmentAppointmentTermTypeEnum.Continuing}
                      control={<Radio />}
                      label="Continuing Appointment"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormInputDatePicker
                    control={control}
                    label="Start"
                    name="appointmentTermStart"
                    required
                  />
                </Grid>
                {agentAppointment!.appointmentTermType ==
                AgentAppointmentAppointmentTermTypeEnum.Single ? (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormInputDatePicker
                      control={control}
                      label="End"
                      name="appointmentTermEnd"
                      required={
                        agentAppointment!.appointmentTermType ==
                        AgentAppointmentAppointmentTermTypeEnum.Single
                      }
                    />
                    <Box sx={{ width: "100px", marginTop: 1 }}>
                      <Button
                        color="secondary"
                        onClick={() => {
                          const updated = { ...agentAppointment }
                          updated.appointmentTermEnd = undefined
                          onSubmit(updated)
                        }}
                      >
                        No end date
                      </Button>
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
            </Box>

            <Box
              className="section-3"
              sx={{
                display: "flex",
                flexDirection: "column",

                borderBottom: "1px solid silver",
                paddingBottom: 2,
              }}
            >
              <Typography variant="h6" component="span" className="roboto-bold">
                Section 3
              </Typography>
              <Typography variant="h6">Price</Typography>
              <Typography variant="body2">
                State the price for which the property, land or business is to
                be sold or let. <br />
                <Typography component="span" sx={{ color: "#c20029" }}>
                  Note:{" "}
                </Typography>{" "}
                Bait advertising is an offence under the Australian Consumer
                Law.
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Controller
                    name="priceList"
                    defaultValue={agentAppointment!.priceList}
                    control={control}
                    render={({ field }) => (
                      <CurrencyField
                        onChange={field.onChange}
                        // {...field}
                        label="Price"
                        amount={agentAppointment!.priceList}
                        required
                      />
                    )}
                  ></Controller>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormInputText
                    control={control}
                    label="Marketing Range"
                    required
                    name="marketingRange"
                    key="marketingRange"
                  />
                </Grid>
              </Grid>
            </Box>

            <Box
              className="section-4"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                marginBottom: 4,
              }}
            >
              <Typography variant="h6" component="span" className="roboto-bold">
                Section 4
              </Typography>
              <Typography variant="h6">Instructions / Conditions</Typography>
              <Typography variant="body2">
                The client may list any condition, limitation or restriction on
                the performance of the service. <br />
                <Typography component="span" sx={{ color: "#c20029" }}>
                  Note:{" "}
                </Typography>
                Annexures detailing instructions/conditions may be attached if
                required.
              </Typography>
              <Grid item xs={12}>
                <FormInputText
                  control={control}
                  label="Instructions"
                  name="instructions"
                  multiline
                  minRows={5}
                />
              </Grid>
            </Box>

            <AutoSave
              onSubmit={onSubmit}
              defaultValues={agentAppointment}
            ></AutoSave>
          </CardContent>
        </Card>
      </form>
    </FormProvider>
  )
}

export default Appointment
