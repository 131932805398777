import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material"
import { FC, useState } from "react"
import { OnBoardingCompanyTypeEnum } from "../../landconnex-api-client"
import { useNavigate } from "react-router-dom"

interface SelectCompanyTypeProps {
  onComplete: (type: OnBoardingCompanyTypeEnum) => void
}

const SelectCompanyType: FC<SelectCompanyTypeProps> = ({ onComplete }) => {
  const [companyType, setCompanyType] =
    useState<OnBoardingCompanyTypeEnum | null>(null)
  const navigate = useNavigate()

  const handleSelect = (type: OnBoardingCompanyTypeEnum) => {
    setCompanyType(type)
  }

  const renderCard = (
    type: OnBoardingCompanyTypeEnum,
    title: string,
    imageUrl: string,
  ) => {
    const isSelected = companyType === type
    const isDimmed = companyType && companyType !== type

    return (
      <Card
        sx={{
          width: 300,
          my: 5,
          outline: isSelected ? "2px solid #c20029" : "none",
          boxShadow: isSelected ? "4px 4px 10px rgba(0,0,0,0.5)" : undefined,
          filter: isDimmed ? "saturate(0.4)" : "saturate(1)",
          transition: "all .2s ease",
        }}
      >
        <CardActionArea onClick={() => handleSelect(type)}>
          <CardMedia image={imageUrl} title={title} sx={{ height: 300 }} />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography variant="h6">{title}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" sx={{ mb: 3 }}>
        What type of company are you?
      </Typography>

      <Box display="flex" flexDirection="row" gap={5}>
        {renderCard(
          "realEstateAgency",
          "Real Estate Agent",
          "https://landconnex.com.au/assets/images/notification-man.png",
        )}
        {renderCard(
          "solicitor",
          "Solicitor",
          "https://landconnex.com.au/assets/images/data-entry.png",
        )}
      </Box>

      <Box sx={{ pt: 2 }} display="flex" gap={2}>
        <Box width={110}>
          <Button
            onClick={() => navigate("/workspaces")}
            size="large"
            color="inherit"
          >
            Cancel
          </Button>
        </Box>
        <Box width={110}>
          <Button
            size="large"
            disabled={!companyType}
            onClick={() => onComplete(companyType!)}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default SelectCompanyType
