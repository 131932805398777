import axios from "axios"
import { AxiosResponse } from "axios"
import { offersApi } from "../../api"
import { appConfig } from "../../constants"

import { Contract, OfferDetail, Participant } from "../../landconnex-api-client"
import { createAppSlice } from "../createAppSlice"
import { User } from "oidc-client-ts"
import { produce } from "immer"
export interface OffersState {
  status: OffersStatus
  loading: {
    offers: boolean
    offer: boolean
    offerParticipants: boolean
    pdf: boolean
  }
  error: {
    pdf: string | undefined
  }
  offers: Array<OfferDetail>
  offer: Contract | undefined
  offerParticipants: Array<Participant>
  offerPdfUrl: string | undefined
}
export enum OffersStatus {
  initial = "initial",
  pending = "pending",
  fulfilled = "fulfilled",
  rejected = "rejected",
}
export interface GetOfferDataProps {
  workspaceId: number
  offerId: number
}
export interface DeleteOfferDataProps {
  workspaceId: number
  offerId: number
}
export interface InviteBuyersDataProps {
  workspaceId: number
  offerId: number
}
export interface AddBuyerDataProps {
  workspaceId: number
  offerId: number
}
export interface DeleteBuyerDataProps {
  workspaceId: number
  offerId: number
  participantId: number
}
export interface GetOfferPdfDataProps {
  workspaceId: number
  offerId: number
}
export interface PutOfferParticipantDataProps {
  workspaceId: number
  offerId: number
  participant: Participant
}
export interface PostOfferParticipantDataProps {
  workspaceId: number
  offerId: number
  participant: Participant
}
export interface PostOfferDataProps {
  workspaceId: number
  offer: Contract
}
export interface PutOfferDataProps {
  workspaceId: number
  offer: Contract
}
export interface StartOfferWorkflowDataProps {
  workspaceId: number
  offerId: number
}
export interface SendForSigningProps {
  workspaceId: number
  offerId: number
}

export interface PutOfferParticipantResponse {
  workspaceId: number
  offerId: number
  participant: Participant
}

export interface PresentOfferToSellerProps {
  workspaceId: number
  offerId: number
}

const initialState: OffersState = {
  status: OffersStatus.initial,
  error: {
    pdf: undefined,
  },
  loading: {
    offers: false,
    offer: false,
    offerParticipants: false,
    pdf: false,
  },
  offers: [],
  offer: undefined,
  offerParticipants: [],
  offerPdfUrl: undefined,
}
export const offersSlice = createAppSlice({
  name: "offers",
  initialState,

  reducers: create => ({
    startOfferWorkflow: create.asyncThunk<number, StartOfferWorkflowDataProps>(
      async ({ workspaceId, offerId }) => {
        await offersApi.startOfferWorkflow(workspaceId, offerId)
        return workspaceId
      },
    ),
    getOfferParticipants: create.asyncThunk<
      Array<Participant>,
      GetOfferDataProps
    >(
      async ({ workspaceId, offerId }) => {
        const response = await offersApi.getOfferParticipants(
          workspaceId,
          offerId,
        )
        return response.data.items!
      },
      {
        fulfilled: (state, action) => {
          state.status = OffersStatus.fulfilled
          state.offerParticipants = action.payload
        },
      },
    ),
    getOffers: create.asyncThunk<Array<OfferDetail>, number>(
      async workspaceId => {
        const response = await offersApi.getOffers(workspaceId)
        return response.data.items!
      },
      {
        fulfilled: (state, action) => {
          state.status = OffersStatus.fulfilled
          state.offers = action.payload
        },
      },
    ),
    getOffer: create.asyncThunk<Contract, GetOfferDataProps>(
      async ({ workspaceId, offerId }) => {
        const response = await offersApi.getOffer(workspaceId, offerId)
        return response.data!
      },
      {
        fulfilled: (state, action) => {
          state.status = OffersStatus.fulfilled
          state.offer = action.payload
        },
      },
    ),
    deleteOffer: create.asyncThunk<void, DeleteOfferDataProps>(
      async ({ workspaceId, offerId }) => {
        await offersApi.deleteOffer(workspaceId, offerId)
      },
    ),
    inviteBuyers: create.asyncThunk<void, InviteBuyersDataProps>(
      async ({ workspaceId, offerId }) => {
        await offersApi.inviteBuyers(workspaceId, offerId)
      },
    ),
    addBuyer: create.asyncThunk<void, AddBuyerDataProps>(
      async ({ workspaceId, offerId }) => {
        await offersApi.postOfferParticipant(workspaceId, offerId, {
          role: "buyer",
        })
      },
    ),
    deleteOfferParticipant: create.asyncThunk<number, DeleteBuyerDataProps>(
      async ({ workspaceId, offerId, participantId }) => {
        await offersApi.deleteOfferParticipant(
          workspaceId,
          offerId,
          participantId,
        )
        return participantId
      },
      {
        fulfilled: (state, action) => {
          state.status = OffersStatus.fulfilled
          state.offerParticipants = state.offerParticipants.filter(
            p => p.id !== action.payload,
          )
        },
      },
    ),
    putOfferParticipant: create.asyncThunk<
      PutOfferParticipantResponse,
      PutOfferParticipantDataProps
    >(
      async ({ workspaceId, offerId, participant }) => {
        await offersApi.putOfferParticipant(
          workspaceId,
          offerId,
          participant.id!,
          participant,
        )
        return {
          workspaceId,
          offerId,
          participant,
        }
      },
      {
        fulfilled: (state, action) => {
          state.status = OffersStatus.fulfilled
          state.offers = state.offers.map<OfferDetail>(o => {
            if (o.offer.id === action.payload.offerId) {
              o.participants = produce(o.participants, participantsDraft => {
                participantsDraft.forEach(p => {
                  if (p.id === action.payload.participant.id) {
                    p = action.payload.participant
                  }
                })
              })
            }
            return o
          })
        },
      },
    ),
    postOfferParticipant: create.asyncThunk<
      Participant,
      PostOfferParticipantDataProps
    >(
      async ({ workspaceId, offerId, participant }) => {
        const result = await offersApi.postOfferParticipant(
          workspaceId,
          offerId,
          participant,
        )
        participant.id = result.data.id
        return participant
      },
      {
        fulfilled: (state, action) => {
          state.status = OffersStatus.fulfilled
          state.offerParticipants.push(action.payload)
        },
      },
    ),
    putOffer: create.asyncThunk<Contract, PutOfferDataProps>(
      async ({ workspaceId, offer }) => {
        const response = await offersApi.putOffer(workspaceId, offer.id!, offer)
        return response.data!
      },
      {
        fulfilled: (state, action) => {
          state.status = OffersStatus.fulfilled
          state.offer = action.payload
        },
      },
    ),
    presentOfferToSeller: create.asyncThunk<PresentOfferToSellerProps, PresentOfferToSellerProps>(
      async ({ workspaceId, offerId }) => {
        await offersApi.presentOfferToSeller(workspaceId, offerId)
        return {
          workspaceId,
          offerId,
        }
      },
    ),
    getOfferPdf: create.asyncThunk<string, GetOfferPdfDataProps>(
      async ({ workspaceId, offerId }) => {
        const oidcStorage = sessionStorage.getItem(
          `oidc.user:${appConfig.authority}:${appConfig.clientId}`,
        )
        const user = User.fromStorageString(oidcStorage!)
        let response: AxiosResponse<Blob>

        response = await axios.get(
          `${appConfig.workspacesUri}/workspaces/${workspaceId}/offers/${offerId}/pdf`,
          {
            headers: { Authorization: `Bearer ${user.access_token}` },
            responseType: "blob",
          },
        )

        const url = window.URL.createObjectURL(response.data)

        return url
      },
      {
        pending: state => {
          state.loading.pdf = true
          state.error.pdf = undefined
        },
        fulfilled: (state, action) => {
          state.loading.pdf = false
          state.offerPdfUrl = action.payload
        },
        rejected: (state, action) => {
          state.loading.pdf = false
          state.error.pdf = action.error.message
        },
      },
    ),
    postOffer: create.asyncThunk<Contract, PostOfferDataProps>(
      async ({ workspaceId, offer }) => {
        const response = await offersApi.postOffer(workspaceId, offer)
        const offerId = response.data.id!
        const getOfferResponse = await offersApi.getOffer(workspaceId, offerId)
        await offersSlice.actions.getOffers(workspaceId)
        return getOfferResponse.data!
      },

      {
        fulfilled: (state, action) => {
          state.status = OffersStatus.fulfilled
          state.offer = action.payload
        },
      },
    ),
    sendForSigning: create.asyncThunk<void, SendForSigningProps>(
      async ({ workspaceId, offerId }) => {
        await offersApi.sendForSigning(workspaceId, offerId)
      },
    ),
  }),
  selectors: {
    selectOfferParticipants: state => state.offerParticipants,
    selectOffers: state => state.offers,
    selectOffer: state => state.offer,
    selectOfferStatus: state => state.status,
    selectOfferLoading: state => state.loading,
    selectOfferPdfUrl: state => state.offerPdfUrl,
  },
})

export const {
  getOfferParticipants,
  getOfferPdf,
  getOffers,
  getOffer,
  deleteOffer,
  inviteBuyers,
  deleteOfferParticipant,
  putOfferParticipant,
  postOfferParticipant,
  putOffer,
  postOffer,
  startOfferWorkflow,
  presentOfferToSeller,
  sendForSigning,
} = offersSlice.actions
export const {
  selectOfferParticipants,
  selectOffers,
  selectOffer,
  selectOfferStatus,
  selectOfferLoading,
  selectOfferPdfUrl,
} = offersSlice.selectors
