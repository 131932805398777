import Container from "@mui/material/Container"
import { getPDF, saveName } from "./thunks"
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Paper,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import "./offer-draft-page.css"
import PDFViewer from "../../components/pdf-viewer"
import { useDebounceValue } from "usehooks-ts"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { sendForSigning, shareWithSeller } from "../workspace/thunks"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { useNavigate } from "react-router-dom"
import {
  deleteOffer,
  inviteBuyers,
  selectOffer,
  selectOfferLoading,
  selectOfferPdfUrl,
} from "../../app/slices/offers-slice"
import { selectWorkspace } from "../../app/slices/workspaces-slice"

const OfferDraftView: React.FC = () => {
  const dispatch = useAppDispatch()
  const offer = useAppSelector(selectOffer)
  const loading = useAppSelector(selectOfferLoading)
  const workspace = useAppSelector(selectWorkspace)
  const offerPdfUrl = useAppSelector(selectOfferPdfUrl)
  const nameUpdatedDebounce = useDebounceValue(offer!.id, 1000)
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // const [validationErrors, _] = useState<{ message: string; step: number }[]>(
  //   []
  // );

  const handleMenuItemClick = (action: any) => {
    if (action === "inviteBuyers") {
      handleInviteBuyers(offer!.id!)
    } else if (action === "shareWithSeller") {
      handleShareWithSeller(offer!.id!)
    } else if (action === "sendToBuyer") {
      handleSendForSigning(offer!.id!)
    } else if (action === "cancelOffer") {
      handleCancelOffer(offer!.id!)
    }
    handleClose()
  }

  useEffect(() => {
    dispatch(
      getPDF({
        workspaceId: workspace!.workspace!.id!,
        offerId: offer!.id!,
      }),
    )
  }, [workspace!.workspace!.id!, offer!.id!])

  useEffect(() => {
    if (loading.pdf) {
      return
    }
    saveName({
      workspaceId: workspace!.workspace!.id!,
      offer: offer!,
    })
  }, [nameUpdatedDebounce])

  function handleSendForSigning(offerId: number): void {
    dispatch(
      sendForSigning({
        workspaceId: workspace!.workspace!.id!,
        offerId: offerId,
      }),
    )
  }

  function handleInviteBuyers(offerId: number): void {
    dispatch(
      inviteBuyers({
        workspaceId: workspace!.workspace!.id!,
        offerId: offerId,
      }),
    )
  }

  function handleShareWithSeller(offerId: number): void {
    dispatch(
      shareWithSeller({
        workspaceId: workspace!.workspace!.id!,
        offerId: offerId,
      }),
    )
  }
  function handleCancelOffer(offerId: number): void {
    dispatch(
      deleteOffer({
        workspaceId: workspace!.workspace!.id!,
        offerId: offerId,
      }),
    )
  }

  return (
    <Container style={{ marginTop: 30 }}>
      {loading.pdf ? (
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <CircularProgress size={100}></CircularProgress>
        </Grid>
      ) : null}

      {loading.pdf ? (
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 2,
          }}
        >
          {/* {validationErrors.length == 0 && ( */}
          <Box
            sx={{
              display: "flex",
              alignSelf: "center",
              gap: 2,
            }}
          >
            {workspace!.workspace?.role === "sellerAgent" && (
              <>
                <Box
                  sx={{
                    width: 120,
                  }}
                >
                  <Button
                    endIcon={<ArrowDropDownIcon />}
                    onClick={e => {
                      handleClick(e)
                    }}
                  >
                    Actions
                  </Button>
                  <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem
                      onClick={() => handleMenuItemClick("inviteBuyers")}
                    >
                      Invite Buyers to Workspace
                    </MenuItem>
                    {/* <MenuItem
                      onClick={() => handleMenuItemClick("sendToBuyer")}
                    >
                      Send to Buyer
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleMenuItemClick("sendToSeller")}
                    >
                      Send to Seller
                    </MenuItem> */}
                    <MenuItem
                      onClick={() => handleMenuItemClick("shareWithSeller")}
                    >
                      Share Offer to Seller
                    </MenuItem>

                    <MenuItem
                      onClick={() => handleMenuItemClick("cancelOffer")}
                    >
                      Cancel Offer
                    </MenuItem>
                  </Menu>
                </Box>
                <Box
                  sx={{
                    width: 100,
                  }}
                >
                  <Button
                    color="success"
                    onClick={() => {
                      navigate(`/workspaces/${workspace!.workspace!.id}`)
                      window.location.reload()
                    }}
                  >
                    Complete
                  </Button>
                </Box>
              </>
            )}
          </Box>
          {/* )} */}
          <Paper square sx={{ alignSelf: "center" }}>
            <PDFViewer url={offerPdfUrl!} />
          </Paper>
        </Grid>
      ) : null}
    </Container>
  )
}

export default OfferDraftView
