import {
  IconButton,
  ListItem,
  ListItemText,
  Theme,
  SxProps,
  Typography,
  Stack,
} from "@mui/material"

import { Participant } from "../landconnex-api-client"
import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import { Fragment } from "react"
import { fullName } from "../helpers/helpers"
import RoleChip from "./role-chip"

export interface ParticipantListItemProps {
  participant: Participant
  sx?: SxProps<Theme>
}
const ParticipantListItem: React.FC<ParticipantListItemProps> = ({
  participant,
  sx,
}) => {
  return (
    <ListItem
      sx={{
        pl: 1,
        pr: 0,
        ...sx,
      }}
      key={participant.id}
      secondaryAction={
        <Fragment>
          <RoleChip role={participant.role!} />
          <IconButton
            onClick={() => {
              window.open(`tel:${participant.phone}`, "_blank")
            }}
          >
            <PhoneIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open(`mailto:${participant.email}`, "_blank")
            }}
          >
            <EmailIcon />
          </IconButton>
        </Fragment>
      }
    >
      {/* <ListItemAvatar>
        <Avatar title="Seller Agent">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="64"
            height="64"
            fill="none"
          >
            <path
              d="M32 8 L12 24 L16 24 L16 48 H28 V36 H36 V48 H48 V24 H52 L32 8 Z"
              fill="#FEFDF4"
            />
            <rect x="20" y="24" width="8" height="8" fill="#FFF" />
            <rect x="36" y="24" width="8" height="8" fill="#FFF" />

            <circle cx="48" cy="48" r="6" fill="#FFC107" />
            <path d="M46 54 C46 50 50 50 50 54 H46 Z" fill="#FFC107" />
          </svg>
        </Avatar>
      </ListItemAvatar> */}

      <ListItemText
        
        primary={<Fragment>{fullName(participant)}</Fragment>}
        secondary={
          <Stack component="span" direction="column" gap={0}>
            <Typography component="span" variant="caption">
              {participant.phone}
            </Typography>
            <Typography component="span" variant="caption">
              {participant.email}
            </Typography>
          </Stack>
        }
      />
    </ListItem>
  )
}

// function getRoleDescription(role: WorkspaceRoleEnum): string {
//   switch (role) {
//     case WorkspaceRoleEnum.Seller:
//       return "Seller"
//     case WorkspaceRoleEnum.SellerAgent:
//       return "Seller Agent"
//     case WorkspaceRoleEnum.SellerSolicitor:
//       return "Seller Solicitor"
//     default:
//       return "Participant"
//   }
// }

export default ParticipantListItem
