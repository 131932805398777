import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React, { ChangeEvent } from "react"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import FormWrapper from "../../agent-appointment/components/form-wrapper"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { putContract, selectContract } from "../../../app/slices/contract-slice"
import { Contract } from "../../../landconnex-api-client"
import { produce } from "immer"

const SafetyAlarmsAndSwitches: React.FC = ({}) => {
  const dispatch = useAppDispatch()

  const contract = useAppSelector(selectContract)
  const workspace = useAppSelector(selectWorkspace)

  const disableField = ["buyer", "buyerSolicitor"].includes(
    workspace!.workspace!.role!,
  )

  function onChanged(event: ChangeEvent<HTMLInputElement>): void {
    let updatedContract: Contract | undefined = undefined

    switch (event.currentTarget.name) {
      case "hasSafetySwitches":
        updatedContract = produce(contract!, draft => {
          draft.hasSafetySwitches = event.currentTarget.value == "true"
        })
        break
      case "hasSmokeAlarms":
        updatedContract = produce(contract!, draft => {
          draft.hasSmokeAlarms = event.currentTarget.value == "true"
        })
        break
    }
    if (updatedContract) {
      dispatch(
        putContract({
          workspaceId: workspace!.workspace!.id,
          contract: updatedContract,
        }),
      )
    }
  }

  return (
    <FormWrapper title="Safety Switches and Alarms">
      <Grid container spacing={2}>
        <Grid item sm={12} lg={12}>
          <Typography variant="body1">
            Does the seller give notice to the buyer that an approved safety
            switch for the general purpose socket outlets as been installed?
          </Typography>
        </Grid>
        <Grid item sm={12} lg={12}>
          <RadioGroup
            value={
              contract!.hasSafetySwitches ? contract!.hasSafetySwitches : false
            }
            id="hasSafetySwitches"
            name="hasSafetySwitches"
            onChange={onChanged}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Yes"
              disabled={disableField}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="No"
              disabled={disableField}
            />
          </RadioGroup>
        </Grid>

        <React.Fragment>
          <Grid item sm={12} lg={12}>
            <Typography variant="body1">
              Does the seller give notice to the buyer that smoke alarms
              complying with the smoke alarm requirement provision have been
              installed?
            </Typography>
          </Grid>
          <Grid item sm={12} lg={12}>
            <RadioGroup
              value={
                contract!.hasSmokeAlarms ? contract!.hasSmokeAlarms : false
              }
              id="hasSmokeAlarms"
              onChange={onChanged}
              name="hasSmokeAlarms"
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Yes"
                disabled={disableField}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="No"
                disabled={disableField}
              />
            </RadioGroup>
          </Grid>
        </React.Fragment>
      </Grid>
    </FormWrapper>
  )
}

export default SafetyAlarmsAndSwitches
