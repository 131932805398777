import Grid from "@mui/material/Grid"
import { FC, Fragment, useEffect, useRef, useState } from "react"
import PDFViewer from "../../../components/pdf-viewer"
import CircularProgress from "@mui/material/CircularProgress"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"

import {
  completeContractDrafting,
  getPdf,
  selectLoading,
  selectPdfUrl,
} from "../../../app/slices/contract-slice"
import {
  selectWorkflow,
  selectWorkspace,
} from "../../../app/slices/workspaces-slice"
import { WorkflowOffersInner } from "../../../landconnex-api-client/api"
import StartOfferWorkflow from "../../../components/start-offer-workflow"

const Review: FC = () => {
  const workspace = useAppSelector(selectWorkspace)
  const workflow = useAppSelector(selectWorkflow)
  const loading = useAppSelector(selectLoading)
  const pdfUrl = useAppSelector(selectPdfUrl)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const offerId = Number(params.offerId)
  const pdfViewerRef = useRef<HTMLDivElement>(null)
  const [_canStartWorkflow, setCanStartWorkflow] = useState(false)
  const [offerWorkflow, setOfferWorkflow] = useState<
    WorkflowOffersInner | undefined
  >(undefined)
  const handleComplete = async () => {
    try {
      const success = await dispatch(
        completeContractDrafting(workspace!.workspace!.id),
      )
      if (success) {
        setTimeout(() => {
          navigate(`/workspaces/${workspace!.workspace!.id}`)
        }, 1500)
      } else {
        console.error("Failed to complete preparation")
      }
    } catch (error) {
      console.error("Error occurred while completing preparation:", error)
    }
  }

  useEffect(() => {
    if (workflow?.offers) {
      setOfferWorkflow(workflow?.offers.find(o => o.offerId === offerId))
    }
    if (workflow?.offers === null || workflow?.offers.length === 0) {
      setCanStartWorkflow(false)
    } else if (
      workflow?.offers.find(o => o.offerId === offerId) === undefined
    ) {
      setCanStartWorkflow(true)
    }
  }, [dispatch, offerId, workspace])
  useEffect(() => {
    dispatch(getPdf(workspace!.workspace!.id))
  }, [dispatch])

  const scrollToTop = () => {
    pdfViewerRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Fragment>
      <pre>{JSON.stringify(offerWorkflow, null, 2)}</pre>
      {loading.pdf ? (
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <CircularProgress size={100}></CircularProgress>
        </Grid>
      ) : null}
      {!loading.pdf ? (
        <Fragment>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
          >
            {offerId &&
              !offerWorkflow &&
              workflow?.state === "acceptingOffers" &&
              workspace?.workspace?.role == "sellerAgent" && (
                <StartOfferWorkflow workspaceId={workspace!.workspace!.id} offerId={offerId} />
              )}

            {workflow?.subState === "contractPreparationPreparing" ? (
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    margin: "15px 0",
                    boxShadow: "0 1px 15px 0 rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <CardHeader title="Complete Preparation" />
                  <CardContent>
                    <Typography mb={2}>
                      When you have completed preparing the contract and
                      contract disclosures. Notify the workspace by completing
                      this task. Offers to buyers cannot be sent until you
                      finish this activity.
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "center", pb: 3 }}>
                    <Box sx={{ width: 120, margin: "0 auto 10px auto" }}>
                      <Button
                        size="large"
                        color="success"
                        onClick={handleComplete}
                      >
                        Complete
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <div ref={pdfViewerRef}>
                <PDFViewer url={pdfUrl!} />
              </div>
            </Grid>
          </Grid>

          <Box
            sx={{
              margin: "0 auto",
              width: "160px",
            }}
          >
            <Button onClick={scrollToTop} color="secondary">
              Scroll to Top
            </Button>
          </Box>
        </Fragment>
      ) : null}
    </Fragment>
  )
}

export default Review
