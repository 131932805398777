import { FC, Fragment, useState } from "react"
import {
  Link,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Box,
  Grid2,
  Slider,
  Button,
  Stack,
  useMediaQuery,
  useTheme,
  Card,
  CardHeader,
  CardContent,
  Collapse,
  IconButton,
  Divider,
  Chip,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { formatCurrency, formatDate, fullName } from "../../helpers/helpers"
import WorkspaceCard, {
  WorkspaceCardContent,
  WorkspaceCardHeader,
} from "../workspace-card"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  presentOfferToSeller,
  selectOfferParticipants,
  selectOffers,
  sendForSigning,
  startOfferWorkflow,
} from "../../app/slices/offers-slice"
import {
  selectWorkflow,
  selectWorkspace,
} from "../../app/slices/workspaces-slice"
import OfferStatusDisplay from "./offer-status-display"
import ParticipantListItem from "../participant-list-item"

import { ParticipantRoleEnum, Workflow } from "../../landconnex-api-client"
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone"
import DoneIcon from "@mui/icons-material/Done"
import ShareIcon from "@mui/icons-material/Share"

export interface OfferCardProps {
  offerId: number
}
const OfferCard: FC<OfferCardProps> = ({ offerId }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const workspace = useAppSelector(selectWorkspace)

  // const isLarge = useMediaQuery(theme.breakpoints.up("md"))

  const offers = useAppSelector(selectOffers)
  const offer = offers.find(offer => offer.offer.id === offerId)
  const workflow = useAppSelector(selectWorkflow)
  const offerWorkflow = workflow?.offers.find(
    workflow => workflow.offerId === offerId,
  )
  const participants = useAppSelector(selectOfferParticipants)
  const marks = [
    {
      value: 0,
      label: "Created",
    },
    {
      value: 1,
      label: "Awaiting Buyer Signing",
    },
    {
      value: 2,
      label: "Buyer Signed",
    },
    {
      value: 3,
      label: "Awaiting Seller Signing",
    },
    {
      value: 4,
      label: "Contract Signed",
    },
  ]

  const concatenatedBuyerNames = offer?.participants
    ?.map(participant => participant.firstName + " " + participant.lastName)
    .join(", ")

  const handleShareWithSeller = (id: number): void => {
    dispatch(
      presentOfferToSeller({
        workspaceId: workspace?.workspace?.id!,
        offerId: id,
      }),
    )
  }

  const OfferWorkflowControls = () => {
    return (
      <Fragment>

        {workspace?.workspace?.role == "sellerAgent" &&
        !offerWorkflow?.sharedWithSeller! ? (
          <Fragment>
            <Grid2 size={12}>
              <Button
                onClick={() => handleShareWithSeller(offerId)}
                startIcon={<ShareIcon />}
              >
                Share with Seller
              </Button>
            </Grid2>
          </Fragment>
        ) : (
          <Chip label="Shared with Seller" />
        )}
        <Grid2 size={12}>
          <Typography variant="h6" color="#666" fontWeight="bold">
            Contract Signing Status
          </Typography>
          <Fragment>
            {offerSigning(offerId, workflow, "buyer")}
            <Divider />
            {offerSigning(offerId, workflow, "seller")}
          </Fragment>
        </Grid2>
      </Fragment>
    )
  }
  // const smallOrMedium = () => {
  //   return <></>
  // }

  const offerSigning = (
    offerId: number,
    workflow: Workflow | undefined,
    role: ParticipantRoleEnum,
  ) => {
    const handleSendForSigning = () => {
      dispatch(
        sendForSigning({
          workspaceId: workspace?.workspace?.id!,
          offerId: offerId,
        }),
      )
    }
    let label
    let actionDisabled = true
    let showDoneIcon = false
    const offerWorkflow = workflow?.offers.find(
      offer => offer.offerId === offerId,
    )
    if (!offerWorkflow) {
      label = "Not Started"
    } else if (role == "buyer") {
      switch (offerWorkflow!.state) {
        case "OfferCreated":
          label = "Not Started"
          break
        case "OfferBuyerSigningSent":
          label = "Awaiting Buyer Signing"
          break
        case "OfferBuyerSigningDeclined":
          label = "Buyer Declined"
          break
        default:
          label = "Buyer Signed"
          showDoneIcon = true
          break
      }
    } else {
      switch (offerWorkflow!.state) {
        case "OfferCreated":
        case "OfferBuyerSigningSent":
        case "OfferBuyerSigningDeclined":
          label = "Awaiting Buyer Signing"
          break
        case "OfferBuyerSigningCompleted":
          label = "Ready to Sign"
          break
        case "OfferSellerSigningSent":
          label = "Awaiting Seller Signing"
          break
        case "OfferSellerSigningDeclined":
          label = "Seller Declined"
          break
        default:
          label = "Seller Signed"
          showDoneIcon = true
          break
      }
    }

    

    if (
      role == "buyer" &&
      (workspace?.workspace?.role == "buyer" ||
        workspace?.workspace?.role == "sellerAgent") &&
      offerWorkflow?.state == "OfferCreated"
    ) {
      actionDisabled = false
    }

    if (
      role == "seller" &&
      (workspace?.workspace?.role == "seller" ||
        workspace?.workspace?.role == "sellerAgent") &&
      offerWorkflow?.state == "OfferBuyerSigningCompleted"
    ) {
      actionDisabled = false
    }

    return (
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
          spacing={1}
        >
          <Stack direction="column">
            <Typography variant="body1" color="#666" fontWeight="bold">
              {role == "buyer" ? "Buyer Signing" : "Seller Signing"}
            </Typography>
            <Typography variant="caption" color="#666">
              {label}
            </Typography>
          </Stack>
          {showDoneIcon && <DoneIcon />}
          <Button
            sx={{
              width: "100px",
            }}
            startIcon={<SendTwoToneIcon />}
            disabled={actionDisabled}
            onClick={() => handleSendForSigning()}
          >
            Sign
          </Button>
        </Stack>
      </Box>
    )
  }
  const large = () => {
    return (
      <Grid2 container sx={{ bgcolor: "white" }}>
        <Grid2 size={6} sx={{ borderRight: "1px solid #ccc" }}>
          <List>
            {offer?.participants?.map(participant => (
              <ParticipantListItem participant={participant} />
            ))}
          </List>
        </Grid2>
        <Grid2 container size={6} sx={{ padding: 1 }}>
          <Grid2 size={6}>
            <Typography variant="caption" color="#666" fontWeight="bold">
              Settlement Date
            </Typography>
            <Typography variant="h6" color="#666" fontWeight="bold">
              {formatDate(offer?.offer.settlementDate)}
            </Typography>
          </Grid2>
          <Grid2 size={6}>
            <Typography
              variant="caption"
              color="#666"
              fontWeight="bold"
              sx={{ textAlign: "right", display: "block" }}
            >
              Offer Amount
            </Typography>
            <Typography
              variant="h5"
              color="#666"
              fontWeight="bold"
              sx={{ textAlign: "right", display: "block" }}
            >
              {formatCurrency(offer?.offer.purchasePrice)}
            </Typography>
          </Grid2>
          {offerWorkflow === undefined ? (
            <Grid2 size={12}>
              <Box sx={{ border: "1px solid #ccc", borderRadius: 1, p: 1 }}>
                <Typography variant="h6" color="#666" fontWeight="bold">
                  Offer not started
                </Typography>
                <Typography variant="body1" color="#666">
                  This offer has not been started. Until it is started, you
                  cannot:
                  <ul>
                    <li>share the offer with the seller or buyer</li>
                    <li>send the offer for signing</li>
                  </ul>
                </Typography>
                <Button
                  variant="contained"
                  onClick={() =>
                    dispatch(
                      startOfferWorkflow({
                        workspaceId: workspace?.workspace?.id!,
                        offerId: offerId,
                      }),
                    )
                  }
                >
                  Start Offer
                </Button>
              </Box>
            </Grid2>
          ) : (
            <OfferWorkflowControls />
          )}
        </Grid2>
      </Grid2>
    )
  }

  if (isSmall || isMedium) {
    const [expanded, setExpanded] = useState(false)
    return (
      <Card>
        <CardHeader
          title={
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Typography variant="h6" color="#666" fontWeight="bold">
                {concatenatedBuyerNames}
              </Typography>
              <IconButton onClick={() => setExpanded(!expanded)}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Stack>
          }
          subheader={
            <Typography variant="caption">
              <OfferStatusDisplay state={offerWorkflow?.state} />
            </Typography>
          }
        />
        <CardContent sx={{ paddingTop: 0 }}>
          <Grid2 container>
            <Grid2 size={8}>
              <Typography variant="h5" color="#666" fontWeight="bold">
                {formatCurrency(offer?.offer.purchasePrice)}
              </Typography>
              <Typography variant="caption">Offer Amount</Typography>
            </Grid2>
            <Grid2 size={4}>
              <Typography variant="h6" color="#666" fontWeight="bold">
                {formatDate(offer?.offer.settlementDate)}
              </Typography>
              <Typography variant="caption">Settlement Date</Typography>
            </Grid2>
          </Grid2>
          {/* <OfferTimeline offer={offer!} offerStatus={offerStatus} /> */}

          <Collapse in={expanded}>
            <Fragment>
              <Box
                sx={{
                  paddingTop: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Link href={`/offer/${offerId}/contract`}>View Contract</Link>
              </Box>
              <List>
                {offer?.participants?.map(participant => (
                  <ParticipantListItem participant={participant} />
                ))}
              </List>
            </Fragment>
          </Collapse>
          {/* <CardActionArea>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Button>View Contract</Button>
              <Button variant="contained">Start Signing</Button>
            </Stack>
          </CardActionArea> */}
        </CardContent>
      </Card>
    )
  }
  return large()
  return (
    <Grid2 container sx={{ p: 1 }}>
      <Grid2 container size={12}>
        <Grid2 size={12} sx={{ my: 2 }}>
          <Box display={"flex"} flexDirection={"row"}>
            <Slider
              sx={{
                ".MuiSlider-thumb": {
                  bgcolor: "primary.main",
                },
                ".MuiSlider-rail": {
                  bgcolor: "primary.light",
                },
                ".MuiSlider-track": {
                  bgcolor: "primary.main",
                },
                ".MuiSlider-mark": {
                  bgcolor: "primary.light",
                  width: 8,
                  height: 8,
                  borderRadius: 4,
                },
                width: "100%",
                maxWidth: 600,
                mx: "auto",
              }}
              marks={marks}
              step={0}
              min={0}
              max={4}
              disabled
            />
          </Box>
        </Grid2>
      </Grid2>
      <Grid2 size={12}>
        <WorkspaceCard
          sx={{ padding: 0, border: "none", maxWidth: 500, mx: "auto" }}
        >
          <WorkspaceCardHeader title="Offer Participants" />
          <WorkspaceCardContent sx={{ p: 0 }}>
            <List sx={{ p: 0, paddingLeft: 2 }}>
              {participants?.map((participant, index) => (
                <ListItem key={index} sx={{ p: 0 }}>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "primary.light" }}>
                      {participant.role}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontWeight: "bold" }}>
                        {fullName(participant)}
                      </Typography>
                    }
                    secondary={
                      <Stack component={"span"}>
                        <Link
                          href={`tel:${participant.phone}`}
                          sx={{
                            textDecoration: "none",
                          }}
                        >
                          {participant.phone}
                        </Link>{" "}
                        {""}
                        <Link
                          href={`mailto:${participant.email}`}
                          sx={{
                            textDecoration: "none",
                          }}
                        >
                          {participant.email}
                        </Link>
                      </Stack>
                    }
                  ></ListItemText>
                </ListItem>
              ))}
            </List>
          </WorkspaceCardContent>
        </WorkspaceCard>
        <Box sx={{ justifySelf: "center", my: 3 }}>
          <Button
            onClick={() => handleShareWithSeller(offerId)}
            color="inherit"
            sx={{ width: 170 }}
          >
            Share with Seller
          </Button>
        </Box>
      </Grid2>
    </Grid2>
  )
}

export default OfferCard
