import {
  Fab,
  Box,
  OutlinedInput,
  InputAdornment,
  Grid,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material"
import { Fragment, useState } from "react"
import { useAppSelector } from "../../../app/hooks"
import { selectWorkspaces } from "../../../app/slices/workspaces-slice"
import WorkspaceSummary from "./workspace-sumary"
import { Search } from "@mui/icons-material"
import SearchIcon from "@mui/icons-material/Search"

const WorkspacesSmallMedium = () => {
  const [searchQuery, setSearchQuery] = useState("")
  const workspaces = useAppSelector(selectWorkspaces)
  const filteredWorkspaces = workspaces.filter(workspace =>
    workspace.workspace.property?.streetAddress1
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase()),
  )

  const workspaceData = searchQuery ? filteredWorkspaces : workspaces

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  return (
    <Fragment>
      <Box
        sx={{
          width: { xs: "100%", md: "500px" },
          mb: 2,
          justifySelf: "center",
        }}
      >
        <OutlinedInput
          placeholder="Search by anything..."
          value={searchQuery}
          onChange={handleSearchChange}
          autoFocus
          sx={{
            width: "100%",
            height: "auto",
            borderRadius: "5px",
            backgroundColor: "#fff",
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </Box>
      <Box sx={{ position: "relative", overflow: "hidden" }}>
        {workspaceData.length > 0 ? (
          <Grid container spacing={2}>
            {workspaceData.map(workspace => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                key={`ws-${workspace.workspace.id}`}
              >
                <WorkspaceSummary
                  workspaceSummary={workspace!.workspace!}
                  workflow={workspace.workflow}
                  isCardView={true}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box
            sx={{
              maxWidth: 600,
              mx: "auto",
            }}
          >
            <Card
              sx={{
                padding: "15px",
                height: 300,
                textAlign: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <CardHeader title={workspaces.length > 0 && `No results found`} />
              <CardContent>
                {workspaces.length > 0 ? (
                  <Typography variant="body1" color="textSecondary">
                    Please try a different search query.
                  </Typography>
                ) : (
                  <CircularProgress size="100px" />
                )}
              </CardContent>
            </Card>
          </Box>
        )}

        <Fab
          color="primary"
          aria-label="search"
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
        >
          <Search />
        </Fab>
      </Box>
    </Fragment>
  )
}

export default WorkspacesSmallMedium
