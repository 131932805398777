import { FC, useState } from "react"
import {
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  Box,
  Typography,
  Button,
  CardHeader,
  Card,
  CardContent,
  CardActions,
} from "@mui/material"
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import KonveiModal from "../../../components/modal/konvei-modal"
import { TeamInvitation } from "../../../landconnex-api-client"

interface PendingInvitationsProps {
  teamInvitations: TeamInvitation[]
  handleAddInvite: (email: string) => void
  handleDeleteInvite: (invitationId: number) => void
}

const PendingInvitations: FC<PendingInvitationsProps> = ({
  teamInvitations,
  handleAddInvite,
  handleDeleteInvite,
}) => {
  const [newInvite, setNewInvite] = useState<string>("")
  const [deleteEmail, setDeleteEmail] = useState<number>(0)
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const addInvite = () => {
    handleAddInvite(newInvite)
    setNewInvite("")
  }

  const openDeleteModal = (invitationId: number) => {
    setDeleteEmail(invitationId)
    setModalOpen(true)
  }

  const closeDeleteModal = () => {
    setDeleteEmail(0)
    setModalOpen(false)
  }

  const confirmDelete = () => {
    handleDeleteInvite(deleteEmail)
    closeDeleteModal()
  }

  const renderDeleteConfirmation = () => {
    return (
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Confirm Deletion
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Are you sure you want to delete invitation for {deleteEmail}?
        </Typography>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 2 }}
        >
          <Button onClick={closeDeleteModal}>Cancel</Button>
          <Button onClick={confirmDelete}>Delete</Button>
        </Box>
      </Box>
    )
  }

  return (
    <Card>
      <CardHeader title="Pending Invitations" />
      <CardContent>
        <List sx={{ position: "relative", zIndex: 1 }}>
          {teamInvitations.map((email, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => openDeleteModal(email.id)}
                >
                  <DeleteForeverOutlinedIcon sx={{ color: "#8F1219" }} />
                </IconButton>
              }
            >
              <ListItemText primary={email.emailAddress} />
            </ListItem>
          ))}
        </List>
        <TextField
          label="Provide email address to send an invitation"
          variant="filled"
          fullWidth
          value={newInvite}
          onChange={e => setNewInvite(e.target.value)}
          sx={{ mt: 2 }}
        />

        <KonveiModal
          open={modalOpen}
          onClose={closeDeleteModal}
          children={renderDeleteConfirmation()}
        />
      </CardContent>
      <CardActions>
        <Box sx={{ mx: "auto", width: 100 }}>
          <Button onClick={addInvite} color="success">
            Invite
          </Button>
        </Box>
      </CardActions>
    </Card>
  )
}

export default PendingInvitations
