import { createAppSlice } from "../createAppSlice"
import { reportsApi } from "../../api"
import { CommissionType, FinancialStatistic } from "../../landconnex-api-client"

export enum ReportsStatus {
  initial = "initial",
  loading = "loading",
  ready = "ready",
}

export interface ReportsState {
  status: ReportsStatus
  reports: FinancialStatistic[]
  gcRevenue: number
  totalListingsCount: number
  totalListingsValue: number
  totalSettlementsValueThisWeek: number
  totalGCRevenueThisWeek: number
  agentStatistics: AgentStatistic[]
}

export interface AgentStatistic {
    sub: string
    agentName: string
    gcRevenue: number
    totalListingsCount: number
    totalListingsValue: number
    totalSettlementsValueThisWeek: number
    totalGCRevenueThisWeek: number
  }


const initialState: ReportsState = {
  status: ReportsStatus.initial,
  reports: [],
  gcRevenue: 0,
  totalListingsCount: 0,
  totalListingsValue: 0,
  totalSettlementsValueThisWeek: 0,
  totalGCRevenueThisWeek: 0,
  agentStatistics: [],
}

export const reportsSlice = createAppSlice({
  name: "reports",
  initialState,
  reducers: create => ({
    getDashboard: create.asyncThunk(
      async () => {
        const response = await reportsApi.getDashboard()
        return response.data!
      },
      {
        pending: state => {
          state.status = ReportsStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = ReportsStatus.ready
          state.reports = action.payload.financialStatistics!

          // Reset state values before calculating new totals
          state.gcRevenue = 0
          state.totalListingsCount = 0
          state.totalListingsValue = 0
          state.totalSettlementsValueThisWeek = 0
          state.totalGCRevenueThisWeek = 0

          // Get current week date range
          const now = new Date()
          const startOfWeek = new Date(now)
          startOfWeek.setDate(now.getDate() - now.getDay()) // Start of week (Sunday)
          startOfWeek.setHours(0, 0, 0, 0)
          
          const endOfWeek = new Date(now) 
          endOfWeek.setDate(now.getDate() + (6 - now.getDay())) // End of week (Saturday)
          endOfWeek.setHours(23, 59, 59, 999)

          action.payload.financialStatistics!.forEach(report => {
            let agent = state.agentStatistics.find(agent => agent.sub === report.agentSub)
            if (!agent) {
                agent = {
                    sub: report.agentSub,
                    gcRevenue: 0,
                    totalListingsCount: 0,
                    totalListingsValue: 0,
                    totalSettlementsValueThisWeek: 0,
                    totalGCRevenueThisWeek: 0,
                    agentName: report.agentName,
                }
                agent.totalListingsCount++
                agent.totalListingsValue += report.listingPrice
                agent.totalSettlementsValueThisWeek += report.listingPrice
                const settlementDate = new Date(report.settlementDate!)
                if (settlementDate >= startOfWeek && settlementDate <= endOfWeek) {
                    agent.totalSettlementsValueThisWeek += report.listingPrice
                }
                if (report.commissionType === CommissionType.Fixed) {
                    agent.gcRevenue += report.commission
                } else {
                    agent.gcRevenue +=
                    report.listingPrice * (report.commission / 100 / 100)
                }
                state.agentStatistics.push(agent)
            } else {
                agent.totalListingsCount++
                agent.totalListingsValue += report.listingPrice
                const settlementDate = new Date(report.settlementDate!)
                if (settlementDate >= startOfWeek && settlementDate <= endOfWeek) {
                    agent.totalSettlementsValueThisWeek += report.listingPrice
                }
                if (report.commissionType === CommissionType.Fixed) {
                    agent.gcRevenue += report.commission
                } else {
                    agent.gcRevenue +=
                    report.listingPrice * (report.commission / 100 / 100)
                }
            }
            
            state.totalListingsCount++
            state.totalListingsValue += report.listingPrice
            const settlementDate = new Date(report.settlementDate!)
            if (settlementDate >= startOfWeek && settlementDate <= endOfWeek) {
              state.totalSettlementsValueThisWeek += report.listingPrice
            }
            if (report.commissionType === CommissionType.Fixed) {
              state.gcRevenue += report.commission
            } else {
              state.gcRevenue +=
                report.listingPrice * (report.commission / 100 / 100)
            }
          })
        },
      },
    ),
  }),
  selectors: {
    selectReports: state => state.reports,
    selectGcRevenue: state => state.gcRevenue,
    selectReportsStatus: state => state.status,
    selectTotalListingsCount: state => state.totalListingsCount,
    selectTotalListingsValue: state => state.totalListingsValue,
    selectTotalSettlementsValueThisWeek: state =>
      state.totalSettlementsValueThisWeek,
    selectTotalGCRevenueThisWeek: state => state.totalGCRevenueThisWeek,
    selectAgentStatistics: state => state.agentStatistics,
  },
})

export const { getDashboard } = reportsSlice.actions
export const {
  selectReports,
  selectGcRevenue,
  selectReportsStatus,
  selectTotalListingsCount,
  selectTotalListingsValue,
  selectTotalSettlementsValueThisWeek,
  selectTotalGCRevenueThisWeek,
  selectAgentStatistics,
} = reportsSlice.selectors
