import { FC, useEffect, useState } from "react"

import { styled, useTheme } from "@mui/material/styles"
import { AppBar, Box, IconButton, Toolbar, useMediaQuery } from "@mui/material"

import MenuIcon from "@mui/icons-material/Menu"
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsOutlined"
import AccountAvatar from "./account-avatar"
import KonveiMiniDrawer from "./konvei-mini-drawer"
import { useAppDispatch, useAppSelector } from "../app/hooks"

import logoImage from "../../public/landconnex-logo.svg"
import { getMyDetails, selectMyDetails } from "../app/slices/my-details-slice"
import {
  initialise,
  selectSessionStatus,
  SessionStatus,
} from "../app/slices/session-slice"

export interface PageProps {
  title?: string
  children?: React.ReactElement | React.ReactElement[]
  showBackNavigation?: boolean
}

const Main = styled("main", {
  shouldForwardProp: prop => prop !== "open",
})<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: "-20px",
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
    padding: theme.spacing(0),
    paddingTop: theme.spacing("12px"),
  },
}))

const Page: FC<PageProps> = ({ children }) => {
  const dispatch = useAppDispatch()

  const sessionStatus = useAppSelector(selectSessionStatus)
  const theme = useTheme()
  const isMdDown = useMediaQuery(theme.breakpoints.down("lg"))

  useEffect(() => {
    if (sessionStatus == SessionStatus.initial) {
      dispatch(initialise())
    }
  }, [sessionStatus, dispatch])

  const [drawerOpen, setDrawerOpen] = useState(isMdDown ? false : true)
  const myDetails = useAppSelector(selectMyDetails)

  useEffect(() => {
    if (myDetails == null) {
      dispatch(getMyDetails())
    }
  }, [myDetails])

  return (
    <Box sx={{ display: "flex", height: "100vh", background: "#fafafa" }}>
      <AppBar
        position="fixed"
        component="nav"
        sx={{ background: "#fff", boxShadow: 0, zIndex: "1201" }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            aria-label="open drawer"
            onClick={() => setDrawerOpen(!drawerOpen)}
            sx={{
              padding: 0,
              mr: { sm: 2, xs: 1 },
              "&:hover": { backgroundColor: "#fff" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                background: "rgba(208, 226, 255, .5)",
                borderRadius: "10px",
                padding: { sm: "10px", xs: "7px" },
                marginRight: { sm: 1, xs: "0" },
                cursor: "pointer",
              }}
            >
              <MenuIcon sx={{ color: "#003285" }} />
            </Box>
          </IconButton>
          <Box component="div" sx={{ flexGrow: 1 }}>
            <Box sx={{ display: "flex", width: { sm: 200, xs: 160 } }}>
              <img
                src={logoImage}
                alt="LandConnex Logo"
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              background: "rgba(255, 127, 62, 0.2)",
              borderRadius: "10px",
              padding: { sm: "10px", xs: "7px" },
              marginRight: { sm: 1, xs: "5px" },
              cursor: "pointer",
            }}
          >
            <NotificationsNoneOutlinedIcon
              fontSize="small"
              sx={{ color: "#FF7F3E" }}
            />
          </Box>
          <Box
            sx={{
              background: "rgb(238, 242, 246)",
              borderRadius: { sm: "30px", xs: "10px" },
              padding: { sm: "0 7px", xs: "4px" },
            }}
          >
            <AccountAvatar
              email={myDetails?.email!}
              firstName={myDetails?.firstName}
              lastName={myDetails?.lastName}
            />
          </Box>
        </Toolbar>
      </AppBar>

      <KonveiMiniDrawer
        open={drawerOpen}
        toggleDrawer={() => setDrawerOpen(!drawerOpen)}
      />
      <Main
        open={drawerOpen}
        // sx={{
        //   padding: {
        //     sm: "24px",
        //     xs: "20px 5px",
        //   },
        // }}
      >
        <Box
          sx={{
            background: "#fafafa",
            borderRadius: { sm: "5px", xs: "5px" },
            marginTop: "45px",

            // minHeight: "calc(-70px + 100vh)",
            padding: { sm: "10px", xs: 0 },
          }}
        >
          {children}
        </Box>
      </Main>
    </Box>
  )
}

export default Page
