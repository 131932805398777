import { useEffect, useState, useRef } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { produce } from "immer"
import {
  Address,
  ContactDetails1,
  Name,
  Team,
} from "../../../landconnex-api-client"
import {
  getTeam,
  updateTeam,
  uploadTeamLogo,
  selectTeamStatus,
  selectTeam,
  TeamStatus,
} from "../../../app/slices/team-slice"
import {
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  CircularProgress,
  Alert,
  useMediaQuery,
  useTheme,
  Stack,
  Input,
} from "@mui/material"
import { CloudUpload, Delete } from "@mui/icons-material"

const TeamPage = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"))

  if (isSmall || isMedium) {
    return (
      <Box>
        <ViewTeam />
      </Box>
    )
  }
  return <ViewTeam />
}

const ViewTeam = () => {
  const team = useAppSelector(selectTeam)
  const status = useAppSelector(selectTeamStatus)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (team === undefined) {
      dispatch(getTeam())
      // dispatch(getTeamLogo())
    }
  }, [team, dispatch])

  if (status === TeamStatus.loading) {
    return (
      <Box display="flex" justifyContent="center" p={4}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      {status === TeamStatus.ready && team && <TeamForm team={team} />}
      {status === TeamStatus.error && (
        <Alert severity="error">Failed to load team details</Alert>
      )}
    </Box>
  )
}

const TeamForm = ({ team }: { team: Team }) => {
  const dispatch = useAppDispatch()
  const status = useAppSelector(selectTeamStatus)
  const [formData, setFormData] = useState<Team>(team)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleLogoUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0]
    if (!file) return

    try {
      // Create a preview URL

      // setLogoPreview(file)

      // Upload the file
      await dispatch(uploadTeamLogo(file))

      // Refresh logo
    } catch (error) {
      console.error("Failed to upload logo:", error)
      // Reset preview on error
      // setLogoPreview(logo)
    }
  }

  const handleRemoveLogo = async () => {
    try {
      // Update team with empty string for logo
      await dispatch(updateTeam({ ...formData, logo: "" })).unwrap()
      // setLogoPreview(null)
      // Refresh logo
    } catch (error) {
      console.error("Failed to remove logo:", error)
    }
  }

  const handleChange = (field: keyof Team, value: any) => {
    let updatedTeam

    updatedTeam = produce(team => {
      team[field] = value
    })

    // switch (field) {
    //   case "abn":
    //     updatedTeam = produce(team => {
    //       team.abn = value
    //     })
    //     break
    //   case "acn":
    //     updatedTeam = produce(team => {
    //       team.acn = value
    //     })
    //     break
    //   default:
    //     updatedTeam = team
    //     break
    // }

    setFormData(updatedTeam)
  }
  const handlePrimaryContactNameChange = (field: keyof Name, value: any) => {
    setFormData(
      produce(team => {
        team.primaryContact!.name![field] = value
      }),
    )
  }
  const handleAddressChange = (field: keyof Address, value: any) => {
    setFormData(
      produce(team => {
        team.primaryContact!.address![field] = value
      }),
    )
  }
  const handleContactDetailsChange = (
    field: keyof ContactDetails1,
    value: any,
  ) => {
    setFormData(
      produce(team => {
        team.primaryContact![field] = value
      }),
    )
  }

  // const VisuallyHiddenInput = styled("input")({
  //   clip: "rect(0 0 0 0)",
  //   clipPath: "inset(50%)",
  //   height: 1,
  //   overflow: "hidden",
  //   position: "absolute",
  //   bottom: 0,
  //   left: 0,
  //   whiteSpace: "nowrap",
  //   width: 1,
  // })
  return (
    <Paper sx={{ padding: 3 }}>
      <Box>
        <Typography variant="h6" gutterBottom>
          Company Logo
        </Typography>
        <Box width={300} mx="auto">
          <img
            style={{ minWidth: "100%", maxWidth: "100%" }}
            src={`https://landconnex-prod-public.ap-south-1.linodeobjects.com/logos/${team.id!}.png`}
            alt="Company Logo"
          />
        </Box>
      </Box>
      {/* <Box display="flex" justifyContent="flex-end" mb={2}>
        {!isEditing ? (
          <Button
            variant="contained"
            onClick={() => setIsEditing(true)}
            sx={{ backgroundColor: "custom.agentAppointment" }}
          >
            Edit Details
          </Button>
        ) : (
          <Box gap={2} display="flex">
            <Button
              variant="outlined"
              onClick={() => {
                setFormData(team)
                setIsEditing(false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={status === TeamStatus.loading}
              sx={{ backgroundColor: "custom.agentAppointment" }}
            >
              Save Changes
            </Button>
          </Box>
        )}
      </Box> */}

      <Stack direction="row" spacing={2} mb={2} justifyContent="center">
        <Box width={130}>
          <Button color="success" startIcon={<CloudUpload />}>
            Upload
            <Input type="file" ref={fileInputRef} onChange={handleLogoUpload} />
            {/* <VisuallyHiddenInput
              type="file"
              accept="image/png"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleLogoUpload}
            /> */}
          </Button>
        </Box>

        <Box width={130}>
          <Button
            onClick={handleRemoveLogo}
            color="error"
            startIcon={<Delete />}
          >
            Delete
          </Button>
        </Box>
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Company Details
          </Typography>
          <TextField
            variant="filled"
            value={formData.name}
            fullWidth
            label="Name"
            onChange={e => handleChange("name", e.target.value)}
            margin="normal"
          />
          <TextField
            variant="filled"
            fullWidth
            label="Website URL"
            value={formData.websiteUrl || ""}
            onChange={e => handleChange("websiteUrl", e.target.value)}
            margin="normal"
          />
          <TextField
            variant="filled"
            fullWidth
            label="Primary Color"
            value={formData.primaryColour || ""}
            onChange={e => handleChange("primaryColour", e.target.value)}
            margin="normal"
          />
          <TextField
            variant="filled"
            fullWidth
            label="Secondary Color"
            value={formData.secondaryColour || ""}
            onChange={e => handleChange("secondaryColour", e.target.value)}
            margin="normal"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Primary Contact Details
          </Typography>
          <TextField
            variant="filled"
            fullWidth
            label="First Name"
            value={formData.primaryContact?.name?.firstName || ""}
            onChange={e =>
              handlePrimaryContactNameChange("firstName", e.target.value)
            }
            margin="normal"
          />
          <TextField
            variant="filled"
            fullWidth
            label="Last Name"
            value={formData.primaryContact?.name?.lastName || ""}
            onChange={e =>
              handlePrimaryContactNameChange("lastName", e.target.value)
            }
            margin="normal"
          />
          <TextField
            variant="filled"
            fullWidth
            label="Email Address"
            value={formData.primaryContact?.email || ""}
            onChange={e => handleContactDetailsChange("email", e.target.value)}
            margin="normal"
          />
          <TextField
            variant="filled"
            fullWidth
            label="Phone"
            value={formData.primaryContact?.phone || ""}
            onChange={e => handleContactDetailsChange("phone", e.target.value)}
            margin="normal"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Address
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                fullWidth
                label="Street Address 1"
                value={formData.primaryContact?.address?.streetAddress1 || ""}
                onChange={e =>
                  handleAddressChange("streetAddress1", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                fullWidth
                label="Street Address 2"
                value={formData.primaryContact?.address?.streetAddress2 || ""}
                onChange={e =>
                  handleAddressChange("streetAddress2", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                fullWidth
                label="Locality"
                value={formData.primaryContact?.address?.locality || ""}
                onChange={e => handleAddressChange("locality", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                fullWidth
                label="State/Territory"
                value={formData.primaryContact?.address?.stateOrTerritory || ""}
                onChange={e =>
                  handleAddressChange("stateOrTerritory", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                fullWidth
                label="Post Code"
                value={formData.primaryContact?.address?.postCode || ""}
                onChange={e => handleAddressChange("postCode", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                fullWidth
                label="Country"
                value={formData.primaryContact?.address?.country || ""}
                onChange={e => handleAddressChange("country", e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Business Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                fullWidth
                label="ABN"
                value={formData.abn || ""}
                onChange={e => handleChange("abn", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                fullWidth
                label="ACN"
                value={formData?.acn || ""}
                onChange={e => handleChange("acn", e.target.value)}
              />
            </Grid>
            <Box width={120} mx="auto" mt="30px">
              <Button
                size="large"
                disabled={status === TeamStatus.loading}
                // sx={{ backgroundColor: "custom.agentAppointment" }}
                onClick={() => {
                  dispatch(updateTeam(formData))
                }}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default TeamPage
