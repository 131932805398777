import Grid from "@mui/material/Grid"
import { Dayjs } from "dayjs"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { Contract } from "../../../landconnex-api-client"
import { FormProvider, useForm } from "react-hook-form"
import AutoSave from "../../../components/auto-save"
import FormWrapper from "../../agent-appointment/components/form-wrapper"
import FormInputDatePicker from "../../../components/form-input-date-picker"
import { putContract, selectContract } from "../../../app/slices/contract-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { produce } from "immer"

const Settlement: React.FC = () => {
  const dispatch = useAppDispatch()

  const contract = useAppSelector(selectContract)
  const workspace = useAppSelector(selectWorkspace)

  const contractFormMethods = useForm<Contract>({
    defaultValues: contract!,
  })

  const { control, handleSubmit } = contractFormMethods

  const onContractChangedSubmit = (contract: Contract) => {
    let updatedContract: Contract | undefined = undefined
    if (contract.settlementDate) {
      updatedContract = produce(contract, draft => {
        draft.settlementDate = (
          contract.settlementDate as unknown as Dayjs
        ).toISOString()
      })
    } else {
      updatedContract = produce(contract, draft => {
        draft.settlementDate = "0001-01-01T00:00:00Z"
      })
    }

    updatedContract = produce(updatedContract, draft => {
      draft.depositHolder = "test"
    })

    dispatch(
      putContract({
        workspaceId: workspace!.workspace!.id,
        contract: updatedContract,
      }),
    )
  }

  return (
    <FormProvider {...contractFormMethods}>
      <form onSubmit={handleSubmit(onContractChangedSubmit)}>
        <FormWrapper title="Settlement Date">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormInputDatePicker
                control={control}
                name="settlementDate"
                label="Settlement Date"
                required
              />
            </Grid>
          </Grid>
          <AutoSave
            onSubmit={onContractChangedSubmit}
            defaultValues={contract}
          />
        </FormWrapper>
      </form>
    </FormProvider>
  )
}

export default Settlement
