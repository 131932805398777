import React, { ReactNode } from "react"
import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from "@mui/material"

interface Note {
  title: string
  items: string[]
}

interface FormWrapperProps {
  title: string
  notes?: Note[]
  children: ReactNode
}

export const Notes = ({ notes }: { notes: Note[] | undefined }) => {
  return (
    <>
      {notes?.map(({ title, items }, index) => (
        <Box key={index}>
          <Typography variant="h6" sx={{ color: "primary.main" }}>
            {title}
          </Typography>
          <List
            sx={{
              listStyleType: "disc",
              pl: 2,
              "& .MuiListItem-root": {
                display: "list-item",
              },
            }}
          >
            {items.map((item, idx) => (
              <ListItem sx={{ padding: 0 }} key={idx}>
                <ListItemText
                  primary={item}
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: { sm: "1rem", xs: ".8rem" },
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      ))}
    </>
  )
}

const FormWrapper: React.FC<FormWrapperProps> = ({
  title,
  notes,
  children,
}) => (
  <Card sx={{ border: "none" }}>
    <CardHeader title={title} />
    <CardContent>
      <Notes notes={notes} />
      {children}
    </CardContent>
  </Card>
)

export default FormWrapper
