import { jwtDecode } from "jwt-decode"
import { User, UserManager, WebStorageStateStore } from "oidc-client-ts"
import { appConfig } from "../constants"

let roles = Array<string>()

const userManager = new UserManager({
  authority: appConfig.authority,
  client_id: appConfig.clientId,
  redirect_uri: `${window.location.origin}/auth-callback`,
  silent_redirect_uri: `${window.location.origin}/silent-callback`,
  post_logout_redirect_uri: `${window.location.origin}`,
  response_type: "code",
  scope: "openid profile email api",
  loadUserInfo: true,
  monitorSession: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
})

export const refreshToken = async () => {
  try {
    const refreshedUser = await userManager.startSilentRenew()
    return refreshedUser

    return null
  } catch (error) {
    console.error("Token refresh failed:", error)
    // If refresh fails, redirect to login
    await userManager.signinRedirect()
    return null
  }
}

function getRoles(): string[] {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${appConfig.authority}:${appConfig.clientId}`,
  )

  if (oidcStorage) {
    const user = User.fromStorageString(oidcStorage)
    const token = jwtDecode(user.access_token, {}) as string

    const roles = JSON.parse(JSON.stringify(token))["roles"]
    return roles
  }
  return []
}

const getTenant = (): string => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${appConfig.authority}:${appConfig.clientId}`,
  )

  if (oidcStorage) {
    const user = User.fromStorageString(oidcStorage)
    const token = jwtDecode(user.access_token, {}) as string
    const tenant = JSON.parse(JSON.stringify(token))["tenant"] as string

    if (tenant === undefined) {
      return ""
    }
    return tenant as string
  }
  return ""
}

function hasRole(requiredRole: string): boolean {
  if (roles.length == 0) {
    roles = getRoles()
  }

  return roles.some(v => v == requiredRole)
}

export { getRoles, hasRole, getTenant }
