import { CancelOutlined, CheckCircleOutlined } from "@mui/icons-material"
import { CircularProgress, Box, Typography } from "@mui/material"

const stateStyles: Record<
  string,
  {
    color: string
    statusBgColor: string
    message: string
    icon: React.ReactNode
  }
> = {
  OfferCreated: {
    color: "rgb(1, 67, 97)",
    statusBgColor: "rgb(229, 246, 253)",
    icon: <CheckCircleOutlined sx={{ color: "#0288d1", width: "20px" }} />,
    message: "Offer Created",
  },
  OfferBuyerSigningSent: {
    color: "rgb(102, 60, 0)",
    statusBgColor: "rgb(255, 244, 229)",
    icon: (
      <CircularProgress size={15} sx={{ color: "#ed6c02", width: "15px" }} />
    ),
    message: "Sent to Buyer for Signing",
  },
  OfferBuyerSigningCompleted: {
    color: "rgb(30, 70, 32)",
    statusBgColor: "rgb(237, 247, 237)",
    icon: <CheckCircleOutlined sx={{ color: "#2e7d32", width: "20px" }} />,
    message: "Buyer Signing · Completed",
  },
  OfferBuyerSigningDeclined: {
    color: "rgb(95, 33, 32)",
    statusBgColor: "rgb(253, 237, 237)",
    icon: <CancelOutlined sx={{ color: "#d32f2f", width: "20px" }} />,
    message: "Buyer Signing · Declined",
  },
  OfferSellerSigningSent: {
    color: "rgb(102, 60, 0)",
    statusBgColor: "rgb(255, 244, 229)",
    icon: (
      <CircularProgress size={15} sx={{ color: "#ed6c02", width: "15px" }} />
    ),
    message: "Sent to Seller for Signing",
  },
  OfferSellerSigningCompleted: {
    color: "rgb(30, 70, 32)",
    statusBgColor: "rgb(237, 247, 237)",
    icon: <CheckCircleOutlined sx={{ color: "#2e7d32", width: "20px" }} />,
    message: "Seller Signing · Completed",
  },
  OfferSellerSigningDeclined: {
    color: "rgb(95, 33, 32)",
    statusBgColor: "rgb(253, 237, 237)",
    icon: <CancelOutlined sx={{ color: "#d32f2f", width: "20px" }} />,
    message: "Seller Signing · Declined",
  },
  OfferCompletedSuccess: {
    color: "rgb(30, 70, 32)",
    statusBgColor: "rgb(237, 247, 237)",
    icon: <CheckCircleOutlined sx={{ color: "#2e7d32", width: "20px" }} />,
    message: "Offer Completed",
  },
  OfferCompletedDeclined: {
    color: "rgb(95, 33, 32)",
    statusBgColor: "rgb(253, 237, 237)",
    icon: <CancelOutlined sx={{ color: "#d32f2f", width: "20px" }} />,
    message: "Offer Declined",
  },
}

const OfferStatusDisplay = ({ state }: { state: string | undefined }) => {
  const status = stateStyles[state || ""] || { color: "#000", icon: null }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        background: status.statusBgColor,
        padding: "10px",
      }}
    >
      <Typography
        component="span"
        variant="subtitle1"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          color: status.color,
          fontSize: "14px",
        }}
      >
        {status.icon} {status.message || "Unknown"}
      </Typography>
    </Box>
  )
}

export default OfferStatusDisplay
