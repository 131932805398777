import { Participant } from "../../../landconnex-api-client"
import { OnParticipantChanged } from "../../../components/events"
import ClientDetail from "./client-detail"
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  CardActions,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { useParams } from "react-router-dom"
import {
  postParticipant,
  selectParticipants,
} from "../../../app/slices/participants-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"

export type OnAddBuyer = () => void

export interface SellerDetailsProps {
  sellers: Array<Participant>
  onChanged: OnParticipantChanged
  onAdded: OnAddBuyer
  onRemoved: OnParticipantChanged
}
const SellerDetails: React.FC = ({}) => {
  const participants = useAppSelector(selectParticipants)
  const workspace = useAppSelector(selectWorkspace)
  const dispatch = useAppDispatch()
  const params = useParams()
  const workspaceId = Number(params.workspaceId)

  let disableField = true
  if (workspace != undefined) {
    disableField = ["buyer", "buyerSolicitor"].includes(
      workspace!.workspace!.role!,
    )
  }

  const sellers = participants.filter(p => p.role == "seller")
  return (
    <Card sx={{ border: "none" }}>
      <CardHeader title="Seller's Details" />
      <CardContent>
        {sellers.map((client, index) => (
          <ClientDetail
            key={client.id}
            index={index}
            client={client}
            participantId={client.id}
            disabled={disableField}
          />
        ))}
      </CardContent>
      <CardActions>
        <Button
          variant="text"
          color="secondary"
          onClick={() => {
            dispatch(
              postParticipant({
                participant: { role: "seller" },
                workspaceId: workspaceId,
              }),
            )
          }}
        >
          Add Seller
        </Button>
      </CardActions>
    </Card>
  )
}

export default SellerDetails
