import {
  House,
  StackedLineChart,
  
} from "@mui/icons-material"
import Grid from "@mui/material/Grid2"
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Stack,
  Typography,
} from "@mui/material"
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined"
import AnalyticsIcon from "@mui/icons-material/Analytics"
import { useState } from "react"
import { useAppSelector } from "../../app/hooks"
import Page from "../../components/page"
import {
  selectGcRevenue,
  selectTotalListingsCount,
  selectTotalListingsValue,
  selectTotalSettlementsValueThisWeek,
  selectTotalGCRevenueThisWeek,
  selectAgentStatistics,
} from "../../app/slices/reports-slice"
import Fact from "./fact"
import { formatCurrency } from "../../helpers/helpers"
import { PieChart } from "@mui/x-charts"

const DashboardSmall = () => {
  const [viewNumber, setViewNumber] = useState(0)

  const gcRevenue = useAppSelector(selectGcRevenue)
  const totalListingsCount = useAppSelector(selectTotalListingsCount)
  const totalListingsValue = useAppSelector(selectTotalListingsValue)
  const totalSettlementsValueThisWeek = useAppSelector(
    selectTotalSettlementsValueThisWeek,
  )
  const totalGCRevenueThisWeek = useAppSelector(selectTotalGCRevenueThisWeek)
  const agentStatistics = useAppSelector(selectAgentStatistics)

  const data = agentStatistics.map(agent => ({
    id: agent.sub,
    value: agent.gcRevenue / gcRevenue,
    label: agent.agentName,
  }))
  const dataColors = ["#f44236", "#1e88e5", "#00c854", "#673ab7"]

  return (
    <Page>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box sx={{ height: "100%", overflow: "auto", pb: 8 }}>
          <Grid container>
            <Grid container size={12} spacing={1} sx={{ paddingBottom: 1 }}>
              <Grid
                size={{ xs: 6, md: 6, lg: 4 }}
                sx={{ backgroundColor: "#fff", padding: 1 }}
              >
                <Fact
                  title="Total Listings"
                  value={`${totalListingsCount}`}
                  icon={
                    <AnalyticsIcon
                      sx={{ fontSize: { lg: 80, sm: 70, xs: 50 } }}
                    />
                  }
                  sx={{
                    background: "#eaf3fa",
                    height: { lg: 150, sm: 120, xs: 100 },
                  }}
                />
              </Grid>

              <Grid
                size={{ xs: 6, md: 6, lg: 4 }}
                sx={{ backgroundColor: "#fff", padding: 1 }}
              >
                <Fact
                  title="Total GCR"
                  sx={{
                    background: "#eaf3fa",
                    height: { lg: 150, sm: 120, xs: 100 },
                  }}
                  icon={
                    <MonetizationOnOutlinedIcon
                      sx={{ fontSize: { lg: 80, sm: 70, xs: 50 } }}
                    />
                  }
                  value={`${formatCurrency(gcRevenue)}`}
                />
              </Grid>
              <Grid
                size={{ xs: 12, md: 6, lg: 4 }}
                sx={{ backgroundColor: "#fff", padding: 1 }}
              >
                <Fact
                  title="Total Listings Value"
                  value={formatCurrency(totalListingsValue)}
                  icon={
                    <MonetizationOnOutlinedIcon
                      sx={{ fontSize: { lg: 80, sm: 70, xs: 50 } }}
                    />
                  }
                  sx={{
                    background: "#eaf3fa",
                    height: { lg: 150, sm: 120, xs: 100 },
                  }}
                />
              </Grid>
            </Grid>
            <Grid
          size={{ xs: 12, md: 6, lg: 4 }}
          sx={{ backgroundColor: "#fff", padding: 1 }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Fact
              title="GCR This Week"
              value={formatCurrency(totalGCRevenueThisWeek)}
              icon={
                <MonetizationOnOutlinedIcon
                  sx={{ fontSize: { lg: 80, sm: 70, xs: 50 } }}
                />
              }
              sx={{ height: { lg: 150, sm: 120, xs: 100 }, flex: 1 }}
            />
            <Fact
              title="Settlements This Week"
              icon={
                <MonetizationOnOutlinedIcon
                  sx={{ fontSize: { lg: 80, sm: 70, xs: 50 } }}
                />
              }
              value={formatCurrency(totalSettlementsValueThisWeek)}
              sx={{ height: { lg: 150, sm: 120, xs: 100 }, flex: 1 }}
            />
          </Box>
          <Grid
            size={12}
            sx={{ display: "flex", justifyContent: "center", padding: 2 }}
          >
            <Stack>
              <PieChart
                colors={dataColors}
                width={400}
                height={200}
                series={[
                  {
                    data,
                    valueFormatter: value =>
                      `${(value.value * 100).toFixed(2)}%`,
                    innerRadius: 30,
                    outerRadius: 100,
                    paddingAngle: 2,
                    cornerRadius: 5,
                    startAngle: -45,
                  },
                ]}
              >
                {/* <PieCenterLabel>% GCR Contribution by Agent</PieCenterLabel> */}
              </PieChart>
              <Typography
                variant="h6"
                sx={{ textAlign: "center", color: "text.secondary" }}
              >
                GCR Contribution by Agent
              </Typography>
            </Stack>
          </Grid>
        </Grid>
          </Grid>
        </Box>
      </Box>
    </Page>
  )

  return (
    <Box sx={{ position: "relative", overflow: "hidden" }}>
      {viewNumber === 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            padding: "0 15px",
          }}
        >
          <h1>0</h1>
        </Box>
      )}
      {viewNumber === 1 && <h1>1</h1>}
      {viewNumber === 2 && <h1>2</h1>}
      {viewNumber === 3 && <h1>3</h1>}
      {/* <Box
        sx={{
          position: "relative",
          height: "100%",
          overflow: "auto",
          zIndex: 1,
          // pt: 8, // Add padding top to account for fixed header
          pb: 8, // Add padding bottom to account for bottom navigation
        }}
      >
        {viewNumber === 0 && <h1>Hello</h1>}
      </Box> */}
      <Box
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1000 }}
      >
        <BottomNavigation
          showLabels
          value={viewNumber}
          onChange={(_, newValue) => {
            setViewNumber(newValue)
          }}
        >
          <BottomNavigationAction
            label="Overview"
            icon={<StackedLineChart />}
          />
          <BottomNavigationAction label="Listings" icon={<House />} />
        </BottomNavigation>
      </Box>
    </Box>
  )
}

export default DashboardSmall
