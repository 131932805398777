import { FC, ReactNode, useEffect, useRef, useState } from "react"
import {
  AddAnnexureDocumentTypeEnum,
  Annexure,
} from "../../../landconnex-api-client"
import {
  Box,
  IconButton,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material"
import LCCard from "../../../components/cards/lc-card"

import FormWrapper from "../../agent-appointment/components/form-wrapper"
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
  useDroppable,
} from "@dnd-kit/core"
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { arrayMove } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import {
  addAnnexure,
  selectAnnexures,
} from "../../../app/slices/contract-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
export type OnAnnexureUpload = (file: File) => void
export type OnAnnexureDelete = (annexureId: number) => void
export type OnAnnexureUpdate = (annexureId: number, position: number) => void

export interface AnnexuresProps {
  documents: Annexure[]
  onAnnexureDelete: OnAnnexureDelete
  onAnnexureUpload: OnAnnexureUpload
  onAnnexureUpdate: OnAnnexureUpdate
}

interface DraggableItemProps {
  id: string
  isLoading: boolean
  children: (listeners: any) => ReactNode
}

const DraggableItem: FC<DraggableItemProps> = ({ id, isLoading, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: isDragging ? undefined : transition,
    opacity: isDragging || isLoading ? 0.5 : 1,
    zIndex: isDragging || isLoading ? 1 : "auto",
    position: "relative" as const,
  }

  return (
    <Box ref={setNodeRef} style={style} {...attributes}>
      {children(listeners)}
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            bgcolor: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}

const DroppableArea = ({ children }: { children: React.ReactNode }) => {
  const { setNodeRef } = useDroppable({ id: "droppable" })
  return (
    <Box
      ref={setNodeRef}
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      {children}
    </Box>
  )
}

const Annexures: FC = ({}) => {
  const dispatch = useAppDispatch()
  const workspace = useAppSelector(selectWorkspace)
  const annexures = useAppSelector(selectAnnexures)
  const disableField = ["buyer", "buyerSolicitor"].includes(
    workspace!.workspace?.role!,
  )

  const [localDocuments, setLocalDocuments] = useState<Annexure[]>(
    annexures || [],
  )
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<number | null>(null)
  const [confirmDeleteId, setConfirmDeleteId] = useState<number | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const sensors = useSensors(useSensor(PointerSensor))

  useEffect(() => {
    setLocalDocuments(annexures || [])
    setIsUploading(false)
    setIsDeleting(null)
  }, [annexures])

  const handleAnnexureUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const files = event.target.files
    if (files) {
      setIsUploading(true)
      dispatch(
        addAnnexure({
          workspaceId: workspace!.workspace!.id!,
          documentName: files[0].name,
          documentType: AddAnnexureDocumentTypeEnum.Annexure,
          file: files[0],
        }),
      )
    }
  }

  const handleDragEnd = (event: any) => {
    const { active, over } = event
    if (over && active.id !== over.id) {
      const oldIndex = localDocuments.findIndex(
        doc => doc.id === Number(active.id),
      )
      const newIndex = localDocuments.findIndex(
        doc => doc.id === Number(over.id),
      )

      if (oldIndex !== -1 && newIndex !== -1) {
        const updatedDocuments = arrayMove(localDocuments, oldIndex, newIndex)

        const reorderedDocuments = updatedDocuments.map((doc, index) => ({
          ...doc,
          order: index,
        }))

        setLocalDocuments(reorderedDocuments)

        // const draggedItem = reorderedDocuments[newIndex];
        //   onAnnexureUpdate(draggedItem.id, draggedItem.order);
      }
    }
  }

  const handleDeleteClick = (id: number) => {
    setConfirmDeleteId(id) // Show confirmation dialog
  }

  const confirmDelete = () => {
    if (confirmDeleteId !== null) {
      setIsDeleting(confirmDeleteId)
      // onAnnexureDelete(confirmDeleteId);
      setConfirmDeleteId(null)
    }
  }

  const cancelDelete = () => {
    setConfirmDeleteId(null)
  }

  return (
    <FormWrapper title="Upload Annexures">
      <LCCard
        title="Annexures"
        buttons={
          <Box
            sx={{ position: "relative", zIndex: 1, display: "flex", gap: 2 }}
          >
            {isUploading && (
              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={30} />
              </Box>
            )}
            <Box
              sx={{
                width: 80,
              }}
            >
              <Button
                color="success"
                disabled={isUploading || disableField}
                onClick={() => {
                  fileInputRef.current?.click()
                }}
              >
                Upload
              </Button>
            </Box>
          </Box>
        }
        titleColor="#000"
        txtColor="#000"
      >
        <Box
          sx={{
            pointerEvents: isUploading ? "none" : "",
            opacity: isUploading ? ".5" : 1,
          }}
        >
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={localDocuments.map(doc => String(doc.id))}
              strategy={verticalListSortingStrategy}
            >
              <DroppableArea>
                {localDocuments.map(document => (
                  <DraggableItem
                    key={document.id}
                    id={String(document.id)}
                    isLoading={isDeleting === document.id}
                  >
                    {listeners => (
                      <Box
                        sx={{
                          padding: 2,
                          borderRadius: 2,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          position: "relative",
                          flex: 1,
                          boxShadow:
                            "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
                          bgcolor: "#f1f6f6",
                        }}
                      >
                        <Box
                          {...listeners}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                          }}
                        >
                          <Typography>{document.document.name}</Typography>
                          <Typography>{document.document.size} kb.</Typography>
                        </Box>
                        <IconButton
                          onClick={() => handleDeleteClick(document.id)}
                          sx={{ pointerEvents: "auto" }}
                          disabled={disableField}
                        >
                          <DeleteForeverOutlinedIcon
                            fontSize="large"
                            color="error"
                          />
                        </IconButton>
                      </Box>
                    )}
                  </DraggableItem>
                ))}
              </DroppableArea>
            </SortableContext>
          </DndContext>
        </Box>
      </LCCard>
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleAnnexureUpload}
        accept="application/pdf"
        multiple={false}
      />

      {/* Confirmation Dialog */}
      <Dialog open={confirmDeleteId !== null} onClose={cancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this item?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </FormWrapper>
  )
}

export default Annexures
