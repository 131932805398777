import React, { Fragment, useEffect, useState } from "react"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useParams } from "react-router-dom"

import KonveiStepper from "../../components/konvei-stepper"
import ClientDetails from "./components/client-details"
import LicenceeDetails from "./components/licencee-details"
import Property from "./components/property"
import Appointment from "./components/appointment"
import PropertySale from "./components/property-sale"
import Commission from "./components/commission"
import Authorisation from "./components/authorisation"
import Generate from "./components/generate"
import {
  AgentAppointmentStatus,
  getAgentAppointment,
  selectAgentAppointment,
  selectAgentAppointmentStatus,
} from "../../app/slices/agent-appointment-slice"
import { getParticipants } from "../../app/slices/participants-slice"
import { getProperty } from "../../app/slices/property-slice"
import { Typography } from "@mui/material"

const AgentAppointmentPage: React.FC = () => {
  const params = useParams()
  const dispatch = useAppDispatch()
  const agentAppointment = useAppSelector(selectAgentAppointment)
  const agentAppointmentStatus = useAppSelector(selectAgentAppointmentStatus)

  const [step, setStep] = useState<string>("client")
  //const [setErrors] = useState<{ message: string; step: number }[]>([]);
  const steps = [
    "client",
    "agent",
    "property",
    "appointment",
    "sale",
    "commission",
    "authorisation",
    "review",
  ]

  const workspaceId = parseInt(params["workspaceId"] as string)
  useEffect(() => {
    if (
      agentAppointment === undefined &&
      agentAppointmentStatus !== AgentAppointmentStatus.loading
    ) {
      dispatch(getAgentAppointment(workspaceId))
      dispatch(getParticipants(workspaceId))
      dispatch(getProperty(workspaceId))
    } else if (
      agentAppointment?.workspaceId !== workspaceId &&
      agentAppointmentStatus !== AgentAppointmentStatus.loading
    ) {
      dispatch(getAgentAppointment(workspaceId))
      dispatch(getParticipants(workspaceId))
      dispatch(getProperty(workspaceId))
    }
  }, [workspaceId, agentAppointment, agentAppointmentStatus, dispatch])

  function onStepChanged(step: string): void {
    setStep(step)
  }

  return (
    <Fragment>
      <Typography
        variant="h6"
        sx={{ color: "primary.main", textTransform: "capitalize" }}
      >
        {step}
      </Typography>
      {steps.indexOf(step) < 9 ? (
        <KonveiStepper
          steps={steps}
          activeStep={step}
          onStepChanged={onStepChanged}
          isCompressed={true}
        />
      ) : (
        <></>
      )}

      {step === "client" && <ClientDetails />}
      {step === "agent" && <LicenceeDetails />}
      {step === "property" && <Property />}
      {step === "appointment" && <Appointment />}
      {step === "sale" && <PropertySale />}
      {step === "commission" && <Commission />}
      {step === "authorisation" && <Authorisation />}
      {step === "review" && <Generate />}
    </Fragment>
  )
}

export default AgentAppointmentPage
