import Grid from "@mui/material/Grid"
import React from "react"
import Typography from "@mui/material/Typography"
import { Participant } from "../../../landconnex-api-client"
import { useTheme } from "@mui/material"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import ParticipantNameForm from "../../../components/participant-name-form"
import FormInputText from "../../../components/form-input-text"
import ContactDetails from "../../../components/contact-details"
import AddressForm from "../../../components/address-form"
import FormWrapper from "../../agent-appointment/components/form-wrapper"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import AutoSave from "../../../components/auto-save"
import {
  putOfferParticipant,
  selectOffer,
} from "../../../app/slices/offers-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { selectParticipants } from "../../../app/slices/participants-slice"

const BuyersSolicitor: React.FC = () => {
  const dispatch = useAppDispatch()

  const workspace = useAppSelector(selectWorkspace)
  const participants = useAppSelector(selectParticipants)
  const offer = useAppSelector(selectOffer)
  const disableField = ["sellerAgent"].includes(workspace!.workspace!.role!)

  const buyerSolicitor = participants.find(
    participant => participant.role === "buyerSolicitor",
  )
  const byerSolicitorFormMethods = useForm<Participant>({
    defaultValues: buyerSolicitor!,
    // resolver: yupResolver(schema),
  })

  const { handleSubmit, control, setValue } = byerSolicitorFormMethods

  const theme = useTheme()

  // useEffect(() => {
  //   reset(participant);
  //   trigger();
  // }, [participant]);

  const handleSaveContractParticipant: SubmitHandler<Participant> = data => {
    dispatch(
      putOfferParticipant({
        workspaceId: workspace!.workspace!.id!,
        offerId: offer!.id!,
        participant: data,
      }),
    )
  }

  return (
    <FormProvider {...byerSolicitorFormMethods}>
      <form onSubmit={handleSubmit(handleSaveContractParticipant)}>
        <FormWrapper title="Buyers' Solicitor's Details">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Name
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormInputText
                control={control}
                name="solicitorName"
                label="Solicitor Name"
                required
                disabled={disableField}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ParticipantNameForm
                control={control}
                participantType={"organisation"}
                disabled={disableField}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Contact Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ContactDetails control={control} disabled={disableField} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Address
              </Typography>
            </Grid>
            <AddressForm
              control={control}
              address={buyerSolicitor!}
              setValue={setValue}
              disabled={disableField}
            />
          </Grid>
        </FormWrapper>
      </form>
      <AutoSave
        onSubmit={handleSaveContractParticipant}
        defaultValues={buyerSolicitor}
      ></AutoSave>
    </FormProvider>
  )
}

export default BuyersSolicitor
