import { selectWorkspace } from "../../../app/slices/workspaces-slice"
import { useAppSelector } from "../../../app/hooks"
import { selectWorkflow } from "../../../app/slices/workspaces-slice"
import AgentAppointmentPage from "../../agent-appointment/agent-appointment-page"
import ContractStepperPage from "../../contract-stepper/contract-stepper-page"
import Offers from "../../offers"
import AwaitingTaskCompletion from "../../../components/awaiting-task-completion"
import SelectSolicitor from "./actions/select-solicitor"
import { ActingForEnum } from "./actions/select-solicitor"
import { FC } from "react"
import { Container, Paper, Typography } from "@mui/material"
import AgentAppointmentAwaitingSigning from "../../agent-appointment/agent-appointment-awating-signing"


const WorkflowAction = () => {
  const workspace = useAppSelector(selectWorkspace)
  const workflow = useAppSelector(selectWorkflow)

  if (workspace == null || workspace.workspace == null) {
    return <></>
  }

  interface WorkflowActionContainerProps {
    children: React.ReactNode
  }
  const WorkflowActionContainer: FC<WorkflowActionContainerProps> = ({
    children,
  }) => {
    return (
      <Container sx={{ padding: { md: 0, xs: 0 }, margin: 0 }}>
        <Paper
          sx={{ padding: 1, boxShadow: "0 1px 15px 0 rgba(0, 0, 0, 0.1)" }}
        >
          <Typography variant="h6">
            Current Task: Complete the agent appointment
          </Typography>
          {children}
        </Paper>
      </Container>
    )
  }
  console.log(workspace)
  switch (workflow?.subState) {
    case "agentAppointmentPreparing":
      if (workspace?.workspace!.role == "sellerAgent") {
        return (
          <WorkflowActionContainer>
            <AgentAppointmentPage />
          </WorkflowActionContainer>
        )
      }
      return <>Nothing to do</>
    case "agentAppointmentAwaitingSigning":
      return <AgentAppointmentAwaitingSigning />
    case "agentAppointmentAwaitingSellerAdmission":
      if (workspace?.workspace!.role == "sellerAgent") {
        return <Offers />
      }
      return (
        <AwaitingTaskCompletion>
          Waiting for sellers to join the workspace
        </AwaitingTaskCompletion>
      )
    case "contractPreparationAwaitingSellerSolicitorSelection":
      if (workspace?.workspace!.role == "sellerAgent") {
        return <Offers />
      }
      if (workspace?.workspace!.role == "seller") {
        return <SelectSolicitor actingFor={ActingForEnum.Seller} />
      }
      return (
        <AwaitingTaskCompletion>
          Waiting for buyer solicitor to be selectedsfsdf
        </AwaitingTaskCompletion>
      )
    case "contractPreparationPreparing":
      if (
        workspace?.workspace!.role == "sellerAgent" ||
        workspace?.workspace!.role == "seller"
      ) {
        return <Offers />
      }
      if (workspace?.workspace!.role == "sellerSolicitor") {
        return <ContractStepperPage />
      }
      return (
        <AwaitingTaskCompletion>
          Waiting for seller solicitor to be selectedfdrg
        </AwaitingTaskCompletion>
      )
    case "acceptingOffers":
    case "contractSigned":
      return <Offers />
    default:
      return <></>
  }

  // switch (workspace?.workspace!.role) {
  //   case "sellerAgent":
  //     if (workflow?.state == "agentAppointment") {
  //       return <AgentAppointmentPage />
  //     }

  //     if (params["offerId"] == null) {
  //       return (
  //         <LCCard
  //           title="Offers"
  //           txtColor="#000"
  //           titleColor="#000"
  //           bgColor="#fff"
  //           buttons={
  //             <Box sx={{ position: "relative", zIndex: 1 }}>
  //               {params["offerId"] == null &&
  //               workspaceSummary.workspace!.role == "sellerAgent" ? (
  //                 <Box sx={{ width: 120 }}>
  //                   <Button
  //                     color="secondary"
  //                     onClick={onOfferCreated}
  //                     startIcon={<AddOutlinedIcon />}
  //                   >
  //                     Offer
  //                   </Button>
  //                 </Box>
  //               ) : null}
  //             </Box>
  //           }
  //         >
  //           <OfferTable

  //           // onOfferClicked={onOfferClicked}
  //           />
  //         </LCCard>
  //       )
  //     } else {
  //       return (
  //         <OfferAction
  //           workspaceSummary={workspaceSummary}
  //           workflow={workflow}
  //           offer={
  //             offers.find(
  //               o => o.offer.id == Number.parseInt(params["offerId"]!),
  //             )!.offer
  //           }
  //         />
  //       )
  //     }

  //     break
  //   case "seller":
  //     if (
  //       workflow.state == "contractPreparation" &&
  //       workflow.subState ==
  //         "contractPreparationAwaitingSellerSolicitorSelection"
  //     ) {
  //       return <SelectSolicitor actingFor={ActingForEnum.Seller} />
  //     }
  //     if (offers.length > 0) {
  //       if (isPhone) {
  //         return <OfferList />
  //       } else {
  //         return <OfferTable />
  //       }
  //     }

  //     // return (
  //     //   <Fragment>
  //     //     {workflow.appointAgent == "completed" ? <></> : <></>}
  //     //     {workflow.contractPreparation == "selecting-solicitor" ? (
  //     //       <SelectSolicitor
  //     //         onInviteSellerSolicitor={onInviteSellerSolicitor}
  //     //       />
  //     //     ) : (

  //     //     )}
  //     //   </Fragment>
  //     // );

  //     break
  //   case "sellerSolicitor":
  //     if (workflow.state == "contractPreparation") {
  //       return <ContractStepperPage></ContractStepperPage>
  //     }
  //     if (workflow.state == "acceptingOffers") {
  //       return (
  //         <>
  //           {offers.length >= 1 && params["offerId"] == null ? (
  //             <LCCard
  //               title="Offers"
  //               txtColor="#000"
  //               titleColor="#000"
  //               bgColor="#fff"
  //             >
  //               <OfferTable />
  //             </LCCard>
  //           ) : (
  //             <OfferAction
  //               workspaceSummary={workspaceSummary}
  //               workflow={workflow}
  //               offer={
  //                 offers.find(
  //                   o => o.offer.id === Number.parseInt(params["offerId"]!),
  //                 )!.offer
  //               }
  //             />
  //           )}
  //         </>
  //       )
  //     }
  //     break
  //   case "buyerSolicitor":
  //     if (workflow.state == "contractPreparation") {
  //       return <ContractStepperPage></ContractStepperPage>
  //     }
  //     if (workflow.state == "acceptingOffers") {
  //       return (
  //         <>
  //           {offers.length >= 1 && params["offerId"] == null ? (
  //             <LCCard
  //               title="Offers"
  //               txtColor="#000"
  //               titleColor="#000"
  //               bgColor="#fff"
  //             >
  //               <OfferTable />
  //             </LCCard>
  //           ) : (
  //             <OfferAction
  //               workspaceSummary={workspaceSummary}
  //               workflow={workflow}
  //               offer={
  //                 offers.find(
  //                   o => o.offer.id === Number.parseInt(params["offerId"]!),
  //                 )!.offer
  //               }
  //             />
  //           )}
  //         </>
  //       )
  //     }
  //     break
  //   case "buyer":
  //     return <SelectSolicitor actingFor={ActingForEnum.Buyer}></SelectSolicitor>
  //   default:
  //     return <></>
  // }

  return <></>
}

export default WorkflowAction
