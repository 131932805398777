import { FC, useEffect, useState } from "react"
import Page from "../../components/page"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useParams } from "react-router-dom"
import { useMediaQuery, useTheme } from "@mui/material"
import { WorkspaceRoleEnum } from "../../landconnex-api-client"
import { withAuthenticationRequired } from "react-oidc-context"
import RedirectToLogin from "../../components/redirect-to-login"
import useWebSocket from "react-use-websocket"

import { appConfig } from "../../constants"
import WorkspaceRetail from "./workspace-retail"
import WorkspacePageSmall from "./components/workspace-page-small-medium"
import WorkspacePageLarge from "./components/workspace-page-large"
import {
  getWorkspace,
  selectWorkspace,
  getWorkflow,
} from "../../app/slices/workspaces-slice"
import {
  getParticipants,
  selectParticipants,
} from "../../app/slices/participants-slice"
import { getOffers } from "../../app/slices/offers-slice"
import { getDocuments } from "../../app/slices/documents-slice"

const Workspace: FC = () => {
  const dispatch = useAppDispatch()
  const params = useParams()

  const [id] = useState(parseInt(params.workspaceId!))
  const { workspaceId } = params

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const isLarge = useMediaQuery(theme.breakpoints.up("md"))
  const participants = useAppSelector(selectParticipants)
  const workspace = useAppSelector(selectWorkspace)
  const [messageHistory, setMessageHistory] = useState<MessageEvent<any>[]>([])
  const { lastMessage } = useWebSocket(
    `${appConfig.eventsUri}/ws?workspace-id=${workspaceId}`,
  )

  // useEffect(() => {
  //   if (state.status == WorkspaceStatus.ready) {
  //     navigateToWorkflowStep(
  //       state.workspace.workspace?.role!,
  //       state.workflow!,
  //       navigate,
  //       location,
  //     )
  //   }
  //   if (state.status == WorkspaceStatus.workspaceDirty) {
  //     dispatch(getWorkspace(parseInt(workspaceId!)))
  //   }
  // }, [dispatch, state.status])

  useEffect(() => {
    if (lastMessage !== null) {
      dispatch(getWorkspace(parseInt(workspaceId!)))
      setMessageHistory(prev => prev.concat(lastMessage))
    }
  }, [lastMessage])

  useEffect(() => {
    if (workspace?.workspace?.id !== id) {
      console.log("should be loading the workspace")
      dispatch(getWorkspace(id))
      dispatch(getWorkflow(id))
      dispatch(getParticipants(id))
      dispatch(getOffers(id))
      dispatch(getDocuments(id))
    }
  }, [dispatch, id])

  messageHistory.map(m => {
    console.log(m.data)
  })

  // function handleOnOfferCreate(): void {
  //   dispatch(newOffer(parseInt(workspaceId!))).then(s => {
  //     const offerId = (s.payload as NewOfferResponse).offerId
  //     dispatch(
  //       getOffer({
  //         workspaceId: state.workspace.workspace?.id!,
  //         offerId: offerId,
  //       }),
  //     ).then(() => {
  //       navigate(`offers/${offerId}`)
  //     })
  //   })
  // }

  // function onOfferEdit(offer?: Contract | undefined): void {
  //   dispatch(
  //     getOffer({ workspaceId: parseInt(workspaceId!), offerId: offer!.id! }),
  //   )
  //   navigate(`offers/${offer!.id!}`)
  // }

  // function onEditAgentAppointment(workspace: WorkspaceSummary): void {
  //   dispatch(getAgentAppointment(workspace.workspace?.id!)).then(() => {
  //     navigate("agent-appointment")
  //   })
  // }
  // function onEditContract(): void {
  //   // dispatch(getContract({workspaceId:workspaceId})).then(() => {
  //   //   navigate("contract");
  //   // });
  // }

  // function handleInviteSellerSolicitor(emailAddress: string): void {
  //   dispatch(
  //     inviteSellerSolicitor({
  //       workspaceId: state.workspace.workspace!.id,
  //       emailAddress: emailAddress,
  //     }),
  //   )
  // }

  // function handleInviteBuyerSolicitor(
  //   offerId: number,
  //   emailAddress: string
  // ): void {
  //   dispatch(
  //     inviteBuyerSolicitor({
  //       workspaceId: state.workspace.workspace!.id,
  //       offerId: offerId,
  //       emailAddress: emailAddress,
  //     })
  //   );
  // }
  // function handleSendToSeller(_offerId: number): void {
  //   // dispatch(
  //   //   sendToSellerForSigning({
  //   //     workspaceId: state.workspace.workspace!.id!,
  //   //     offerId: offerId,
  //   //   })
  //   // );
  // }

  // function handleSendToBuyer(_offerId: number): void {
  //   // dispatch(
  //   //   sendToBuyerForSigning({
  //   //     workspaceId: state.workspace.workspace!.id!,
  //   //     offerId: offerId,
  //   //   })
  //   // );
  // }

  // function handleShareWithSeller(offerId: number): void {
  //   dispatch(
  //     shareWithSeller({
  //       workspaceId: state.workspace.workspace!.id!,
  //       offerId: offerId,
  //     }),
  //   )
  // }

  if (
    (workspace && workspace.workspace?.role) === WorkspaceRoleEnum.Buyer ||
    (workspace && workspace.workspace?.role === WorkspaceRoleEnum.Seller)
  ) {
    return (
      <WorkspaceRetail workspace={workspace!} participants={participants} />
    )
  }

  return (
    <Page title={workspace ? workspace.property?.streetAddress1 : ""}>
      {/* {workspace == undefined || workspaceStatus == WorkspacesStatus.loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <CircularProgress size={200} sx={{ color: "#8F1219" }} />
        </Box>
      ) : (
        <></>
      )} */}

      {isSmall || isMedium ? <WorkspacePageSmall /> : <></>}

      {isLarge ? <WorkspacePageLarge /> : <></>}
    </Page>
  )
}

export default withAuthenticationRequired(Workspace, {
  OnRedirecting: () => {
    console.log(window.location.href)
    return <RedirectToLogin />
  },
  signinRedirectArgs: { redirect_uri: window.location.href },
})
