import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import React, { Fragment } from "react"
import { Participant } from "../../../landconnex-api-client"
import BuyerDetail from "./buyer-detail"
import FormWrapper from "../../agent-appointment/components/form-wrapper"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { Box } from "@mui/material"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import {
  deleteOfferParticipant,
  postOfferParticipant,
  putOfferParticipant,
  selectOffer,
  selectOfferParticipants,
} from "../../../app/slices/offers-slice"
import { selectWorkspace } from "../../../app/slices/workspaces-slice"

export type OnAddBuyer = () => void

const BuyersDetails: React.FC = () => {
  const dispatch = useAppDispatch()
  const workspace = useAppSelector(selectWorkspace)
  const offer = useAppSelector(selectOffer)
  const offerParticipants = useAppSelector(selectOfferParticipants)

  function handleOnAddBuyer(): void {
    dispatch(
      postOfferParticipant({
        workspaceId: workspace!.workspace!.id!,
        offerId: offer!.id!,
        participant: { role: "buyer" },
      }),
    )
  }

  function handleOnBuyerRemoved(participant: Participant): void {
    dispatch(
      deleteOfferParticipant({
        workspaceId: workspace!.workspace!.id!,
        offerId: offer!.id!,
        participantId: participant.id!,
      }),
    )
  }

  function handleOnBuyersDetailsChanged(participant: Participant): void {
    dispatch(
      putOfferParticipant({
        participant: participant,
        workspaceId: workspace!.workspace!.id!,
        offerId: offer!.id!,
      }),
    )
  }

  // useEffect(() => {
  //   if (offerParticipants.length === 0) {
  //     dispatch(
  //       postOfferParticipant({
  //         workspaceId: workspace!.workspace!.id!,
  //         offerId: offer!.id!,
  //         participant: { role: "buyer" },
  //       }),
  //     )
  //   }
  // }, [
  //   offerParticipants.length,
  //   workspace!.workspace!.id!,
  //   offer!.id!,
  //   dispatch,
  // ])

  const buyers = offerParticipants.filter(p => p.role === "buyer")
  return (
    <FormWrapper title="Buyer's Details">
      <Fragment>
        {buyers.map((buyer, index) => (
          <BuyerDetail
            key={buyer.id}
            buyer={buyer}
            onChanged={handleOnBuyersDetailsChanged}
            onDelete={handleOnBuyerRemoved}
            index={index}
          />
        ))}

        <Grid container item spacing={2} justifyContent={"center"}>
          <Box sx={{ width: "100px", marginTop: 3 }}>
            <Button
              color="success"
              onClick={handleOnAddBuyer}
              startIcon={<AddOutlinedIcon />}
            >
              client
            </Button>
          </Box>
        </Grid>
      </Fragment>
    </FormWrapper>
  )
}

export default BuyersDetails
