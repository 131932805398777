import {
  Grid,
  Typography,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  debounce,
  useTheme,
  Box,
} from "@mui/material"
import React, { FC, useCallback, useEffect, useState } from "react"
import ConfirmationDialog from "../../../components/confirmation-dialog"
import ContactDetails from "../../../components/contact-details"
import ParticipantNameForm from "../../../components/participant-name-form"
import {
  Participant,
  ParticipantParticipantTypeEnum,
} from "../../../landconnex-api-client"
import { OnParticipantChanged } from "../../../components/events"
import useDeepCompareEffect from "use-deep-compare-effect"
import { SubmitHandler, useForm, useWatch } from "react-hook-form"
import AddressForm from "../../../components/address-form"
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"

export interface ClientDetailProps {
  client: Participant
  index: number
  onChanged: OnParticipantChanged
  onDelete?: OnParticipantChanged
  disabled?: boolean
}

const ClientDetail: FC<ClientDetailProps> = ({
  client,
  index,
  onChanged,
  onDelete,
  disabled,
}) => {
  const [confirmationDialogState, setConfirmationDialogState] =
    useState(Array<number>())
  const theme = useTheme()
  //const schema = participantSchema;

  const { handleSubmit, control, formState, setValue, reset, trigger } =
    useForm<Participant>({
      defaultValues: client,
      // resolver: yupResolver(schema),
    })

  useEffect(() => {
    reset(client)
    trigger()
  }, [client])

  const onSubmit: SubmitHandler<Participant> = data => {
    onChanged(data)
  }

  const watchedData = useWatch({
    control: control,
    defaultValue: client,
  })
  const debouncedSave = useCallback(
    debounce(() => {
      console.log("Saving")
      handleSubmit(onSubmit)()
    }, 1000),
    [],
  )

  useDeepCompareEffect(() => {
    console.log("Triggered")
    if (formState.isDirty) {
      debouncedSave()
    }
  }, [watchedData])
  return (
    <React.Fragment key={client.id}>
      <Grid item xs={12} sm={12}>
        <ConfirmationDialog
          open={confirmationDialogState.indexOf(client.id!) > -1}
          message={`Remove ${
            client.firstName ? client.firstName : "un-named"
          } from the contract?`}
          title="Remove client?"
          onClose={result => {
            if (result) {
              onDelete!(client)
              setConfirmationDialogState([])
            } else {
              setConfirmationDialogState([])
            }
          }}
        ></ConfirmationDialog>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">Client {index + 1}</Typography>

              <IconButton
                size="small"
                color="primary"
                onClick={() => setConfirmationDialogState([client.id!])}
              >
                <DeleteForeverOutlinedIcon fontSize="large" color="error" />
              </IconButton>
            </Box>
            <Divider />
          </Grid>
          <Grid item xs={3} sm={3}>
            <FormControl fullWidth variant="filled">
              <InputLabel size="small" id="clientTypeLabel">
                client type
              </InputLabel>
              <Select
                size="small"
                labelId="clientTypeLabel"
                name="participantType"
                label="Client Type"
                disabled={disabled}
                defaultValue={client.participantType}
                onChange={val => {
                  const p = { ...client }
                  p.participantType = val.target
                    .value as ParticipantParticipantTypeEnum
                  onChanged(p)
                }}
              >
                {Object.values(ParticipantParticipantTypeEnum).map(p => (
                  <MenuItem key={p} value={p}>
                    {p}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Name
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ParticipantNameForm
              control={control}
              captureMiddleName={true}
              captureGst={true}
              participantType={client.participantType!}
              disabled={disabled}
            />
          </Grid>
          {/* {client.participantType!} */}
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Contact
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ContactDetails control={control} disabled={disabled} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Address
            </Typography>
          </Grid>
          <AddressForm
            control={control}
            address={client}
            setValue={setValue}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default ClientDetail
