import {
  StripePaymentElementOptions,
} from "@stripe/stripe-js"

import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { StripeAddressElementOptions } from "@stripe/stripe-js"
import { Box, Button, Typography } from "@mui/material"

interface PaymentDetailsProps {
  clientSecretID: string
  setupIntentID: string
  onComplete: () => void
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  onComplete,
}) => {
  const elements = useElements()
  const stripe = useStripe()

  const paymentOptions: StripePaymentElementOptions = {
    paymentMethodOrder: ["card"],
    fields: {
      billingDetails: {
        name: "auto",
        email: "auto",
      },
    },
    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
  }

  const addressOptions: StripeAddressElementOptions = {
    mode: "billing",
    display: { name: "full" },
    autocomplete: {
      mode: "automatic",
    },
  }

  const handleCompleteOnBoarding = async () => {
    if (!stripe || !elements) {
      return
    }

    let confirmSetupOptions = {
      elements: elements,
      confirmParams: {
        return_url: "http://localhost:5173/on-boarding-complete",
      },
    }

    elements.submit()
    const result = await stripe.confirmSetup(confirmSetupOptions)

    if (result?.error) {
      console.log(result.error.message)
      throw result.error
    } else {
      onComplete()
    }
  }

  return (
    <Box>
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">
          Our simple pay-as-you-go pricing means no monthly fees. A standard
          workspace package is approximately $80 and provides:
          <ul>
            <li>
              User accounts are free. Add as many company users as you need
            </li>
            <li>Complete workspace setup and configuration</li>
            <li>Full property title extract</li>
            <li>
              Two or more digital signing envelopes (extra envelopes available
              as needed for multiple offers)
            </li>
          </ul>
        </Typography>
        <Typography variant="body1">
          Each workspace is individually invoiced, allowing you to easily manage
          disbursements with your clients.
        </Typography>
      </Box>
      <form
        onSubmit={e => {
          e.preventDefault()
          handleCompleteOnBoarding()
        }}
      >
        <AddressElement options={addressOptions} />
        <PaymentElement options={paymentOptions} />
        {/* <Box sx={{ width: 150, margin: "30px auto" }}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={!stripe || !elements}
            sx={{ backgroundColor: "custom.agentAppointment" }}
          >
            Submit Payment
          </Button>
        </Box> */}
      </form>
      <Box sx={{ pt: 2, display: "flex", justifyContent: "center" }}>
        <Button variant="contained" onClick={() => handleCompleteOnBoarding()}>
          Next
        </Button>
      </Box>
    </Box>
  )
}

export default PaymentDetails
