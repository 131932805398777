import { Fragment } from "react"
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"

import OfferRow from "./offer-row"
import { useAppSelector } from "../../app/hooks"
import { selectOffers } from "../../app/slices/offers-slice"
import { selectWorkflow } from "../../app/slices/workspaces-slice"

const OfferTable = () => {
  const offers = useAppSelector(selectOffers)
  const workflow = useAppSelector(selectWorkflow)

  return (
    <Fragment>
      {offers.length > 0 ? (
        <Table size="small" aria-label="collapsible table">
          <TableHead>
            <TableRow sx={{ ".MuiTableCell-root": { textAlign: "center" } }}>
              <TableCell></TableCell>
              <TableCell>Buyers</TableCell>
              <TableCell>Purchase Price</TableCell>
              <TableCell>Deposit</TableCell>
              <TableCell>Settlement Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offers.map((offer, _index) => {
              const workflowOffer = workflow?.offers.find(
                wOffer => wOffer.offerId === offer.offer.id,
              )
              return (
                <OfferRow
                  key={offer.offer.id}
                  offer={offer}
                  workflowOffer={workflowOffer!}
                />
              )
            })}
          </TableBody>
        </Table>
      ) : (
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h6" color="textSecondary">
            No Offers Available
          </Typography>
        </Box>
      )}
    </Fragment>
  )
}
export default OfferTable
